import Rodal from "rodal";
import * as Yup from "yup";
import "rodal/lib/rodal.css";
import { Formik, Field, Form, ErrorMessage } from "formik";

export default function App({ visible, setVisible, handleAddTracking }) {
  const initialValues = {
    shippingCarrier: "",
    trackingNumber: "",
  };
  const formSchema = Yup.object().shape({
    shippingCarrier: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    trackingNumber: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  return (
    <div>
      <div>
        <Rodal
          visible={visible}
          onClose={() => setVisible(false)}
          customStyles={{
            width: "95%",
            maxWidth: 700,
            height: "100%",
            maxHeight: 550,
            padding: "0",
            overflowY: "scroll",
          }}
        >
          <div className="my-5 text-2xl lg:text-3xl text-center font-bold px-10 break-normal hover:break-all">
            Agregue los datos de envío
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={(values) => {
              handleAddTracking(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form className="px-10">
                <div className="grid grid-cols-1 gap-x-10 ">
                  <div className="mb-9 text-2xl lg:text-3xl text-gray-550">
                    <p>Proveedor</p>
                    <Field
                      name="shippingCarrier"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="shippingCarrier"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                  <div className="mb-9 text-2xl lg:text-3xl text-gray-550">
                    <p>Clave de rastreo</p>
                    <Field
                      name="trackingNumber"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="trackingNumber"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>
                </div>
                {/* BUTTONS */}
                <div className="w-full flex justify-end mb-10 lg:mb-2">
                  <button
                    type="submit"
                    className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75 mt-2"
                  >
                    Agregar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Rodal>
      </div>
    </div>
  );
}
