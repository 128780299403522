import { useEffect, useState } from "react";

import Modal from "./Modal";
import LoginForm from "./forms/LoginForm";
import RegisterForm from "./forms/RegisterForm";
import SendCodeForm from "./forms/SendCodeForm";
import VerifyForm from "./forms/VerifyForm";

import ResetPasswordForm from "./forms/ResetPasswordForm";

import BGPLogo from "../assets/images/logo-bgp.svg";
import backgroundImg from "../assets/images/login-bg.png";

const AuthModal = ({ isModalOpen, setIsModalOpen, type }) => {
  const [active, setActive] = useState(null);
  const [email, setEmail] = useState(null);

  const onClose = () => {
    setActive(type);
  };

  const onRegister = () => {
    setActive("register");
  };

  useEffect(() => {
    setActive(type);
  }, [type]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onRequestClose={onClose}
    >
      <div className="flex flex-col md:flex-row w-full h-screen md:h-full">
        <div
          className={`flex flex-col justify-between w-full md:w-1/2 h-3/4 md:h-full px-14 pb-5 ${
            active === "register" ? "pt-5" : "pt-16"
          }`}
        >
          <div className="flex flex-col">
            <img
              src={BGPLogo}
              alt="BeGlobalPro"
              className="object-contain h-auto w-60 mx-auto"
            />
            <div>
              {
                {
                  login: (
                    <LoginForm
                      setActive={setActive}
                      setEmail={setEmail}
                      setIsModalOpen={setIsModalOpen}
                    />
                  ),
                  register: (
                    <RegisterForm setActive={setActive} setEmail={setEmail} />
                  ),
                  sendCode: (
                    <SendCodeForm setActive={setActive} setEmail={setEmail} />
                  ),

                  verify: <VerifyForm setActive={setActive} email={email} />,

                  resetPassword: (
                    <ResetPasswordForm setActive={setActive} email={email} />
                  ),
                }[active]
              }
            </div>
          </div>
          {active === "login" && (
            <div className="flex pt-0 md:pt-12 mx-auto md:mx-0 ">
              <p className="text-gray-700 mr-1">¿No tienes una cuenta?</p>
              <button
                type="button"
                className="text-secondary-new font-bold text-base "
                onClick={() => onRegister()}
              >
                Registrate
              </button>
            </div>
          )}
        </div>

        <div className="w-full md:w-1/2 h-1/4 md:h-auto relative mt-5 xs:mt-4 md:mt-0  ">
          <img src={backgroundImg} alt="" className="object-cover" />
          <div className="bg-primary-new absolute top-0 w-full h-full opacity-[.86]" />
          <p className="hidden md:block absolute left-8 bottom-8 mr-6 text-white font-bold text-5xl">
            Bienvenidos <br />
            al sistema educativo de <br />
            Be Global Pro.
          </p>
          <div className="block md:hidden">
            <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold text-2xl lg:text-3xl text-center">
              Bienvenidos <br />
              al sistema educativo de <br />
              Be Global Pro.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
