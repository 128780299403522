import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Context from "../context/UserContext";
import profileNavbarItems from "../utils/profileNavbarItems";
import logo from "../assets/images/logo-globe-gbp.png";
import headerBg from "../assets/images/profile/header-bg.png";
import ChangeImageProfileWebModal from "../components/Modals/ChangeImageProfileWebModal";
import NavBarItem from "../components/profile/NavbarItem";
import { notifications } from "../utils/notifications";
import moment from "moment";
import * as _ from "lodash";
import TableLoading from "../components/TableLoading";
import TableTopSales from "../components/Sales/Memberships/TableTopSales";
import { profileService } from "../services/api/profile";

const ProfileLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(Context);
  const [showChangeImageModal, setShowChangeImageModal] = useState(false);
  const [membershipsSales, setMembershipsSales] = useState([]);
  const [productSales, setProductSales] = useState([]);
  const currentMonth =
    "" + moment().format("MMMM") + " " + moment().format("YYYY");
  const getMembershipName = () => {
    if (!currentUser) return;

    if (currentUser.hasMembership) {
      return currentUser.membership_name;
    }

    return null;
  };

  const handleCopyClick = () => {
    const textToCopy = currentUser.uuid;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    notifications.success("código copiado en el portapapeles.");
  };

  const fetchMonthlyTopSellers = () => {
    profileService
      .getMonthlyTopSellers()
      .then((response) => {
        let membershipsSalesResponse = response.data.memberships;
        let productSalesResponse = response.data.products;
        membershipsSalesResponse.sort((a, b) => b.total - a.total);
        productSalesResponse.sort((a, b) => b.total - a.total);

        if (membershipsSalesResponse.length < 10) {
          membershipsSalesResponse.push(
            ...Array(10 - membershipsSalesResponse.length).fill({})
          );
        }
        if (productSalesResponse.length < 10) {
          productSalesResponse.push(
            ...Array(10 - productSalesResponse.length).fill({})
          );
        }
        setMembershipsSales(membershipsSalesResponse);
        setProductSales(productSalesResponse);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchMonthlyTopSellers();
  }, [currentUser]);

  return (
    <div className="relative">
      <NavBar />

      <main>
        <div className="border-b border-gray-200 px-5 2xl:px-0">
          <div className="container mx-auto h-20 flex items-center px-5 md:px-0">
            <img src={logo} alt="logo Beglobal Pro" className="h-6 mr-1" />
            <p className=" text-gray-700 ">
              <FontAwesomeIcon icon="chevron-right" /> Mi Perfil
            </p>
          </div>
        </div>

        <div className="relative">
          <div className="absolute w-full h-96">
            <img src={headerBg} alt="" className="object-cover h-full w-full" />
          </div>

          <div className="container mx-auto w-full h-full relative px-5 py-20 2xl:px-0">
            <div className="w-full flex flex-col md:flex-row justify-between gap-8 lg:gap-16">
              <div className="bg-white border border-gray-350 flex flex-col self-start w-full md:w-2/5 ">
                {/* PROFILE IMG */}
                <div className="w-44 h-44 md:w-32 md:h-32 lg:w-44 lg:h-44 xl:w-48 xl:h-48 mx-auto mt-10">
                  <button
                    className="rounded-full border-4 border-primary-new w-full h-full hover:shadow-2xl"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowChangeImageModal(true);
                    }}
                  >
                    <img
                      src={currentUser && currentUser.image}
                      alt=""
                      className="object-cover rounded-full h-full w-full"
                      style={{ backgroundColor: "#D8D8D8" }}
                    />
                  </button>
                </div>
                {/* USER INFO */}
                <div className="text-center py-10">
                  <p className="text-2xl lg:text-4xl font-bold text-gray-800">
                    {currentUser && currentUser.name}
                  </p>
                  <p className="text-xl text-primary-200">
                    {getMembershipName()}
                  </p>
                  {currentUser &&
                    currentUser.hasMembership &&
                    !moment(currentUser.membership_expired_at).isAfter(
                      "2035-01-01 00:00:00"
                    ) && (
                      <p className="text-lg font-bold text-gray-800">
                        Caduca el&nbsp;
                        <Moment format="DD/MM/YYYY">
                          {currentUser.membership_expired_at}
                        </Moment>
                      </p>
                    )}

                  {currentUser &&
                    currentUser.hasMembership &&
                    moment(currentUser.membership_expired_at).isAfter(
                      new Date()
                    ) &&
                    currentUser.uuid && (
                      <div className="text-lg md:text-sm lg:text-lg font-bold text-primary-new flex mx-auto text-center justify-center space-x-1">
                        <div>{currentUser.uuid}</div>
                        <div className="text-primary-new py-auto my-auto">
                          <span
                            onClick={() => {
                              handleCopyClick();
                            }}
                            className="cursor-pointer"
                            title="Haz click aquí para copiar el código"
                          >
                            <FontAwesomeIcon icon="fa-regular fa-copy" />
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                {/* NAV */}
                <div className="flex flex-col text-xl lg:text-2xl text-gray-800 text-center">
                  {profileNavbarItems.map((item, idx) => (
                    <NavBarItem item={item}></NavBarItem>
                  ))}
                </div>
              </div>
              <div className="bg-white border border-gray-350 w-full md:w-3/5 p-8 lg:p-16">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <p className="text-center font-bold text-2xl uppercase ">
          TOP 10 DE VENTAS DE USUARIOS
        </p>
        <p className="text-center font-bold text-2xl uppercase text-primary-400">
          {currentMonth}
        </p>
        {!isLoading ? (
          <div className="grid grid-cols-1 xl:grid-cols-2 container mx-auto">
            <TableTopSales data={membershipsSales} membershipSales />
            <TableTopSales data={productSales} />
          </div>
        ) : (
          <div className="mx-auto flex justify-center">
            <TableLoading size="5x" />
          </div>
        )}
      </main>
      <ChangeImageProfileWebModal
        visible={showChangeImageModal}
        setVisible={setShowChangeImageModal}
      />
      <Footer />
    </div>
  );
};

export default ProfileLayout;
