import { useEffect, useState } from "react";
import moment from "moment";
import * as _ from "lodash";
import { MEMBERSHIPS_DICTIONARY } from "../utils/MembershipsDictionary";
import { labelsByMonth } from "../utils/chartsOptions";

export default function useComissionsFilters() {
  const [commissions, setCommissions] = useState([]);
  const [commissionsRange, setCommissionsRange] = useState([]);
  const [commissionsProducts, setCommissionsProducts] = useState([]);
  const [commissionsProductsRange, setCommissionsProductsRange] = useState([]);
  const [yearBar, setYearBar] = useState(new Date().getFullYear());
  const [filter, setFilter] = useState("all");
  const [comissionsChart, setComissionsChart] = useState([]);
  const [soldCountChart, setSoldCountChart] = useState([]);
  const [salesTotalChart, setSalesTotalChart] = useState([]);
  const [comissionsProductsChart, setComissionsProductsChart] = useState([]);
  const [soldCountProductsChart, setSoldCountProductsChart] = useState([]);
  const [salesTotalProductsChart, setSalesTotalProductsChart] = useState([]);
  const [currentWeekDateRange, setCurrentWeekDateRange] = useState([]);
  const [dateRangesCombined, setDateRangesCombined] = useState([]);
  const [breakdownWeekSelect, setBreakdownWeekSelect] = useState(0);
  const [arrayYears, setArrayYears] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [breakdownSelect, setBreakdownSelect] = useState("currentWeek");
  const [startDate, endDate] = dateRange;

  const calculateMembershipsByRanges = () => {
    const format = "YYYY-MM-DD";
    const saturdayNumber = 7;
    const firstDayNumberOfYear = parseInt(moment().startOf("year").format("d"));

    const firstDayDateOfYear = "2000-01-01";
    const currentDate = moment().format(format);

    let firstSaturdayAnnualCycleDate = null;
    let lastSaturdayDateIteration = null;
    let dateRanges = []; // Array que contiene los rangos de fecha de sabado a sabado iniciando en el primero sabado anual del ciclo
    let dateRangesCourses = []; // Array que contiene los rangos de fecha de sabado a sabado iniciando en el primero sabado anual del ciclo para cursos
    let membershipsInRange = []; // Array que contiene las membresias dentro de cada rango de sabado-sabado
    let coursesInRange = []; // Array que contiene los cursos dentro de cada rango de sabado-sabado

    if (saturdayNumber === firstDayNumberOfYear) {
      firstSaturdayAnnualCycleDate = firstDayDateOfYear;
    } else {
      //TODO: OBTENER EL PRIMER SABADO ANTERIOR A LA FECHA DE INICIO DE AÑO
      let daysDiff = saturdayNumber - firstDayNumberOfYear;
      firstSaturdayAnnualCycleDate = moment(firstDayDateOfYear)
        .day(-(daysDiff + 1))
        .format(format);
    }

    lastSaturdayDateIteration = firstSaturdayAnnualCycleDate;

    //TODO: GUARDAR RANGOS DE FECHA DE SABADO-SABADO INICIANDO EN EL PRIMERO SABADO DEL CICLO
    while (
      moment(lastSaturdayDateIteration, format).isBefore(
        moment(currentDate, format)
      )
    ) {
      let datePlus7Days = moment(lastSaturdayDateIteration)
        .add(7, "days")
        .format(format);
      dateRanges.push([lastSaturdayDateIteration, datePlus7Days]);
      dateRangesCourses.push([lastSaturdayDateIteration, datePlus7Days]);
      lastSaturdayDateIteration = datePlus7Days;
    }

    setCurrentWeekDateRange(dateRanges[dateRanges.length - 1]);

    //TODO: PROCEDER A GUARDAR LAS MEMBRESIAS EN LA SEMANA QUE LES CORRESPONDE
    dateRanges.forEach((range) => {
      let startDate = moment(`${range[0]} 23:59:00`);
      let endDate = moment(`${range[1]} 23:59:00`);
      let membershipsInCurrentRange = [];

      commissions.forEach((membership) => {
        let memershipDate = moment(membership.date);
        if (
          memershipDate.isBetween(startDate, endDate) &&
          membership.type === "Membership"
        ) {
          membershipsInCurrentRange.push(membership);
        }
      });

      membershipsInRange.push(membershipsInCurrentRange);
    });

    dateRangesCourses.forEach((range) => {
      let startDate = moment(`${range[0]} 23:59:00`);
      let endDate = moment(`${range[1]} 23:59:00`);
      let coursesInCurrentRange = [];

      commissions.forEach((course) => {
        let courseDate = moment(course.date);
        if (
          courseDate.isBetween(startDate, endDate) &&
          course.type !== "Membership"
        ) {
          //coursesInCurrentRange.push(course, range);
          coursesInCurrentRange.push(course);
        }
      });

      coursesInRange.push(coursesInCurrentRange);
    });

    dateRanges = dateRanges.filter((range, idx) => {
      if (!_.isEmpty(membershipsInRange[idx])) return range;
      return false;
    });

    dateRangesCourses = dateRangesCourses.filter((range, idx) => {
      if (!_.isEmpty(coursesInRange[idx])) return range;
      return false;
    });

    membershipsInRange = membershipsInRange.filter((range, idx) => {
      if (!_.isEmpty(range)) return range;
      return false;
    });

    coursesInRange = coursesInRange.filter((range, idx) => {
      if (!_.isEmpty(range)) return range;
      return false;
    });

    // COMBINAR RANGOS DE MEMBRESIAS/CURSOS Y ELIMINAR DUPLICADOS
    let _dateRangesCombined = dateRanges.concat(dateRangesCourses);
    _dateRangesCombined = _.uniqBy(_dateRangesCombined, function (item) {
      return JSON.stringify(item);
    });
    setDateRangesCombined(_dateRangesCombined);
  };

  const labels = Object.values(MEMBERSHIPS_DICTIONARY).map(
    (membership) => membership.label
  );

  const backgroundColor = Object.values(MEMBERSHIPS_DICTIONARY).map(
    (membership) => {
      return membership.backgroundColor || "rgba(255, 255, 255, 0.7)";
    }
  );
  const dataComissions = {
    labels,
    datasets: [
      {
        data: comissionsChart,
        backgroundColor,
        borderColor: ["rgba(255, 255, 255, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const dataSalesCount = {
    labels,
    datasets: [
      {
        data: soldCountChart,
        backgroundColor,
        borderColor: ["rgba(255, 255, 255, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const data = {
    labels: labelsByMonth,
    datasets: [
      {
        data: labelsByMonth.map((el, idx) => {
          let amount = 0;
          let currentYear = yearBar;

          let mFilter = commissions.filter((m) => {
            let date = m.date.split(" ")[0];
            let month = parseInt(date.split("-")[1]);
            let year = parseInt(date.split("-")[0]);

            return month === idx + 1 && year === currentYear ? true : false;
          });

          mFilter.forEach((membership) => (amount += membership.total));
          return amount;
        }),
        backgroundColor: "#003159",
      },
    ],
  };

  const updateCharts = (startDate, endDate) => {
    const sales = new Array(
      Object.keys(MEMBERSHIPS_DICTIONARY).length + 1
    ).fill(0);
    const count = new Array(
      Object.keys(MEMBERSHIPS_DICTIONARY).length + 1
    ).fill(0);
    const commissionsMoney = new Array(
      Object.keys(MEMBERSHIPS_DICTIONARY).length + 1
    ).fill(0);

    const products = new Array(3).fill(0); // count, total, commission

    commissions.forEach((commission) => {
      let membershipDate = moment(commission.date);
      if (membershipDate.isBetween(startDate, endDate)) {
        if (commission.type === "Membership") {
          const membershipIndex = commission.membershipId - 1;
          if (
            ["all", "memberships"].includes(filter) ||
            commission.membershipId === parseInt(filter)
          ) {
            sales[membershipIndex] += commission.total;
            count[membershipIndex]++;
            commissionsMoney[membershipIndex] += commission.commission;
          }
        } else if (commission.type !== "Product") {
          if (filter === "all" || filter === "courses") {
            sales[10] += commission.total;
            count[10]++;
            commissionsMoney[10] += commission.commission;
          }
        }
      }
    });

    commissionsProducts.forEach((commission) => {
      let productDate = moment(commission.date);
      if (productDate.isBetween(startDate, endDate)) {
        if (
          commission.type === "Product" &&
          (filter === "all" || filter === "products")
        ) {
          products[0]++;
          products[1] += commission.total;
          products[2] += commission.commission;
        }
      }
    });

    setSoldCountChart(count);
    setSalesTotalChart(sales);
    setComissionsChart(commissionsMoney);
    setSoldCountProductsChart(products[0]);
    setSalesTotalProductsChart(products[1]);
    setComissionsProductsChart(products[2]);
  };

  const selectOtherWeekOption = () => {
    let idx = breakdownWeekSelect;
    let rangeWeek = dateRangesCombined[idx];
    let startDate = moment(`${rangeWeek[0]} 23:59:00`);
    let endDate = moment(`${rangeWeek[1]} 23:59:00`);
    updateCharts(startDate, endDate);
  };

  const setBreakdownSelectByAnnual = () => {
    let currentYear = yearBar;
    let startDate = moment(`${currentYear}-01-01 00:00:00`);
    let endDate = moment(`${currentYear}-12-31 23:59:59`);
    updateCharts(startDate, endDate);
  };

  const setBreakdownSelectByWeek = () => {
    let startDate = moment(`${currentWeekDateRange[0]} 23:59:00`);
    let endDate = moment(`${currentWeekDateRange[1]} 23:59:00`);
    updateCharts(startDate, endDate);
  };

  const generateArrayOfYears = () => {
    const years = new Set(
      commissions
        ?.map((c) => new Date(c.date).getFullYear())
        .concat(new Date().getFullYear())
    );
    const yearsArray = [...years].sort((a, b) => b - a);
    setArrayYears(yearsArray);
  };

  useEffect(() => {
    calculateMembershipsByRanges();
    generateArrayOfYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissions]);

  useEffect(() => {
    if (!arrayYears) return;
    switch (breakdownSelect) {
      case "annual":
        return setBreakdownSelectByAnnual();
      case "otherWeek":
        if (_.isEmpty(dateRangesCombined)) return;
        return selectOtherWeekOption();
      default:
        return setBreakdownSelectByWeek();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayYears, breakdownSelect, breakdownWeekSelect, yearBar, filter]);

  useEffect(() => {
    if (dateRange[0] === null && dateRange[1] === null) {
      setCommissionsRange(commissions);
      setCommissionsProductsRange(commissionsProducts);
      return;
    }
    if (dateRange[0] === null || dateRange[1] === null) {
      setCommissionsRange([]);
      setCommissionsProductsRange([]);
      return;
    }
    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const commissionsRangeFiltered = commissions.filter((commission) =>
      moment(commission.date).isBetween(startDate, endDate)
    );
    const commissionsProductsRangeFiltered = commissionsProducts.filter(
      (commission) => moment(commission.date).isBetween(startDate, endDate)
    );
    setCommissionsRange(commissionsRangeFiltered);
    setCommissionsProductsRange(commissionsProductsRangeFiltered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return {
    setCommissions,
    setCommissionsRange,
    setCommissionsProducts,
    setCommissionsProductsRange,
    setYearBar,
    setBreakdownWeekSelect,
    setDateRange,
    setBreakdownSelect,
    comissionsChart,
    salesTotalChart,
    soldCountChart,
    comissionsProductsChart,
    salesTotalProductsChart,
    soldCountProductsChart,
    dataComissions,
    dataSalesCount,
    commissions,
    commissionsRange,
    commissionsProducts,
    commissionsProductsRange,
    yearBar,
    data,
    breakdownWeekSelect,
    dateRangesCombined,
    arrayYears,
    breakdownSelect,
    startDate,
    endDate,
    filter,
    setFilter,
  };
}
