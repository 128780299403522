import { useNavigate } from "react-router-dom";
import { coursesServices } from "../../services/api/courses";
import { BUTTON_COURSES } from "../../utils/coursesButtonDictionary";
import { COURSES_STATUS } from "../../utils/memberships";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notifications } from "../../utils/notifications";
import { useContext } from "react";
import Context from "../../context/UserContext";
import { cartServices } from "../../services/api/cart";

const CourseButton = ({ course, detail, fetchCourse }) => {
  const { currentUser } = useContext(Context);

  const status = course.status;
  const navigate = useNavigate();

  const handleAddCourse = async () => {
    if (currentUser) {
      await coursesServices
        .addCourseMembership(course.uuid)
        .then((response) => {
          notifications.success(response.data.message);
          fetchCourse();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            /* eslint-disable jsx-a11y/anchor-is-valid */
            notifications.error(
              <div>
                {" "}
                {err.response.data.message}{" "}
                <a href="/membresias/dropshipping" className="text-primary-new">
                  Adquiere tu membresía.{" "}
                </a>
              </div>
            );
          }
        });
    } else {
      notifications.warn(
        <div>
          {" "}
          <a
            onClick={() => navigate({ pathname: "", search: "?login=true" })}
            className="text-primary-new"
          >
            Inicie sesión{" "}
          </a>
          para adquirir un curso
        </div>
      );
    }
  };
  const handleShoppingCart = async () => {
    if (currentUser) {
      await cartServices
        .addCourseToCart({ uuid: course.uuid, type: "Curso" })
        .then((response) => {
          notifications.success(response.data.message);
          navigate("/carrito-de-compras");
        })
        .catch((err) => {});
    } else {
      /* eslint-disable jsx-a11y/anchor-is-valid */
      notifications.warn(
        <div>
          {" "}
          <a
            onClick={() => navigate({ pathname: "", search: "?login=true" })}
            className="text-primary-new"
          >
            Inicie sesion{" "}
          </a>
          para adquirir un curso
        </div>
      );
    }
  };

  const handleGoToCourseContent = async () => {
    switch (status) {
      case COURSES_STATUS.START:
        return navigate(`/cursos/contenido/${course.uuid}/`);
      case COURSES_STATUS.TO_ACQUIRE:
        if (detail) return handleAddCourse();
        return navigate(`/cursos/${course.uuid}`);
      case COURSES_STATUS.BUY:
        if (detail) return handleShoppingCart();
        return navigate(`/cursos/${course.uuid}`);
      default:
        if (detail) return;
        return navigate(`/cursos/${course.uuid}`);
    }
  };

  const optionsButton = BUTTON_COURSES[status] || BUTTON_COURSES.default;
  return (
    <button
      onClick={handleGoToCourseContent}
      className={`block ${optionsButton.color} transition-all ease-in text-[10px] sm:text-[14px] text-white text-sm py-4 sm:px-8 w-[140px] sm:w-[220px] rounded-[100px] sm:rounded-full drop-shadow-xl `}
    >
      {status === COURSES_STATUS.BUY ? (
        <div
          className={`flex items-center ${
            detail ? "justify-center" : "justify-start"
          }`}
        >
          {detail && (
            <FontAwesomeIcon icon={["far", "cart-shopping"]} size="lg" />
          )}
          <p
            className={`${
              detail ? "pl-2 text-[10px] lg:text-3xl " : "mx-auto"
            }`}
          >
            {detail ? "Comprar" : `${optionsButton.label} $${course.cost} MXN.`}
          </p>
        </div>
      ) : (
        optionsButton.label
      )}
    </button>
  );
};

export default CourseButton;
