const NButton = ({
  label,
  className = "",
  type = "button",
  secondary = false,
  ...props
}) => {
  const Component = type === "a" ? "a" : "button";
  const bgColor = secondary
    ? "bg-secondary-new hover:bg-[#F5A800]"
    : "bg-primary-new hover:bg-[#0A4C7D]";

  return (
    <Component
      className={`${bgColor} transition-all ease-in text-white text-sm py-4 px-8 w-[220px] rounded-full drop-shadow-xl ${className}`}
      {...props}
    >
      {label}
    </Component>
  );
};

export default NButton;
