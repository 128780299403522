import { useEffect, useState } from "react";
import noImageProduct from "../../assets/images/store/no-image-product.jpg";

const ProductCard = ({ product, onClick }) => {
  const getTitle = (windowWidth) => {
    let title = product.title.toLowerCase();
    let limit = 20;

    if (windowWidth >= 768) {
      limit = 22;
    }

    if (windowWidth >= 1280) {
      limit = 30;
    }

    if (windowWidth >= 1536) {
      limit = 35;
    }

    title = title.charAt(0).toUpperCase() + title.slice(1);

    setProductTitle(
      title.length >= limit ? title.substring(0, limit).trim() + "..." : title
    );
  };

  const [productTitle, setProductTitle] = useState("");

  const getPrice = () => {
    if (product.variants && product.variants.length > 0) {
      return product.variants[0].price;
    } else {
      let price = product.variants.edges[0].node.price;
      return price.amount ? price.amount : price;
    }
  };

  const getCompareAtPrice = () => {
    if (product.variants && product.variants.length > 0) {
      return product.variants[0].compare_at_price &&
        product.variants[0].compare_at_price > 0
        ? product.variants[0].compare_at_price
        : getPrice();
    } else {
      let productVariant = product.variants.edges[0].node;
      if (
        productVariant.compareAtPrice &&
        productVariant.compareAtPrice.amount
      ) {
        return productVariant.compareAtPrice.amount > 0
          ? productVariant.compareAtPrice.amount
          : getPrice();
      } else {
        return productVariant.compareAtPrice > 0
          ? productVariant.compareAtPrice
          : getPrice();
      }
    }
  };

  const getImage = () => {
    if (product.image && product.image.src) {
      return product.image.src;
    } else if (
      product.images &&
      product.images.edges &&
      product.images.edges.length > 0
    ) {
      return product.images.edges[0].node.originalSrc;
    } else {
      return noImageProduct;
    }
  };

  useEffect(() => {
    const resizeListener = () => {
      getTitle(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    getTitle(window.innerWidth);
  }, []);

  return (
    <div
      role="button"
      onClick={() => onClick(product.id, product.shopify_store_id)}
      className={`w-[180px] xl:w-[285px] cursor-pointer`}
    >
      {/* IMAGE */}
      <div className="w-full">
        <img
          src={getImage()}
          alt=""
          className={`mx-auto w-full h-full object-cover h-[205px] xl:h-[320px]`}
        />
      </div>

      {/* INFO */}
      <div className="text-sm lg:text-sm leading-none mt-2">
        <p className="font-bold text-gray-new text-sm xl:text-base">
          {productTitle}
        </p>
        <p className="font-bold text-[#8E8E8E] text-xs xl:text-base">
          ${getCompareAtPrice()} mxn{" "}
        </p>

        <div className="flex flex-col xl:flex-row xl:items-center space-y-[2px] xl:space-x-2">
          <p className="font-bold text-secondary-new text-xs xl:text-base">
            ${getPrice()} mxn
          </p>
          <div className="flex justify-center items-center bg-secondary-new rounded-xl w-[65px] h-[18px] xl:w-[79px] xl:h-[28px]">
            <p className="text-[11px] xl:text-sm text-white font-bold">
              Miembros
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
