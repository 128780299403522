import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import useUser from "../../hooks/useUser";

import { ReactComponent as MailIcon } from "../../assets/icons/mail-icon.svg";

const SendCodeForm = ({ setActive, setEmail }) => {
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Campo requerido")
      .email("Correo electronico invalido")
      .max(255, `Máximo 255 caracteres`),
  });

  const { isLoading, hasError, errors, onSucess, sendPasswordReset } =
    useUser();

  useEffect(() => {
    if (onSucess) {
      setActive("resetPassword");
    }
  }, [onSucess, setActive]);

  const handleSubmit = async (values) => {
    setEmail(values.email);
    await sendPasswordReset(values);
  };

  return (
    <div className="flex flex-col justify-center h-96">
      <p className="text-gray-700 font-bold text-xl pt-5">
        Recuperación de cuenta
      </p>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={formSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <div className="mt-5">
            <Field
              name="email"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
              placeholder="Ingrese el correo electronico"
              type="email"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          {hasError && errors.length > 0 && (
            <div className="bg-gray-300 border-l-4 border-l-tertiary p-2 mt-2">
              <ul className="text-gray-700 font-bold text-xs">
                {errors.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}

          <button
            type="submit"
            className="bg-white border border-gray-300 border-l-primary-new border-l-[6px] h-10 w-full text-white font-bold text-2xl my-5 disabled:opacity-75"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="text-gray-700 text-lg">
                <FontAwesomeIcon icon="circle-notch" spin />
              </div>
            ) : (
              <div className="flex">
                <div className="w-1/6 pl-3 pr-1">
                  <MailIcon />
                </div>
                <div className="w-5/6 text-left">
                  <p className="leading-none text-gray-700 font-bold text-sm">
                    Enviar código por correo electronico
                  </p>
                  {/* <p className="text-gray-550 text-sm font-normal">m….@gmail.com</p> */}
                </div>
              </div>
            )}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default SendCodeForm;
