import noImageProduct from "../../assets/images/store/no-image-product.jpg";

const CategoryCard = ({ category, onClick }) => {
  return (
    <div
      onClick={() => onClick(category.store.id, category.id)}
      key={category.id}
      className="bg-white h-40 sm:h-72 p-8 flex flex-col justify-between cursor-pointer hover:shadow-lg transform transition-transform duration-300 hover:scale-105 hover:bg-blue-100"
    >
      <div className="w-full h-2/3">
        <img
          src={
            category.images.length > 0 ? category.images[0].url : noImageProduct
          }
          alt=""
          className="w-full h-full object-contain"
        />
      </div>
      <div className="text-lg sm:text-xl font-bold leading-none pt-2">
        <p className="text-gray-700 text-center">{category.title}</p>
      </div>
    </div>
  );
};

export default CategoryCard;
