import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleNotch,
  faChevronRight,
  faChevronLeft,
  faStar,
  faStarHalf,
  faLockKeyhole,
  faChevronDown,
  faChevronUp,
  faMedal,
  faDownload,
  faInfinity,
  faHeart,
  faXmark,
  faCheck,
  faBookBlank,
  faBullhorn,
  faStore,
  faUserGroup,
  faUsers,
  faBell,
  faFlag,
  faExclamation,
  faMagnifyingGlass,
  faPlus,
  faLocationDot,
  faRankingStar,
  faMessageExclamation,
  faBan,
  faCircle,
  faCartCircleExclamation,
  faTags,
  faBusinessTime,
  faBlog,
  faFaceThinking,
  faFilterList,
  faCrown,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faUser as farUser,
  faLockKeyhole as farLockKeyhole,
  faThumbsUp as farThumbsUp,
  faBookBlank as farBookBlank,
  faFileCertificate as farFileCertificate,
  faCartShopping as farCartShopping,
  faHeart as farHeart,
  faStar as farStar,
  faFilePdf as farFilePdf,
  faCircleDown as farCircleDown,
  faClock as farClock,
  faChartLine as farChartLine,
  faCartCircleCheck as farCartCircleCheck,
  faTag as farTag,
  faCreditCard as farCreditCard,
  faCircleCheck as farCircleCheck,
  faVideo as farVideo,
  faLocationSmile as farLocationSmile,
  faPlay as farPlay,
  faStore as farStore,
  faBoxCircleCheck as farBoxCircleCheck,
  faTruckClock as farTruckClock,
  faMoneyBill1Wave as farMoneyBill1Wave,
  faExclamation as farExclamation,
  faCheck as farCheck,
  faCalendar as farCalendar,
  faPencil as farPencil,
  faCloudArrowUp as farCloudArrowUp,
  faUserTag as farUserTag,
  faChess as farChess,
  faRectangleAd as farRectangleAd,
  faPenNib as farPenNib,
  faLaptopCode as farLaptopCode,
  faPaintbrushPencil as farPaintBrushPencil,
  faPeopleRoof as farPeopleRoof,
  faBezierCurve as farBezierCurve,
  faMegaphone as farMegaphone,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faCirclePlay as farCirclePlay,
  faBars as farBars,
  faFile as farFile,
  faBook as farBook,
  faShieldPlus as farShieldPlus,
  faBoxOpen as farBoxOpen,
  faAd as farAd,
  faMemoPad as farMemoPad,
  faFileInvoice as farFileInvoice,
  faMoneyCheckPen as farMoneyCheckPen,
  faLoader as farLoader,
  faCircleDollar as farCircleDollar,
  faCircleQuestion as farCircleQuestion,
  faRectangleHistoryCirclePlus as farRectangleHistoryCirclePlus,
  faLocationDot as farLocationDot,
  faClipboardListCheck as farClipboardListCheck,
  faBriefcase as farBriefCase,
  faUsers as farUsers,
  faRightFromBracket as farRightFromBrackets,
  faRightToBracket as farRightToBrackets,
  faCircle as farCircle,
  faCrown as farCrown,
  faTicket as farTicket,
  faMoneyBillTransfer as farMoneyBillTransfer,
  faFileExcel as farFileExcel,
  faCartCircleXmark as farCartCircleXmark,
  faCopy as farRegularCopy,
  faCircleArrowLeft as farCircleArrowLeft,
  faCircleArrowRight as farCircleArrowRight,
  faDisplay as farDisplay,
  faTruckFast as farTruckFast,
  faDolly as farDolly,
  faUserGraduate as farUserGraduate,
  faLink as farRegularLink,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faCcVisa as fabCcVisa,
  faCcMastercard as fabCCMastercard,
  faWhatsapp as fabWhatsapp,
  faAmazon as fabAmazon,
  faShopify as fabShopify,
  faTwitter as fabWitter,
  faFacebook as fabFacebook,
  faInstagram as fabInstagram,
  faWordpress as fabWordpress,
  faTiktok as fabTikTok,
  faTelegram as fabTelegram,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  // SOLID ICONS BELOW HERE
  faCircleNotch,
  faChevronRight,
  faChevronLeft,
  faStar,
  faStarHalf,
  faLockKeyhole,
  faChevronDown,
  faChevronUp,
  faMedal,
  faDownload,
  faInfinity,
  faHeart,
  faXmark,
  faCheck,
  faBookBlank,
  faBullhorn,
  faStore,
  faUserGroup,
  faUsers,
  faBell,
  faFlag,
  faExclamation,
  faMagnifyingGlass,
  faPlus,
  faLocationDot,
  faRankingStar,
  faMessageExclamation,
  faBan,
  faCircle,
  faCartCircleExclamation,
  faTags,
  faBusinessTime,
  faBlog,
  faFaceThinking,
  faFilterList,
  faCrown,
  // REGULAR ICONS BELOW HERE
  farUser,
  farLockKeyhole,
  farThumbsUp,
  farBookBlank,
  farBook,
  farFileCertificate,
  farCartShopping,
  farHeart,
  farStar,
  farFilePdf,
  farCircleDown,
  farClock,
  farChartLine,
  farCartCircleCheck,
  farTag,
  farCreditCard,
  farCircleCheck,
  farVideo,
  farLocationSmile,
  farPlay,
  farStore,
  farBoxCircleCheck,
  farTruckClock,
  farMoneyBill1Wave,
  farExclamation,
  farCheck,
  farCalendar,
  farPencil,
  farCloudArrowUp,
  farUserTag,
  farChess,
  farRectangleAd,
  farPenNib,
  farLaptopCode,
  farPaintBrushPencil,
  farPeopleRoof,
  farBezierCurve,
  farMegaphone,
  farEye,
  farEyeSlash,
  farCirclePlay,
  farBars,
  farFile,
  farShieldPlus,
  farBoxOpen,
  farAd,
  farMemoPad,
  farFileInvoice,
  farMoneyCheckPen,
  farLoader,
  farCircleDollar,
  farCircleQuestion,
  farRectangleHistoryCirclePlus,
  farLocationDot,
  farClipboardListCheck,
  farBriefCase,
  farUsers,
  farRightFromBrackets,
  farRightToBrackets,
  farCircle,
  farCrown,
  farTicket,
  farMoneyBillTransfer,
  farFileExcel,
  farCartCircleXmark,
  farRegularCopy,
  farCircleArrowLeft,
  farCircleArrowRight,
  farDisplay,
  farTruckFast,
  farDolly,
  farUserGraduate,
  farRegularLink,
  // BRANDS ICONS BELOW HERE
  fabCcVisa,
  fabCCMastercard,
  fabWhatsapp,
  fabAmazon,
  fabShopify,
  fabWitter,
  fabFacebook,
  fabInstagram,
  fabWordpress,
  fabTikTok,
  fabTelegram
);
