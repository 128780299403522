import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "react-skeleton-generator";
import { shopifyService } from "../../services/api/shopify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCard from "../../components/store/ProductCard";
import BounceLoader from "react-spinners/BounceLoader";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import * as _ from "lodash";
import "../../components/store/CarrouselProducts";
import CarrouselProducts from "../../components/store/CarrouselProducts";
import CategoryCard from "../../components/store/CategoryCard";

const sortFilters = [
  { id: "mostsell", title: "Más vendidos" },
  { id: "alfa", title: "Alfabeticamente A-Z" },
  { id: "alfz", title: "Alfabeticamente Z-A" },
  { id: "cheapest", title: "Precio: Menor a mayor" },
  { id: "expensive", title: "Precio: Mayor a menor" },
  { id: "old", title: "Fecha: Más antiguo" },
  { id: "new", title: "Fecha: Más reciente" },
];

const Index = () => {
  localStorage.removeItem("scrollPosition");

  const navigate = useNavigate();

  const [offers, setOffers] = useState([]);
  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState(null);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [originalCollectionProducts, setOriginalCollectionProducts] = useState(
    []
  );
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsSearch, setProductsSearch] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedCategories, setIsLoadedCategories] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState(null);
  const [selectedSortedOption, setSelectedSortedOption] = useState("mostsell");
  const [showFilters, setShowFilters] = useState(false);

  const [title, setTitle] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  const goToProductDetail = (id, store_id) => {
    navigate(`/tienda/${store_id}/producto/${id}`);
  };

  const handleSearchCollection = (event) => {
    let searchText = event.target.value.trim().toLowerCase();
    if (searchText === "") {
      setProducts(originalCollectionProducts);
    } else {
      let searchWords = searchText.split(" ");

      setProducts(
        originalCollectionProducts
          .filter((product) =>
            searchWords.every((word) =>
              product.title.toLowerCase().includes(word)
            )
          )
          .sort((a, b) => {
            return searchWords.reduce((acc, word) => {
              if (acc !== 0) return acc; // Si ya se determinó el orden, no continuar.

              const aIndex = a.title.toLowerCase().indexOf(word);
              const bIndex = b.title.toLowerCase().indexOf(word);

              // Compara las posiciones de las palabras en los títulos.
              if (aIndex === -1 && bIndex !== -1) return 1;
              if (bIndex === -1 && aIndex !== -1) return -1;
              if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;

              return 0;
            }, 0);
          })
      );
    }
  };
  const navigateToCategory = (store_id, id) => {
    navigate(`/tienda/${store_id}/categoria/${id}`);
  };

  const addToTags = (tag) => {
    let tagsToAdd = [...selectedTags];
    let tagsFound = selectedTags.filter((t) => t === tag).length > 0;

    if (tagsFound) {
      tagsToAdd = tagsToAdd.filter((t) => t !== tag);
    } else {
      tagsToAdd.push(tag);
    }

    setSelectedTags(tagsToAdd);
  };

  const handleSearchProducts = () => {
    setIsLoading(true);
    setLoadingProducts(true);
    setShowSearchContent(true);

    let params;
    params = {
      title: title,
    };
    shopifyService
      .getAllProductsByTitle(params)
      .then((response) => {
        setProductsSearch(response.data.products);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
        setLoadingProducts(false);
      });
  };

  const fetchInitialProducts = () => {
    shopifyService
      .getAllProducts()
      .then((response) => {
        let products = response.data.products;
        products.sort(function (a, b) {
          return a.sold > b.sold ? -1 : a.sold < b.sold ? 1 : 0;
        });
        products.splice(25);
        setProducts(products);
        setOriginalProducts(products);
      })
      .catch((err) => {});
  };

  const fetchOfferProducts = () => {
    shopifyService
      .getOfferProducts()
      .then((response) => {
        let products = response.data.products;
        if (products) {
          setOffers(products);
        }
      })
      .catch((err) => {});
  };

  const fetchCollections = () => {
    shopifyService
      .getAllCollections()
      .then((response) => {
        let collectionsFetch = response.data.collects;
        setCollections(collectionsFetch);
      })
      .catch((err) => {})
      .finally(() => setIsLoadedCategories(true));
  };

  const fetchCollectionById = (id) => {
    shopifyService
      .getCollection(id)
      .then((response) => {
        let data = response.data;
        setCollection(data.collection);
        setOriginalCollectionProducts(data.products);
        setSelectedSortedOption("mostsell");
        setTags(data.tags);
        setProducts(data.products);
        setShowSidebar(false);
      })
      .catch((err) => {});
  };

  const handleChangeCategory = (event) => {
    setSelectedCategoryOption(parseInt(event.target.value));
    setSelectedTags([]);
  };

  const handleOnSearchChange = (event) => {
    setTitle(event.target.value);

    if (collection) {
      handleSearchCollection(event);
    }
  };

  const handleOnSortCategory = (event) => {
    const value = event.target.value;
    setSelectedSortedOption(value);

    if (products) {
      let filteredProducts = [...originalCollectionProducts];

      if (selectedTags.length > 0) {
        filteredProducts = filteredProducts.filter((product) => {
          return selectedTags.some((tag) => product.tags.includes(tag));
        });
      }

      const sortFunctions = {
        cheapest: (a, b) =>
          parseFloat(a.variants.edges[0].node.price.amount) -
          parseFloat(b.variants.edges[0].node.price.amount),
        expensive: (a, b) =>
          parseFloat(b.variants.edges[0].node.price.amount) -
          parseFloat(a.variants.edges[0].node.price.amount),
        new: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        old: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        alfa: (a, b) => a.title.localeCompare(b.title),
        alfz: (a, b) => b.title.localeCompare(a.title),
        mostsell: (a, b) => (a.sold > b.sold ? -1 : a.sold < b.sold ? 1 : 0),
      };

      if (sortFunctions[value]) {
        if (selectedTags.length === 0) {
          filteredProducts = originalCollectionProducts.slice(); // Asigna los productos completos
        }

        if (value === "mostsell") {
          setProducts(filteredProducts);
          return;
        }

        filteredProducts.sort(sortFunctions[value]);
      } else {
        filteredProducts = originalCollectionProducts.slice();
      }

      setProducts(filteredProducts);
    }

    setShowSidebar(false);
  };

  const cleanFilters = () => {
    setSelectedCategoryOption(null);
    setTitle("");
    setProducts(originalProducts);
    setCollection(null);
    setOriginalCollectionProducts([]);
    setShowSearchContent(false);
    setSelectedSortedOption("mostsell");
    setSelectedTags([]);
    setTags([]);
    setShowSidebar(false);
  };

  useEffect(() => {
    if (selectedCategoryOption) {
      fetchCollectionById(selectedCategoryOption);
    }
  }, [selectedCategoryOption]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (title.length === 0) setShowSearchContent(false);
  }, [title]);

  useEffect(() => {
    fetchInitialProducts();
    fetchCollections();
    fetchOfferProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let vars = [products, collections];

    if (vars.every((element) => !_.isEmpty(element))) {
      setIsLoaded(true);
    }
  }, [products, collections]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedCategoryOption && selectedSortedOption && tags.length > 0) {
      let event = { target: { value: selectedSortedOption } };
      handleOnSortCategory(event);
    }
  }, [selectedTags]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="pb-12 lg:pb-24">
      {/* TOP */}
      <div className="mx-auto w-full">
        <div className="bg-primary-new h-14 lg:h-24 flex justify-center items-center">
          <p className="text-white font-bold text-[13px] lg:text-[22px]">
            Beneficios exclusivos que ofrecemos a nuestros miembros.
          </p>
        </div>
      </div>

      {/* HEADER */}
      <div className="mb-4 lg:mb-8 lg:shadow-md">
        <div className="container mx-auto px-0 md:px-4 flex flex-col md:flex-row justify-between items-center py-5 lg:py-8 space-y-5 md:space-y-0">
          <div>
            <p className="font-bold text-primary-new-50 text-center text-[25px] md:text-left lg:text-[33px]">
              {collection ? collection.title : "Lo más vendido"}
            </p>
            {!collection && (
              <p className="font-bold text-gray-new text-center text-[13px] md:text-left lg:text-base">
                Accede a los productos más vendidos y{" "}
                <br className="md:hidden" /> rentables{" "}
                <br className="hidden md:block lg:hidden" /> del mercado con
                precios preferenciales
                <br className="md:hidden" /> para miembros
              </p>
            )}
          </div>

          {/* SEARCH BAR */}
          <div className="flex justify-end items-center w-full px-4 md:px-0 md:w-auto">
            <div className="relative w-full">
              <input
                type="text"
                id="searchInput"
                className="pl-4 pr-10 py-2 border border-gray-500 rounded-xl w-full focus:outline-none"
                placeholder="Buscar productos"
                value={title}
                onChange={handleOnSearchChange}
                onKeyDown={(event) => {
                  if (collection) return;

                  if (event.key === "Enter" && title.length > 0) {
                    handleSearchProducts();
                  }
                }}
              />
              {!collection && (
                <button
                  type="button"
                  id="searchButton"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-primary-new focus:outline-none"
                  onClick={() =>
                    title.length > 0 && !isLoading && handleSearchProducts()
                  }
                >
                  <FontAwesomeIcon
                    icon={[
                      "fa",
                      isLoading ? "circle-notch" : "magnifying-glass",
                    ]}
                    size="1x"
                    spin={isLoading}
                  />
                </button>
              )}
            </div>
          </div>

          {/* FILTERS MOBILE BUTTON */}
        </div>
      </div>

      {/* CONTENT */}

      <div className="w-full">
        <div className="container mx-auto w-full pt-2 md:pt-6 px-4">
          <div>
            <div
              className={`grid grid-cols-2 xl:grid-cols-5 2xl:grid-cols-6"
              }`}
            >
              {!showSearchContent ? (
                <>
                  <div className=" col-span-4 lg:col-span-3 xl:col-span-5 ">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
                      {!isLoadedCategories && productsSkeleton("categories")}
                      {isLoadedCategories &&
                        collections.map((category) => (
                          <CategoryCard
                            key={category.id}
                            category={category}
                            onClick={navigateToCategory}
                          />
                        ))}
                    </div>
                  </div>

                  {/* OFFERS */}
                  {offers.length > 0 && (
                    <div className="col-span-full">
                      <div className="container mx-auto px-0 md:px-8 xl:px-0 py-4 md:py-8 lg:py-16 space-y-8">
                        <div>
                          <p className="font-bold text-primary-new-50 text-center text-[25px] md:text-left lg:text-[33px]">
                            Ofertas
                          </p>
                          <p className="font-bold text-gray-new text-center text-[13px] md:text-left lg:text-base">
                            Accede a los productos más vendidos y rentables del
                            mercado con precios preferenciales para miembros
                          </p>
                        </div>

                        {/* PRODUCT LIST */}
                        <CarrouselProducts
                          products={offers}
                          productsSkeleton={() => productsSkeleton("offers")}
                        ></CarrouselProducts>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="col-span-4 md:col-span-3 xl:col-span-4 2xl:col-span-5">
                  {productsSearch.length && !loadingProducts > 0 ? (
                    <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 place-items-center gap-y-8 md:gap-5 xl:gap-8">
                      {productsSearch.map((product) => (
                        <ProductCard
                          key={product.id}
                          category_id={product.shopify_store_id}
                          product={product}
                          onClick={goToProductDetail}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="mx-auto container w-full text-2xl my-10">
                      {loadingProducts ? (
                        <div className="py-10">
                          <BounceLoader
                            color={"#062F55"}
                            loading={true}
                            cssOverride={{
                              display: "block",
                              margin: "0 auto",
                              borderColor: "red",
                              color: "blue",
                            }}
                            size={100}
                            aria-label="BeatLoader"
                            data-testid="BeatLoader"
                          />
                          <p className="font-bold text-primary-new-50 text-center text-[25px] lg:text-[33px]">
                            Buscando productos.
                          </p>
                          <p className="font-bold text-gray-new text-center text-[13px] lg:text-base pb-10">
                            Espere un momento...
                          </p>
                        </div>
                      ) : (
                        <NoProductsFound />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* FILTERS MOBILE */}
      <>
        {/* Sidebar */}
        <div
          className={`block md:hidden w-5/6 text-gray-700 h-full fixed top-0 left-0 z-50 shadow-md ease-in-out duration-300 overflow-auto transform ${
            showSidebar ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ backgroundColor: "#FCFBFC" }}
        >
          {showFilters && (
            <div className="mt-5 text-xl p-5">
              <p className="font-bold text-gray-new text-left text-2xl pb-5">
                FILTRAR Y ORDENAR
              </p>

              <div className="border-t border-[#E6E6E6] py-4">
                <div>
                  <p className="font-bold text-gray-new text-left text-xl pb-5">
                    Proveedores
                  </p>

                  {isLoadedCategories && (
                    <form>
                      {collections.map((category) => (
                        <div
                          key={category.id}
                          className="flex flex-col items-start"
                        >
                          <label className="flex flex-row items-start text-sm font-bold text-gray-new leading-5">
                            <input
                              type="radio"
                              name="opcion"
                              value={category.id}
                              className="mr-2 mt-[2px] accent-primary-new"
                              checked={selectedCategoryOption === category.id}
                              onChange={handleChangeCategory}
                            />
                            {category.title}
                          </label>
                        </div>
                      ))}
                    </form>
                  )}
                </div>

                {collection && (
                  <>
                    <hr className="border-[#E6E6E6] my-4" />
                    <div>
                      <p className="font-bold text-gray-new text-left text-xl pb-5">
                        Ordenar
                      </p>

                      <form>
                        {sortFilters.map((filter) => (
                          <div
                            key={filter.id}
                            className="flex flex-col items-start"
                          >
                            <label className="flex flex-row items-start text-sm font-bold text-gray-new leading-5">
                              <input
                                type="radio"
                                name="opcion"
                                value={filter.id}
                                className="mr-2 mt-[2px] accent-primary-new"
                                checked={selectedSortedOption === filter.id}
                                onChange={handleOnSortCategory}
                              />
                              {filter.title}
                            </label>
                          </div>
                        ))}
                      </form>
                    </div>
                  </>
                )}

                {/* CLEAN FILTERS */}
                <div className="w-full">
                  <button
                    className="flex justify-center text-sm items-center border border-gray-500 rounded-xl px-2 py-1 w-full mt-5 hover:bg-gray-200"
                    onClick={cleanFilters}
                  >
                    <span className="font-bold text-gray-new">
                      Limpiar filtros
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Overlay */}
        {showSidebar && (
          <div
            role="button"
            className="fixed inset-0 bg-black bg-opacity-25 z-40 ease-in-out duration-150"
            onClick={() => setShowSidebar(false)}
          />
        )}
      </>
    </div>
  );
};

const productsSkeleton = (section) => {
  return [...Array(5)].map((e, idx) => (
    <Skeleton.SkeletonThemeProvider key={idx} animation="opacity">
      <div key={`${section}-${idx}`} className="h-72">
        <Skeleton height="100%" borderRadius="0px" />
      </div>
    </Skeleton.SkeletonThemeProvider>
  ));
};

const NoProductsFound = () => {
  return (
    <div className="flex flex-col mx-auto container w-full space-y-5">
      <FontAwesomeIcon
        icon={["fa", "face-thinking"]}
        className="text-secondary-new"
        size="4x"
      />
      <div className="flex flex-col space-y-3">
        <p className="font-bold text-primary-new-50 text-center text-[25px] lg:text-[33px]">
          No se encontraron productos
        </p>
        <p className="font-bold text-gray-new text-center text-[13px] lg:text-base leading-5">
          No hay productos que coincidan con tus filtros actuales.{" "}
          <br className="hidden md:block" /> Intenta eliminar algunos de ellos
          para obtener mejores resultados.
        </p>
      </div>
    </div>
  );
};

export default Index;
