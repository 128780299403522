import { useRef, useEffect } from "react";
import { Player, Hls, DefaultUi, DefaultSettings } from "@vime/react";
import "@vime/core/themes/default.css";

import posterBackup from "../assets/images/imagen-contenido-curso.png";
import { loggerServices } from "../services/api/logger";

const VideoPlayer = ({
  url,
  poster,
  handleSaveLessonProgress,
  setResourceFound,
  setVideoError,
}) => {
  const playerRef = useRef(null);

  const hlsConfig = {
    autoStartLoad: true,
    startPosition: -1,
    debug: false,
    manifestLoadingTimeOut: 30000,
    manifestLoadingMaxRetry: 5,
    manifestLoadingRetryDelay: 1500,
    manifestLoadingMaxRetryTimeout: 80000,
    fragLoadingTimeOut: 40000,
    fragLoadingMaxRetry: 8,
    fragLoadingRetryDelay: 1500,
    fragLoadingMaxRetryTimeout: 80000,
    maxBufferLength: 30, // Máximo de segundos en el buffer
    maxMaxBufferLength: 60, // Límite máximo absoluto para el buffer
    maxBufferSize: 60 * 1000 * 1000, // Tamaño máximo en bytes para el buffer
    maxBufferHole: 0.5, // Tamaño máximo del agujero en el buffer en segundos
  };

  const es = {
    play: "Reproducir",
    pause: "Pausa",
    mute: "Silenciar",
    enterPiP: "Reproductor en miniatura",
    exitPiP: "Expandir",
    settings: "Configuraciones",
    unmute: "Activar sonido",
    enterFullscreen: "Pantalla completa",
    exitFullscreen: "Salir de pantalla completa",
    playbackRate: "Velocidad de reproducción",
    playbackQuality: "Calidad",
    none: "Ninguno",
    subtitlesOrCc: "Subtítulos",
    default: "Por defecto",
    normal: "Normal",
    audio: "Audio",
    scrubber: "-",
    scrubberLabel: "-",
  };

  const videoPlaybackEnded = (event) => {
    handleSaveLessonProgress(event.detail);
  };

  const handleNotFoundResource = (event) => {
    if (!event?.detail) return;

    let response = event?.detail?.data?.response;
    let is404 = false;

    if (response?.code === 404) {
      setResourceFound(false);
      is404 = true;
    }

    let eventProperties = {
      isTrusted: event.isTrusted || null,
      bubbles: event.bubbles || null,
      cancelBubble: event.cancelBubble || null,
      cancelable: event.cancelable || null,
      composed: event.composed || null,
      currentTarget: event.currentTarget || null,
      defaultPrevented: event.defaultPrevented || null,
      detail: event.detail || null,
      eventPhase: event.eventPhase || null,
      returnValue: event.returnValue || null,
      target: event.target || null,
      timeStamp: event.timeStamp || null,
      type: event.type || null,
    };

    const replacer = (key, value) => {
      if (value instanceof EventTarget) {
        return "[EventTarget]";
      }
      if (key === "stateNode") {
        return "[FiberNode]";
      }
      return value;
    };

    if (!is404) {
      setVideoError(true);
    }

    try {
      loggerServices
        .addLogger({
          error: ["Error detectado en el reproductor de video"],
          info: [JSON.stringify(eventProperties, replacer)],
          url: [window.location.href],
          resolution: [
            window.screen.width + "w X " + window.screen.height + "h",
          ],
        })
        .then(() => {})
        .catch((err) => {});
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA")
        return;
      if (e.keyCode === 32) {
        e.preventDefault();
        if (playerRef.current) {
          if (playerRef.current.paused) return playerRef.current.play();
          return playerRef.current.pause();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Player
      ref={playerRef}
      onVmPlaybackEnded={videoPlaybackEnded}
      translations={{ es }}
      language="es"
    >
      <Hls
        version="1.4.14"
        config={hlsConfig}
        poster={poster ?? posterBackup}
        preload="auto"
        onVmError={(event) => handleNotFoundResource(event)}
      >
        <source data-src={url} type="application/x-mpegURL" />
      </Hls>

      <DefaultUi noCaptions={true}>
        <DefaultSettings />
      </DefaultUi>
    </Player>
  );
};

export default VideoPlayer;
