import { useEffect } from "react";
import { isEmpty } from "lodash";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import noImageProduct from "../../assets/images/store/no-image-product.jpg";

const ProductImages = ({ images, variantImage }) => {
  useEffect(() => {
    console.log(variantImage);
  }, [variantImage]);

  return (
    <div className="">
      {/* SWIPER MOBILE */}
      <div className="block md:hidden relative">
        {images.length > 0 ? (
          !isEmpty(variantImage) ? (
            <div className="flex w-full h-[465px]">
              <img
                src={variantImage.src}
                alt={variantImage.alt}
                className="m-1 cursor-pointer w-full h-full object-cover"
                onClick={() => null}
              />
            </div>
          ) : (
            <Swiper
              slidesPerView={1}
              spaceBetween={5}
              pagination={{
                dynamicBullets: true,
                clickable: false,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={images.length > 1 ? true : false}
              navigation={false}
              longSwipes={true}
              modules={[Autoplay, Pagination]}
              className="productImagesSwiper"
            >
              {images.map((image) => (
                <SwiperSlide key={image.id}>
                  <div className="flex w-full h-[465px]">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="m-1 cursor-pointer w-full h-full object-cover"
                      onClick={() => null}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )
        ) : (
          <div className="flex w-full h-[465px]">
            <img
              src={noImageProduct}
              alt="Sin imagen"
              className="m-1 cursor-pointer w-full h-full object-cover"
              onClick={() => null}
            />
          </div>
        )}
      </div>

      {/* IMAGES MD+ */}
      <div className="hidden md:block">
        {images.length > 0 ? (
          !isEmpty(variantImage) ? (
            <div className="flex w-full h-[465px]">
              <img
                src={variantImage.src}
                alt={variantImage.alt}
                className="w-full h-[560px] xl:h-[674px] border border-[#E6E6E6] p-1 object-cover"
                onClick={() => null}
              />
            </div>
          ) : (
            <div>
              {images.length > 1 && (
                <div
                  className={`grid grid-cols-2 mb-4 ${
                    images.length > 2 ? "gap-x-4" : ""
                  }`}
                >
                  <div
                    className={`${
                      images.length > 2 ? "col-span-1" : "col-span-2"
                    }`}
                  >
                    <img
                      src={images[1].src}
                      alt={images[1].alt}
                      className="w-full h-[200px] xl:h-[317px] border border-[#E6E6E6] p-1 object-cover"
                      onClick={() => null}
                    />
                  </div>

                  {images.length > 2 && (
                    <div>
                      <img
                        src={images[2].src}
                        alt={images[2].alt}
                        className="w-full h-[200px] xl:h-[317px] border border-[#E6E6E6] p-1 object-cover"
                        onClick={() => null}
                      />
                    </div>
                  )}
                </div>
              )}

              {/* MAIN IMAGE */}
              <div>
                <img
                  src={images[0].src}
                  alt={images[0].alt}
                  className="w-full h-[360px] xl:h-[474px] border border-[#E6E6E6] p-1 object-cover"
                  onClick={() => null}
                />
              </div>
            </div>
          )
        ) : (
          <div>
            <img
              src={noImageProduct}
              alt="Sin imagen"
              className="w-full h-[360px] xl:h-[474px] border border-[#E6E6E6] p-1 object-cover"
              onClick={() => null}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductImages;
