import { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";

import useProfile from "../../hooks/useProfile";

import Context from "../../context/UserContext";

const Billing = ({ desktop = false }) => {
  const regex = /^(?:\+?\d+\s?)?(?:\(\d+\)|\d+)(?:-?\s?\d+)*$/;
  const formSchema = Yup.object().shape({
    phone: Yup.string()
      .max(10, `Máximo 10 caracteres`)
      .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  });

  const { currentUser } = useContext(Context);
  const { isLoading, getBillingInfo, billingInfo, updateBillingInfo } =
    useProfile();

  const [initialValues, setInitialValues] = useState({
    rfc: "",
    business_name: "",
    address: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    if (currentUser) getBillingInfo();
  }, [currentUser, getBillingInfo]);

  useEffect(() => {
    setInitialValues({
      rfc: billingInfo.rfc || "",
      business_name: billingInfo.business_name || "",
      address: billingInfo.address || "",
      phone: billingInfo.phone || "",
      email: billingInfo.email || "",
    });
  }, [billingInfo]);

  const handleSubmit = async (values) => {
    !values.rfc && delete values.rfc;
    !values.business_name && delete values.business_name;
    !values.address && delete values.address;
    !values.phone && delete values.phone;
    !values.email && delete values.email;
    await updateBillingInfo(values);
  };

  const getMembershipName = () => {
    if (!currentUser) return;

    if (currentUser.hasMembership) {
      return currentUser.membership_name;
    }

    return null;
  };

  const getMembershipExpiredAt = () => {
    if (!currentUser) return;

    if (currentUser.hasMembership) {
      if (
        moment(currentUser.membership_expired_at).isAfter("2035-01-01 00:00:00")
      ) {
        return "INDEFINIDA";
      }
      return moment(currentUser.membership_expired_at).format("DD-MM-YYYY");
    }

    return null;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form>
        {!desktop && (
          <div>
            {currentUser && currentUser.hasMembership && (
              <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
                <p>Nivel Membresía</p>
                <input
                  name="nivel_membresia"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                  type="text"
                  readOnly
                  value={getMembershipName()}
                />
              </div>
            )}

            {currentUser && currentUser.hasMembership && (
              <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
                <p>Caducidad</p>
                <input
                  name="caducidad"
                  className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                  type="text"
                  readOnly
                  value={getMembershipExpiredAt()}
                />
              </div>
            )}

            <p className="text-gray-700 font-bold text-center lg:text-left text-xl lg:text-2xl pb-5">
              Datos de Facturación
            </p>
          </div>
        )}

        <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
          <p>RFC</p>
          <Field
            name="rfc"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            type="text"
          />
          <ErrorMessage
            name="rfc"
            component="div"
            className="text-red-400 font-bold text-xl"
          />
        </div>

        <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
          <p>Razón Social</p>
          <Field
            name="business_name"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            type="text"
          />
          <ErrorMessage
            name="business_name"
            component="div"
            className="text-red-400 font-bold text-xl"
          />
        </div>

        <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
          <p>Dirección</p>
          <Field
            name="address"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            type="text"
          />
          <ErrorMessage
            name="address"
            component="div"
            className="text-red-400 font-bold text-xl"
          />
        </div>

        <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
          <p>Teléfono</p>
          <Field
            name="phone"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            type="text"
          />
          <ErrorMessage
            name="phone"
            component="div"
            className="text-red-400 font-bold text-xl"
          />
        </div>

        <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
          <p>Correo</p>
          <Field
            name="email"
            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            type="email"
          />
          <ErrorMessage
            name="email"
            component="div"
            className="text-red-400 font-bold text-xl"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className={`${
              !desktop ? "md:w-1/2 lg:w-1/3" : ""
            } w-full  bg-primary-new text-white font-bold text-xl lg:text-2xl py-3 disabled:opacity-75 rounded-full`}
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              <span>Actualizar</span>
            )}
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default Billing;
