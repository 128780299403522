import { NavLink } from "react-router-dom";

const NavBarItem = ({ item }) => {
  return (
    <div className="text-xl border-t border-gray-350 py-4 lg:py-5">
      <NavLink end to={item.link}>
        {({ isActive }) => (
          <span
            className={isActive ? "text-primary-new  font-bold" : undefined}
          >
            {item.label}
          </span>
        )}
      </NavLink>
    </div>
  );
};

export default NavBarItem;
