/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cards from "react-credit-cards";
import { ordersService } from "../services/api/orders";
import { mercadoServices } from "../services/api/mercadoPago";
import { couponServices } from "../services/api/coupons";

import "react-credit-cards/es/styles-compiled.css";
import logo from "../assets/images/logo-globe-gbp.png";
import { profileService } from "../services/api/profile";
import mexicoStates from "../utils/mexicoStates";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { notifications } from "../utils/notifications";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import ProductCheckout from "../components/checkout/ProductCheckout";
import _ from "lodash";
import MainItemCheckout from "../components/checkout/MainItemCheckout";
import UserContext from "../context/UserContext";
import useUser from "../hooks/useProfile";
import { validationPrice } from "../utils";

const ShoppingCart = () => {
  const { getUser } = useUser();
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [referralEmail, setReferralEmail] = useState(
    currentUser ? currentUser.code : null
  );
  const [saveAddress, setSaveAddress] = useState(false);
  const [showCardMessage, setShowCardMessage] = useState(false);
  const [showShippingMessage, setShowShippingMessage] = useState(false);
  const [hasCupon, setHasCupon] = useState(false);
  const [isItemAplied, setIsItemAplied] = useState(false);
  const [isItemInCart, setIsItemInCart] = useState(false);
  const [showAlerts, setShowAlerts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [shippingsOptions, setShippingsOption] = useState(null);
  const [currentShipping, setCurrentShipping] = useState(null);

  const color = "#394875";
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    color: "blue",
  };

  const [orders, setOrders] = useState();
  const [addresses, setAddresses] = useState();
  const [radio, setRadio] = useState(0);
  const [isNewShippingAddress, setIsNewShippingAddress] = useState(false);
  const [mainOrder, setMainOrder] = useState({});
  const [vendorOrder, setVendorOrder] = useState({});
  const [showMainOrder, setShowMainOrder] = useState(true);
  const [productsOrders, setProductsOrders] = useState([]);
  const [orderIdActive, setOrderIdActive] = useState(null);
  const [shippingDaysActive, setShippingDaysActive] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);

  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponsApplied, setCouponsApplied] = useState([]);
  const formRef = useRef();
  const cardRef = useRef();

  const [cardState, setCardState] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    cardholderName: "",
    cardNumber: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    securityCode: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const assignShippingsOrder = (id) => {
    productsOrders.forEach((order) => {
      if (order.id === id) {
        setShippingsOption(order.shippings);
      }
    });
  };

  const selectShippingDays = (id) => {
    shippingsOptions.forEach((shipping) => {
      if (parseInt(shipping.id) === parseInt(id)) {
        setShippingDaysActive(shipping.days);
        setShippingCost(shipping.cost);
      }
    });
    if (parseInt(id) === 0) {
      setShippingCost(0);
      setShippingDaysActive(null);
    }
  };

  const fetchOrders = () => {
    ordersService
      .getCheckout()
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/carrito-de-compras");
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchAddress = () => {
    profileService
      .getAddresses()
      .then((response) => {
        setAddresses(response.data.addresses);
      })
      .catch((err) => {});
  };

  const getProductsOrderTotal = (order) => {
    let total = 0;

    order.products.forEach((product) => {
      let variant = getVariant(product, product.variant_id);
      let variantPrice = showPrice(product)
        ? variant.price
        : variant.compare_at_price > 0
        ? variant.compare_at_price
        : variant.price;

      total += product.amount * variantPrice;
    });
    return total;
  };

  const showPrice = (product) => {
    return validationPrice(currentUser, product.vendor);
  };

  const getVariant = (product, variant_id) => {
    let variant;
    Object.keys(product.variants).forEach((key) => {
      if (variant_id === product.variants[key].id) {
        variant = product.variants[key];
      }
    });
    return variant;
  };
  const getMainOrderTotal = () => {
    let total = 0;

    mainOrder.courses.forEach((course) => {
      total += course.cost;
    });

    mainOrder.services.forEach((service) => {
      total += service.cost;
    });

    mainOrder.memberships.forEach((membership) => {
      total += membership.cost;
    });

    return total;
  };

  const assignOrders = () => {
    let productsOrderArr = [];

    orders.forEach((order) => {
      if (_.has(order, "services")) {
        setMainOrder(order);
      } else {
        productsOrderArr.push(order);
      }
    });
    setProductsOrders(productsOrderArr);
  };

  useEffect(() => {
    if (_.isEmpty(orders)) return;
    assignOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (_.isEmpty(productsOrders)) return;
    fetchAddress();
  }, [productsOrders, code]);

  function containsNumbers(str) {
    return /\d/.test(str);
  }

  const handleOrder = () => {
    const hasMembership = mainOrder.memberships.length > 0;

    let bodyFormData = new FormData();

    if (couponsApplied.length > 0) {
      bodyFormData.append("coupons[] ", couponsApplied);
    }
    if (getMainOrderTotal() !== 0) {
      let values = cardRef.current.values;
      if (
        !values.cardNumber ||
        !values.cardholderName ||
        !values.cardExpirationMonth ||
        !values.cardExpirationYear ||
        !values.securityCode ||
        values.cardNumber.length !== 16 ||
        values.cardExpirationMonth.length !== 2 ||
        values.cardExpirationYear.length !== 2 ||
        values.securityCode.length < 3 ||
        containsNumbers(values.cardholderName) ||
        !containsNumbers(values.cardNumber)
      ) {
        setShowCardMessage(true);
        return;
      } else {
        setShowCardMessage(false);
      }

      setIsOrderProcessing(true);

      if (cardState.cardNumber !== "") {
        bodyFormData.append("card_number", cardState.cardNumber);
        bodyFormData.append("security_code", cardState.securityCode);
        bodyFormData.append("expiration_month", cardState.cardExpirationMonth);
        bodyFormData.append("expiration_year", cardState.cardExpirationYear);
        bodyFormData.append("holder_name", cardState.cardholderName);
      }
    }
    if (hasMembership && !referralEmail) {
      setIsOrderProcessing(false);
      return notifications.error("El correo de referencia es requerido.");
    }
    if (referralEmail) {
      bodyFormData.append("code", referralEmail);
    }

    mercadoServices
      .setPay(mainOrder.id, bodyFormData)
      .then((response) => {
        if (mainOrder.memberships.length > 0) {
          getUser();
        }
        if (_.isEmpty(productsOrders)) {
          navigate("/carrito-de-compras/pago/aceptado");
        } else {
          notifications.successBuy("Pago realizado con éxito.");
          setShowMainOrder(false);
          setOrderIdActive(null);
          setShowPaymentCard(false);
          setMainOrder({});
        }
      })
      .catch((err) => {
        if (_.isEmpty(productsOrders)) {
          navigate("/carrito-de-compras/pago/declinado", {
            state: { message: err.response.data.message },
          });
        }
      })
      .finally(() => setIsOrderProcessing(false));
  };

  const handleOrderTransfer = () => {
    let values = formRef.current.values;
    let bodyFormData = new FormData();
    if (
      !values.street_and_number ||
      !values.colony ||
      !values.city ||
      !values.state ||
      !values.postal_code ||
      !values.phone ||
      !values.receives ||
      !values.sender_phone ||
      !values.sender_name ||
      !values.reference ||
      currentShipping === 0 ||
      currentShipping === "0" ||
      !currentShipping
    ) {
      setShowShippingMessage(true);
      return;
    } else {
      setShowShippingMessage(false);
    }

    setIsOrderProcessing(true);

    if (isNewShippingAddress) {
      bodyFormData.append("street_and_number", values.street_and_number);
      bodyFormData.append("colony", values.colony);
      bodyFormData.append("city", values.city);
      bodyFormData.append("state", values.state);
      bodyFormData.append("country", "MEXICO");
      bodyFormData.append("postal_code", values.postal_code);
      bodyFormData.append("phone", values.phone);
      bodyFormData.append("receives", values.receives);
      bodyFormData.append("sender_phone", values.sender_phone);
      bodyFormData.append("sender_name", values.sender_name);
      bodyFormData.append("reference", values.reference);
      if (saveAddress) {
        bodyFormData.append("save", 1);
      } else {
        bodyFormData.append("save", 0);
      }
    } else if (radio !== 0) {
      bodyFormData.append("address_id", radio);
      const _address = addresses.find((a) => a.id === radio);
      if (_address) {
        bodyFormData.append("phone", values.phone);
        bodyFormData.append("receives", values.receives);
        bodyFormData.append("sender_phone", values.sender_phone);
        bodyFormData.append("sender_name", values.sender_name);
        bodyFormData.append("reference", values.reference);
      }
    }

    bodyFormData.append("shipping_id", parseInt(currentShipping));

    mercadoServices
      .setPay(orderIdActive, bodyFormData)
      .then((response) => {
        notifications.success("Se confirmo la orden con éxito.");

        if (!_.isEmpty(mainOrder) || productsOrders.length > 1) {
          let filterProductsOrder = productsOrders.filter(
            (order) => order.id !== vendorOrder.id
          );
          setProductsOrders(filterProductsOrder);
          setVendorOrder({});
          setOrderIdActive(null);

          setIsOrderProcessing(false);
          setCurrentShipping(0);
          setShippingCost(0);
          setShippingDaysActive(null);
        } else {
          navigate("/carrito-de-compras/pago/confirmado");
        }
      })
      .catch((err) => {})
      .finally(() => setIsOrderProcessing(false));
  };

  const addCouponToItem = (mainOrderItems, item, coupon) => {
    let isPercent;
    let _isItemAplied = false;
    let _isItemInCart = false;

    mainOrderItems.forEach((itemOrder) => {
      if (item.uuid === itemOrder.uuid) {
        if (
          itemOrder.couponsCodes &&
          itemOrder.couponsCodes.includes(coupon.code)
        ) {
          setIsItemAplied(true);
          notifications.warn(`El cupón "${coupon.code}" ya fue canjeado`);
          _isItemAplied = true;
        } else {
          if (itemOrder.cost !== 0) {
            itemOrder.old_cost = itemOrder.cost;
          }
          if (coupon.type === "Fixed") {
            itemOrder.cost = itemOrder.cost - coupon.amount;
          } else {
            isPercent = true;
            itemOrder.cost =
              itemOrder.cost - itemOrder.cost * (0.01 * coupon.amount);
          }
          if (itemOrder.cost < 0) {
            itemOrder.cost = 0;
          }
          setIsItemInCart(true);
          _isItemInCart = true;

          if (itemOrder.couponsCodes) {
            let serviceCoupons = itemOrder.couponsCodes;
            let serviceCosts = itemOrder.couponsCosts;
            let couponsCopy = [...serviceCoupons];
            let couponsCopyCosts = [...serviceCosts];
            couponsCopy.push(coupon.code);
            let couponCost = coupon.amount;
            if (isPercent) {
              couponCost = itemOrder.cost * (0.01 * coupon.amount);
            }
            couponsCopyCosts.push(couponCost);
            itemOrder.couponsCodes = couponsCopy;
            itemOrder.couponsCosts = couponsCopyCosts;
          } else {
            itemOrder.couponsCodes = [coupon.code];
            let couponCost = coupon.amount;
            if (isPercent) {
              couponCost = itemOrder.old_cost * (0.01 * coupon.amount);
            }
            itemOrder.couponsCosts = [couponCost];
          }
        }
      }
    });

    if (_isItemInCart && !_isItemAplied) {
      setCouponsApplied((current) => [...current, coupon.id]);
    }
  };

  const applyCoupon = (coupon, items) => {
    setIsItemInCart(false);
    setIsItemAplied(false);

    items.forEach((item) => {
      switch (item.type) {
        case "service":
          addCouponToItem(mainOrder.services, item, coupon);
          break;
        case "course":
          addCouponToItem(mainOrder.courses, item, coupon);
          break;
        default:
          addCouponToItem(mainOrder.memberships, item, coupon);
          break;
      }
    });

    setShowAlerts(true);

    setHasCupon(!hasCupon);
    setCoupon("");
  };

  useEffect(() => {
    if (showAlerts) {
      if (!isItemInCart && !isItemAplied) {
        notifications.error("El cupón no es válido para este carrito");
      }

      setShowAlerts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlerts]);

  const handleVerifyCoupon = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("code", coupon);
    couponServices
      .verifyCoupon(bodyFormData)
      .then((response) => {
        applyCoupon(response.data.coupon, response.data.items);
      })
      .catch((err) => {})
      .finally(() => setIsOrderProcessing(false));
  };

  const handleInputFocus = (e) => {
    let flag = e.target.name === "securityCode" ? "cvc" : e.target.name;

    setCardState((state) => ({
      ...state,
      focus: flag,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardState((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    } else {
      fetchOrders();
    }
  }, [currentUser]);

  const removeCardListeners = () => {
    setShowPaymentCard(false);
  };

  const initialValues = {
    street_and_number: "",
    colony: "",
    city: "",
    state: mexicoStates["AGU"],
    country: "",
    country_trigger: "MX",
    postal_code: "",
    phone: "",
    receives: "",
    sender_phone: "",
    sender_name: "",
    reference: "",
  };

  const initalCardValues = {
    cardholderName: "",
    cardNumber: "",
    cvc: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    securityCode: "",
  };

  const formSchema = Yup.object().shape({
    street_and_number: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    colony: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    city: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    state: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    country_trigger: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    country: Yup.string().when("country_trigger", {
      is: (val) => val === "OTHER",
      then: Yup.string().required("Campo requerido"),
      postal_code: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
      phone: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
      receives: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
      sender_phone: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
      sender_name: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
      reference: Yup.string()
        .required("Campo requerido")
        .max(255, `Máximo 255 caracteres`),
    }),
  });

  const formSchemaServices = Yup.object().shape({
    cardholderName: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  useEffect(() => {
    setWindowHeight(window.screen.height);
  }, [window.screen.height]);

  const isAllValuesFilled = (values) => {
    for (let key in values) {
      if (values[key] === null || values[key] === "") {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <div className={`${windowHeight > 1279 && "min-h-screen"}`}>
        {loading ? (
          <div className="sweet-loading my-40">
            <div className="container mx-auto flex-1 px-4 md:px-0 mb-8 lg:mb-16 text-xl lg:text-3xl text-center font-bold text-primary-new">
              {" "}
              Procesando ordenes...
            </div>
            <BounceLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={100}
              aria-label="BeatLoader"
              data-testid="BeatLoader"
            />
          </div>
        ) : (
          <div>
            <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
              <div className="">
                <div className="h-20 flex items-center">
                  <img src={logo} alt="BeGlobalPro" className="h-9 mr-2" />
                  <p className=" text-gray-700">
                    <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
                    <span className="text-gray-400">Carrito de compras</span>
                  </p>
                </div>
              </div>
              <div className="lg:pt-8 text-gray-700 text-3xl">
                <div className="pt-8 text-gray-700 text-3xl">
                  <div className="flex flex-col md:flex-row w-full mb-10 md:gap-x-10 ">
                    {/* INFORMATION COL */}
                    <div className="w-full lg:w-1/2 space-y-4 ">
                      <p className="pb-4 lg:pb-10 text-2xl lg:text-4xl text-center lg:text-left font-bold">
                        Información de pago
                      </p>

                      {/* CARD FORM */}
                      {showPaymentCard && getMainOrderTotal() !== 0 && (
                        <div className="w-full text-gray-500 grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 col-span-2 ">
                          <div className="md:col-span-2 xl:col-span-1 my-auto">
                            <Cards
                              cvc={cardState.securityCode}
                              expiry={
                                cardState.cardExpirationMonth +
                                "" +
                                cardState.cardExpirationYear
                              }
                              focused={cardState.focus}
                              name={cardState.cardholderName}
                              number={cardState.cardNumber}
                              locale={{ valid: "FECHA EXP" }}
                              placeholders={{
                                name: "NOMBRE",
                              }}
                            />
                          </div>
                          <Formik
                            id="cardForm"
                            innerRef={cardRef}
                            enableReinitialize={true}
                            initialValues={initalCardValues}
                            validationSchema={formSchemaServices}
                          >
                            {({ setFieldValue }) => (
                              <Form className="md:col-span-2 xl:col-span-1">
                                <div className="md:col-span-2 xl:col-span-1">
                                  {/* CARD HOLDER */}
                                  <div className="text-xl lg:text-2xl">
                                    <p>Nombre completo</p>
                                    <input
                                      id="cardholderName"
                                      name="cardholderName"
                                      data-checkout="cardholderName"
                                      type="text"
                                      maxLength="25"
                                      onChange={(event) => {
                                        handleInputChange(event);
                                        setFieldValue(
                                          "cardholderName",
                                          event.target.value
                                        );
                                      }}
                                      onFocus={(event) => {
                                        handleInputFocus(event);
                                      }}
                                      className="border-2 border-gray-350 h-10 lg:h-14 w-full focus:outline-none p-3 text-xl lg:text-2xl"
                                    />
                                    <ErrorMessage
                                      name="cardholderName"
                                      component="div"
                                      className="text-red-400 font-bold text-xl"
                                    />
                                  </div>

                                  {/* CARD NUMBER */}
                                  <div className="text-xl lg:text-2xl">
                                    <label htmlFor="cardNumber">
                                      Número de la tarjeta
                                    </label>
                                    <input
                                      type="text"
                                      id="cardNumber"
                                      name="cardNumber"
                                      data-checkout="cardNumber"
                                      autoComplete="off"
                                      maxLength="16"
                                      className="border-2 border-gray-350 h-10 lg:h-14 w-full focus:outline-none p-3 text-xl lg:text-2xl"
                                      onChange={(event) => {
                                        handleInputChange(event);
                                        setFieldValue(
                                          "cardNumber",
                                          event.target.value
                                        );
                                      }}
                                      onFocus={(event) => {
                                        handleInputFocus(event);
                                      }}
                                    />
                                  </div>

                                  {/* DATE & CVV */}
                                  <div className="grid grid-cols-2 gap-5 text-xl lg:text-2xl">
                                    <div className="w-full">
                                      <p>Vencimiento</p>
                                      <div className="w-full  text-gray-500 grid grid-cols-2 space-x-4">
                                        <input
                                          type="text"
                                          placeholder="MM"
                                          id="cardExpirationMonth"
                                          name="cardExpirationMonth"
                                          data-checkout="cardExpirationMonth"
                                          autoComplete="off"
                                          maxLength="2"
                                          className="border-2 border-gray-350 h-10 lg:h-14   focus:outline-none p-3 text-xl lg:text-2xl"
                                          onChange={(event) => {
                                            handleInputChange(event);
                                            setFieldValue(
                                              "cardExpirationMonth",
                                              event.target.value
                                            );
                                          }}
                                          onFocus={(event) => {
                                            handleInputFocus(event);
                                          }}
                                        />
                                        <input
                                          type="text"
                                          placeholder="YY"
                                          id="cardExpirationYear"
                                          name="cardExpirationYear"
                                          data-checkout="cardExpirationYear"
                                          maxLength="2"
                                          autoComplete="off"
                                          className="border-2 border-gray-350 h-10 lg:h-14   focus:outline-none p-3 text-xl lg:text-2xl"
                                          onChange={(event) => {
                                            handleInputChange(event);
                                            setFieldValue(
                                              "cardExpirationYear",
                                              event.target.value
                                            );
                                          }}
                                          onFocus={(event) => {
                                            handleInputFocus(event);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="w-full text-gray-500 grid grid-cols-1 gap-5 md:grid-cols-1 col-span-1">
                                      <div className="w-full text-gray-500">
                                        <div className="text-xl lg:text-2xl">
                                          CVC
                                        </div>
                                        <input
                                          id="securityCode"
                                          name="securityCode"
                                          data-checkout="securityCode"
                                          type="text"
                                          maxLength="4"
                                          autoComplete="off"
                                          className="border-2 border-gray-350 h-10 lg:h-14  w-full focus:outline-none p-3 text-xl lg:text-2xl"
                                          onChange={(event) => {
                                            handleInputChange(event);
                                            setFieldValue(
                                              "securityCode",
                                              event.target.value
                                            );
                                          }}
                                          onFocus={(event) => {
                                            handleInputFocus(event);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* BANK */}

                                  {showCardMessage && (
                                    <div className="text-base text-red-50 text-center mt-2">
                                      Asegúrese de llenar correctamente todos
                                      los datos.
                                    </div>
                                  )}

                                  {/* HIDDEN INPUTS */}
                                  <div>
                                    <input
                                      type="hidden"
                                      name="transactionAmount"
                                      id="transactionAmount"
                                      value="100"
                                    />
                                    <input
                                      type="hidden"
                                      name="paymentMethodId"
                                      id="paymentMethodId"
                                    />
                                    <input
                                      type="hidden"
                                      name="description"
                                      id="description"
                                    />
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      )}

                      {/* ELECTRONIC TRANSFER */}
                      {!showPaymentCard && orderIdActive ? (
                        <div>
                          <div className="text-sm lg:text-3xl">
                            <p className="text-justify">
                              Las instrucciones para realizar el pago serán
                              enviadas a su correo una vez que confirme la
                              orden.
                            </p>
                            <br />
                            {shippingDaysActive && shippingDaysActive !== 0 && (
                              <p className={` text-justify text-green-50`}>
                                El tiempo estimado para recibir su orden es de{" "}
                                <b className="font-bold">
                                  {shippingDaysActive}{" "}
                                  {shippingDaysActive === 1 ? " día" : " días"}{" "}
                                </b>{" "}
                                una vez la orden haya sido enviada por el
                                vendedor.
                              </p>
                            )}

                            <br />
                            <p className="text-justify">
                              Estas instrucciones tambien podrán ser consultadas
                              en el detalle de la orden en la sección de
                              <b> Mi Escritorio &gt; Ordenes</b>
                            </p>
                          </div>

                          <div className="text-center mt-10">
                            <button
                              type="button"
                              className="w-full md:w-1/2 rounded-md bg-green-50 hover:bg-green-700 disabled:opacity-75 py-3 px-4 text-sm lg:text-base font-bold text-white transition-all duration-300"
                              disabled={isOrderProcessing}
                              onClick={() => handleOrderTransfer()}
                            >
                              {isOrderProcessing ? (
                                <FontAwesomeIcon icon="circle-notch" spin />
                              ) : (
                                <span>CONFIRMAR ORDEN</span>
                              )}
                            </button>
                          </div>
                        </div>
                      ) : (
                        !showPaymentCard && (
                          <div className="text-xl md:text-3xl font-bold text-center text-secondary pb-8 lg:pb-14">
                            SELECCIONE UNA ORDEN PARA PROCEDER CON EL PAGO
                          </div>
                        )
                      )}

                      {/* SHIPPING ADDRESS */}
                      {!showPaymentCard && !_.isEmpty(vendorOrder) && (
                        <Formik
                          id="nicknameForm"
                          innerRef={formRef}
                          enableReinitialize={true}
                          initialValues={initialValues}
                          validationSchema={formSchema}
                          onSubmit={(values) => {}}
                        >
                          {({ setFieldValue, values }) => (
                            <Form>
                              {!_.isEmpty(productsOrders) && (
                                <div className="w-full lg:mt-10">
                                  <br />
                                  {showShippingMessage && (
                                    <div className="text-xl lg:text-3xl text-red-50 mb-5 text-center lg:text-left">
                                      Asegúrese de llenar toda la información de
                                      envío.
                                    </div>
                                  )}

                                  <div>
                                    <div className="text-center lg:text-left font-bold text-xl lg:text-4xl my-4">
                                      Tipo de envío
                                    </div>
                                    <select
                                      value={currentShipping}
                                      onChange={(event) => {
                                        setCurrentShipping(event.target.value);
                                        selectShippingDays(event.target.value);
                                      }}
                                      className="text-center lg:text-left border border-gray-500 px-10 text-primary-300 mb-6 lg:mb-12 w-full text-xl lg:text-3xl"
                                    >
                                      <option value={0}>
                                        Seleccione un envío
                                      </option>
                                      {shippingsOptions &&
                                        Object.keys(shippingsOptions).map(
                                          (index, i) => (
                                            <option
                                              value={shippingsOptions[index].id}
                                            >
                                              {shippingsOptions[index].name +
                                                " $" +
                                                shippingsOptions[index].cost +
                                                "MXN - " +
                                                shippingsOptions[index].days +
                                                (shippingsOptions[index]
                                                  .days === 1
                                                  ? " Día "
                                                  : " Días ")}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>

                                  <div className="text-center lg:text-left font-bold text-xl lg:text-4xl my-4">
                                    Dirección de envío
                                  </div>

                                  <div className="w-full grid grid-cols-1 gap-5 md:grid-cols-1 col-span-1 '">
                                    <div className=" md:grid-cols-0 space-y-8 ">
                                      {addresses &&
                                        Object.keys(addresses).map(
                                          (index, i) => (
                                            <div
                                              key={addresses[index].id}
                                              onClick={() => {
                                                setRadio(addresses[index].id);
                                                setIsNewShippingAddress(false);
                                                setFieldValue(
                                                  "street_and_number",
                                                  addresses[index]
                                                    .street_and_number
                                                );
                                                setFieldValue(
                                                  "colony",
                                                  addresses[index].colony
                                                );
                                                setFieldValue(
                                                  "city",
                                                  addresses[index].city
                                                );
                                                setFieldValue(
                                                  "state",
                                                  addresses[index].state
                                                );
                                                setFieldValue(
                                                  "country",
                                                  addresses[index].country
                                                );
                                                setFieldValue(
                                                  "postal_code",
                                                  addresses[index].postal_code
                                                );
                                                setFieldValue(
                                                  "phone",
                                                  addresses[index].phone
                                                );
                                                setFieldValue(
                                                  "receives",
                                                  addresses[index].receives
                                                );
                                                setFieldValue(
                                                  "sender_phone",
                                                  addresses[index].sender_phone
                                                );
                                                setFieldValue(
                                                  "sender_name",
                                                  addresses[index].sender_name
                                                );
                                                setFieldValue(
                                                  "reference",
                                                  addresses[index].reference
                                                );
                                                setCode(
                                                  addresses[index].postal_code
                                                );
                                              }}
                                              className={`space-x-2 flex items-center w-full mb-2 hover:cursor-pointer hover:text-primary-300`}
                                            >
                                              <input
                                                name="addres"
                                                type="radio"
                                                checked={
                                                  radio === addresses[index].id
                                                }
                                                onClick={() => {
                                                  setRadio(addresses[index].id);
                                                  setIsNewShippingAddress(
                                                    false
                                                  );
                                                  setFieldValue(
                                                    "street_and_number",
                                                    addresses[index]
                                                      .street_and_number
                                                  );
                                                  setFieldValue(
                                                    "colony",
                                                    addresses[index].colony
                                                  );
                                                  setFieldValue(
                                                    "city",
                                                    addresses[index].city
                                                  );
                                                  setFieldValue(
                                                    "state",
                                                    addresses[index].state
                                                  );
                                                  setFieldValue(
                                                    "country",
                                                    addresses[index].country
                                                  );
                                                  setFieldValue(
                                                    "postal_code",
                                                    addresses[index].postal_code
                                                  );
                                                  setFieldValue(
                                                    "phone",
                                                    addresses[index].phone
                                                  );
                                                  setFieldValue(
                                                    "receives",
                                                    addresses[index].receives
                                                  );
                                                  setFieldValue(
                                                    "sender_phone",
                                                    addresses[index]
                                                      .sender_phone
                                                  );
                                                  setFieldValue(
                                                    "sender_name",
                                                    addresses[index].sender_name
                                                  );
                                                  setFieldValue(
                                                    "reference",
                                                    addresses[index].reference
                                                  );
                                                  setCode(
                                                    addresses[index].postal_code
                                                  );
                                                }}
                                                className="lg:t-20 lg:w-5 lg:h-5 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                                              />
                                              <span className="text-xl  lg:text-3xl lg:text-left">
                                                {
                                                  addresses[index]
                                                    .street_and_number
                                                }
                                                , {addresses[index].colony},{" "}
                                                {addresses[index].postal_code},{" "}
                                                {addresses[index].city},{" "}
                                                {addresses[index].state}{" "}
                                                {addresses[index].receives && (
                                                  <small>
                                                    - Recibe{" "}
                                                    {addresses[index].receives}
                                                  </small>
                                                )}
                                              </span>
                                            </div>
                                          )
                                        )}

                                      <div
                                        className={`space-x-2 flex items-center w-full mb-2 col-span-1 hover:cursor-pointer hover:text-primary-300`}
                                        onClick={() => {
                                          setRadio("new");
                                          setIsNewShippingAddress(true);

                                          setFieldValue(
                                            "street_and_number",
                                            ""
                                          );
                                          setFieldValue("colony", "");
                                          setFieldValue("city", "");
                                          setFieldValue(
                                            "state",
                                            mexicoStates["AGU"]
                                          );
                                          setFieldValue("country", "MX");
                                          setFieldValue("postal_code", "");
                                          setFieldValue("phone", "");
                                          setFieldValue("receives", "");
                                          setFieldValue("sender_phone", "");
                                          setFieldValue("sender_name", "");
                                          setFieldValue("reference", "");
                                        }}
                                      >
                                        <input
                                          name="addres"
                                          type="radio"
                                          checked={radio === "new"}
                                          onChange={(value) => {
                                            setRadio("new");
                                            setIsNewShippingAddress(true);

                                            setFieldValue(
                                              "street_and_number",
                                              ""
                                            );
                                            setFieldValue("colony", "");
                                            setFieldValue("city", "");
                                            setFieldValue(
                                              "state",
                                              mexicoStates["AGU"]
                                            );
                                            setFieldValue("country", "MX");
                                            setFieldValue("postal_code", "");
                                            setFieldValue("phone", "");
                                            setFieldValue("receives", "");
                                            setFieldValue("sender_phone", "");
                                            setFieldValue("sender_name", "");
                                            setFieldValue("reference", "");
                                          }}
                                          className="lg:t-20 lg:w-5 lg:h-5 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none resize-none"
                                        />
                                        <span className="text-xl lg:text-3xl lg:text-left">
                                          {" "}
                                          Agregar nueva dirección de envío
                                        </span>
                                      </div>
                                      <div className="col-span-2">
                                        {isNewShippingAddress ? (
                                          <div className="mb-5 lg:mb-2 space-y-2 lg:space-y-4">
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Persona quien recibe
                                              </p>
                                              <Field
                                                name="receives"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "receives",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                placeholder="Por defecto, el nombre de la persona de esta cuenta."
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="receives"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Teléfono de quien recibe
                                              </p>
                                              <Field
                                                name="phone"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "phone",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="phone"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="flex flex-row space-x-8">
                                              <div className="w-full lg:w-1/2 text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Pais o Region{" "}
                                                </p>
                                                <Field
                                                  as="select"
                                                  name="country"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "country",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                >
                                                  <option
                                                    key="México"
                                                    value="México"
                                                  >
                                                    México
                                                  </option>
                                                </Field>
                                              </div>
                                              <div className="w-full lg:w-1/2 text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Estado
                                                </p>
                                                <Field
                                                  as="select"
                                                  name="state"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "state",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                >
                                                  {Object.keys(
                                                    mexicoStates
                                                  ).map((key) => (
                                                    <option
                                                      key={key}
                                                      value={mexicoStates[key]}
                                                    >
                                                      {mexicoStates[key]}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                            </div>

                                            <div className="flex flex-row space-x-8">
                                              <div className="w-full lg:w-1/2 text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Ciudad{" "}
                                                </p>
                                                <Field
                                                  name="city"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "city",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="city"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>
                                              <div className="w-full lg:w-1/2 text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Codigo postal
                                                </p>
                                                <Field
                                                  name="postal_code"
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[0-9]/.test(event.key)
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  onChange={(event) => {
                                                    if (
                                                      event.target.value
                                                        .length > 5
                                                    ) {
                                                      return false;
                                                    } else {
                                                      setCode(
                                                        event.target.value
                                                      );
                                                      setFieldValue(
                                                        "postal_code",
                                                        event.target.value
                                                      );
                                                    }
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="postal_code"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>
                                            </div>
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Calle y número
                                              </p>
                                              <Field
                                                name="street_and_number"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "street_and_number",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="street_and_number"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Referencia
                                              </p>
                                              <Field
                                                name="reference"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "reference",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="reference"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Colonia
                                              </p>
                                              <Field
                                                name="colony"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "colony",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="colony"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Remitente
                                              </p>
                                              <Field
                                                name="sender_name"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "sender_name",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="sender_name"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="w-full text-gray-500">
                                              <p className="text-xl lg:text-2xl">
                                                Teléfono del remitente
                                              </p>
                                              <Field
                                                name="sender_phone"
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "sender_phone",
                                                    event.target.value
                                                  );
                                                }}
                                                className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name="sender_phone"
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                            <div className="w-full text-gray-500 flex flex-wrap lg:flex-row lg:space-x-8">
                                              <div className="w-full flex justify-center  lg:text-left">
                                                <label className="space-x-4 flex flex-row mt-6">
                                                  <Field
                                                    type="checkbox"
                                                    name="save"
                                                    className="h-7 w-8 my-auto"
                                                    checked={saveAddress}
                                                    onChange={() => {
                                                      setFieldValue(
                                                        "save",
                                                        !saveAddress
                                                      );
                                                      setSaveAddress(
                                                        !saveAddress
                                                      );
                                                    }}
                                                  />
                                                  <div className="text-xl lg:text-2xl">
                                                    {" "}
                                                    Guardar dirección para
                                                    futuros pedidos
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          radio !== 0 &&
                                          !isAllValuesFilled(
                                            addresses.find(
                                              (a) => a.id === radio
                                            )
                                          ) && (
                                            <div className="mb-5 lg:mb-2 space-y-2 lg:space-y-4">
                                              <div className="w-full text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Persona quien recibe
                                                </p>
                                                <Field
                                                  name="receives"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "receives",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  placeholder="Por defecto, el nombre de la persona de esta cuenta."
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="receives"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>

                                              <div className="w-full text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Teléfono de quien recibe
                                                </p>
                                                <Field
                                                  name="phone"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "phone",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="phone"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>

                                              <div className="w-full text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Referencia
                                                </p>
                                                <Field
                                                  name="reference"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "reference",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="reference"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>

                                              <div className="w-full text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Remitente
                                                </p>
                                                <Field
                                                  name="sender_name"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "sender_name",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="sender_name"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>

                                              <div className="w-full text-gray-500">
                                                <p className="text-xl lg:text-2xl">
                                                  Teléfono del remitente
                                                </p>
                                                <Field
                                                  name="sender_phone"
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      "sender_phone",
                                                      event.target.value
                                                    );
                                                  }}
                                                  className="text-sm lg:text-2xl w-full h-10 lg:h-14 bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                  type="text"
                                                />
                                                <ErrorMessage
                                                  name="sender_phone"
                                                  component="div"
                                                  className="text-red-400 font-bold text-xl"
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form>
                          )}
                        </Formik>
                      )}

                      {/* SHIPPING METHOD */}

                      {showPaymentCard && (
                        <div className="w-full text-center">
                          <button
                            type="submit"
                            form="paymentForm"
                            className="w-full lg:w-1/2 rounded-md mt-5 mb-10 bg-primary-new-50 hover:bg-primary-new disabled:opacity-75 py-3 px-4 text-base font-bold text-white transition-all duration-300"
                            disabled={isOrderProcessing}
                            onClick={() => handleOrder()}
                          >
                            {isOrderProcessing ? (
                              <FontAwesomeIcon icon="circle-notch" spin />
                            ) : (
                              <span>PAGAR</span>
                            )}
                          </button>
                        </div>
                      )}
                    </div>

                    {/* ORDERS COL */}
                    <div className="w-full lg:w-1/2 max-h-screen lg:mb-20">
                      <p className="pb-1 lg:pb-5 lg:pb-10 text-2xl lg:text-4xl text-center lg:text-left font-bold">
                        Ordenes
                      </p>

                      <div className="text-2xl mb-10 mx-auto text-center">
                        <div className=" bg-white overflow-y-auto">
                          <div className="max-h-screen overflow-y-auto ">
                            {productsOrders.map((order) => (
                              <div key={order.id} className="mt-3">
                                <div className="flex flex-col md:flex-row justify-between">
                                  <p
                                    className={`text-xl lg:text-2xl text-center lg:text-left font-bold ${
                                      order.id === orderIdActive &&
                                      "text-green-50"
                                    }`}
                                  >
                                    Orden #{order.id}
                                  </p>
                                  <p
                                    className={`text-xl lg:text-2xl text-center lg:text-left font-bold`}
                                  >
                                    Tienda: {order.vendor}
                                  </p>
                                </div>
                                <div
                                  className={`${
                                    order.id === orderIdActive
                                      ? "border-2 border-green-50"
                                      : "border border-zinc-200"
                                  }`}
                                >
                                  {order.products.map((product) => (
                                    <ProductCheckout
                                      key={product.id}
                                      product={product}
                                    />
                                  ))}
                                  <div className="text-gray-900  px-3 grid grid-cols-2 md:grid-cols-2">
                                    <div className="font-bold text-base lg:text-xl ">
                                      Subtotal
                                    </div>
                                    <div className="flex flex-col justify-center order-1 md:order-2 md:mb-0 ">
                                      <p className="ml-8 font-bold text-base lg:text-xl text-gray-900 leading-none text-right">
                                        $
                                        {getProductsOrderTotal(
                                          order
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })}
                                        <small> MXN</small>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className={` ${
                                      order.id === orderIdActive
                                        ? "block"
                                        : "hidden"
                                    } font-bold text-base lg:text-xl text-gray-900 px-3 grid grid-cols-2 md:grid-cols-2`}
                                  >
                                    <div>Costo de envio</div>
                                    <div className="flex flex-col justify-center order-1 md:order-2  md:mb-0">
                                      <p className="ml-8 font-bold text-base lg:text-xl text-gray-900 leading-none text-right">
                                        $
                                        {shippingCost
                                          //order.shipping_cost
                                          .toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                          })}
                                        <small> MXN</small>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      order.id === orderIdActive
                                        ? "block"
                                        : "hidden"
                                    } font-bold text-base lg:text-xl text-gray-900 p-3 grid grid-cols-2 md:grid-cols-2`}
                                  >
                                    <div>Total</div>
                                    <div className="flex flex-col justify-center order-1 md:order-2  md:mb-0">
                                      <p className="ml-8 font-bold text-base lg:text-xl text-gray-900 leading-none text-right">
                                        $
                                        {(
                                          getProductsOrderTotal(order) +
                                          //order.shipping_cost
                                          shippingCost
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })}
                                        <small> MXN</small>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="font-bold text-xl lg:text-2xl text-gray-900 px-3 grid col-span-1 md:grid-cols-1 my-2">
                                    <button
                                      type="button"
                                      className="order-2 md:order-1 w-full rounded-md bg-primary-new-50 hover:bg-primary-new disabled:opacity-75 py-3 px-4 text-sm lg:text-base font-bold text-white transition-all duration-300"
                                      disabled={isOrderProcessing}
                                      onClick={() => {
                                        setOrderIdActive(order.id);
                                        //setShippingDaysActive(order.days)
                                        assignShippingsOrder(order.id);
                                        setCurrentShipping(0);
                                        setShippingDaysActive(null);
                                        setShippingCost(0);

                                        setVendorOrder(order);
                                        removeCardListeners();
                                      }}
                                    >
                                      SELECCIONAR ORDEN
                                    </button>
                                  </div>
                                  <div className="grid grid-cols-2 text-gray-900">
                                    <div className="flex justify-between"></div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {!_.isEmpty(mainOrder) && showMainOrder && (
                              <div className="mt-3">
                                <div className="flex flex-col md:flex-row justify-between">
                                  <p
                                    className={`text-xl lg:text-2xltext-2xl text-center lg:text-left font-bold ${
                                      mainOrder.id === orderIdActive &&
                                      "text-green-50"
                                    }`}
                                  >
                                    Orden #{mainOrder.id}
                                  </p>
                                  <p
                                    className={`text-xl lg:text-2xl text-center lg:text-left font-bold`}
                                  >
                                    Tienda: BeGlobalPro
                                  </p>
                                </div>
                                <div
                                  className={`${
                                    mainOrder.id === orderIdActive
                                      ? "border-2 border-green-50"
                                      : "border border-zinc-200"
                                  }`}
                                >
                                  {mainOrder.courses.map((course) => (
                                    <MainItemCheckout
                                      key={course.uuid}
                                      item={course}
                                      type="Curso"
                                    />
                                  ))}
                                  {mainOrder.services.map((service) => (
                                    <MainItemCheckout
                                      key={service.uuid}
                                      item={service}
                                      type="Servicio"
                                    />
                                  ))}
                                  {mainOrder.memberships.map((membership) => (
                                    <MainItemCheckout
                                      key={membership.uuid}
                                      item={membership}
                                      type="Membresía"
                                    />
                                  ))}
                                  {(mainOrder.courses.length > 0 ||
                                    mainOrder.memberships.length > 0) && (
                                    <div className="grid grid-cols-2 my-auto text-primary-new font-bold">
                                      <div className="my-auto">
                                        Correo de referencia
                                      </div>
                                      <div className="flex space-x-4 my-auto px-4">
                                        <FontAwesomeIcon
                                          title="Correo de la persona que te recomendó Be Global Pro"
                                          icon={["far", "circle-question"]}
                                          size="lg"
                                          className="my-auto"
                                        />
                                        <input
                                          value={referralEmail}
                                          onChange={(e) =>
                                            setReferralEmail(e.target.value)
                                          }
                                          className="border-2 border-primary-new  h-10 w-full focus:outline-none p-3 text-2xl rounded-lg"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div className="p-3 grid col-span-1 md:grid-cols-2">
                                    <button
                                      type="button"
                                      className="order-2 md:order-1 w-full rounded-md bg-primary-new-50 hover:bg-primary-new disabled:opacity-75 py-3 px-4 text-sm lg:text-base font-bold text-white transition-all duration-300"
                                      disabled={isOrderProcessing}
                                      onClick={() => {
                                        setOrderIdActive(mainOrder.id);
                                        setShowPaymentCard(true);
                                      }}
                                    >
                                      SELECCIONAR ORDEN
                                    </button>
                                    <div className="flex flex-col justify-center order-1 md:order-2 mb-3 md:mb-0">
                                      <p className="ml-8 font-bold text-2xl text-gray-900 leading-none text-right">
                                        $
                                        {getMainOrderTotal().toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )}
                                        <br />
                                        <small>MXN</small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {hasCupon ? (
                              <div className="py-5 grid grid-cols-2 my-auto text-primary-new font-bold px-10">
                                <div className="flex my-auto px-4">
                                  <input
                                    id="coupon"
                                    value={coupon}
                                    onChange={(event) => {
                                      setCoupon(event.target.value);
                                    }}
                                    placeholder="Introduce el codigo"
                                    className="border-2 border-primary-new  h-10 w-full focus:outline-none p-3 text-2xl rounded-lg"
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => handleVerifyCoupon()}
                                  className="order-2 md:order-1 w-full rounded-md bg-primary-new hover:bg-primary-new-50 disabled:opacity-75 py-3 px-4 text-sm lg:text-base font-bold text-white transition-all duration-300"
                                >
                                  Aplicar
                                </button>
                              </div>
                            ) : (
                              <button
                                className="text-primary-new"
                                onClick={() => setHasCupon(!hasCupon)}
                              >
                                Tengo un cupón{" "}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCart;
