import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import Address from "../../../components/Address";

import { profileService } from "../../../services/api/profile";

import Context from "../../../context/UserContext";

const Index = () => {
  const { currentUser } = useContext(Context);

  const [addresses, setAddresses] = useState([]);

  const fetchAddresses = () => {
    profileService
      .getAddresses()
      .then((response) => {
        setAddresses(response.data.addresses);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      {!_.isEmpty(addresses) ? (
        <>
          <p className="text-gray-700 font-bold text-2xl">
            Direcciones de envío
          </p>
          {addresses.map((address, index) => (
            <Address
              key={`Address-${address.id}`}
              address={address}
              last={index === addresses.length - 1}
            />
          ))}
        </>
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-new-50 p-4">
          <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold leading-none">
            Aún no tienes direcciones registradas
          </p>
        </div>
      )}
      <div className="w-full flex justify-end mt-5">
        <Link
          to="/mi-perfil/direcciones/nueva"
          className="w-full md:w-auto bg-primary-new text-white text-center font-bold text-xl py-2 px-10 rounded-full"
        >
          Agregar dirección
        </Link>
      </div>
    </>
  );
};

export default Index;
