import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import useUser from "../../hooks/useUser";

const VerifyForm = ({ setActive, email }) => {
  const formSchema = Yup.object().shape({
    code: Yup.string()
      .required("Campo requerido")
      .test("len", "El código debe ser de 6 carácteres", (val) =>
        val ? val.length === 6 : false
      ),
  });

  const [internalMode, setInternalMode] = useState(null);
  const [codeSended, setCodeSended] = useState(false);
  const {
    isLoading,
    hasError,
    errors,
    onSucess,
    verifyAccount,
    resendVerificationCode,
  } = useUser();

  const formattedEmail = () => {
    let emailSplit = email.split("@");
    return `${email.charAt(0)}....@${emailSplit[1]}`;
  };

  useEffect(() => {
    if (onSucess)
      internalMode === "verify" ? setActive("login") : setCodeSended(true);
  }, [onSucess, setActive, internalMode]);

  const handleSubmit = async ({ code }) => {
    setCodeSended(false);
    setInternalMode("verify");
    await verifyAccount({ email, code });
  };

  const resendCode = async () => {
    setCodeSended(false);
    setInternalMode("resendCode");
    await resendVerificationCode({ email });
  };

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={formSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="flex flex-col justify-center h-96">
        <p className="text-gray-700 font-bold text-xl pt-5">
          Ingresa el código de verificación
        </p>
        <p className="text-gray-550 font-normal text-sm ">{formattedEmail()}</p>
        <Field
          name="code"
          className="w-full bg-white text-gray-550 border-b-2 border-b-slate-300 py-1 pl-1 pr-4 mt-10 focus:outline-none"
          placeholder="Ingresa el código de 6 caracteres"
          type="text"
        />
        <ErrorMessage
          name="code"
          component="div"
          className="text-red-400 font-bold font-xs"
        />

        {hasError && errors.length > 0 && (
          <div className="bg-gray-300 border-l-4 border-l-tertiary p-2 mt-2">
            <ul className="text-gray-700 font-bold text-xs">
              {errors.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        )}

        <button
          type="submit"
          className="w-3/4 bg-primary-new text-white font-bold text-lg py-2 mt-8 mb-5 mx-auto disabled:opacity-75 rounded-full"
          disabled={isLoading}
        >
          {isLoading ? (
            <FontAwesomeIcon icon="circle-notch" spin />
          ) : (
            <span>Verificar</span>
          )}
        </button>

        <button
          type="button"
          className="text-secondary-new text-sm disabled:opacity-75"
          onClick={() => resendCode()}
          disabled={isLoading}
        >
          Reenviar código
        </button>

        {codeSended && (
          <div className="bg-gray-300 border-l-4 border-l-green-400 p-2 mt-2">
            <ul className="text-gray-700 font-bold text-xs">
              <li>Se a enviado un nuevo código a tu correo.</li>
            </ul>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default VerifyForm;
