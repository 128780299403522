import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemCart = ({
  type,
  item,
  handleRemoveItemFromCart,
  handleBuyLaterFromCart,
}) => {
  return (
    <>
      <div className="flex relative pt-8 pb-6 border-t border-gray-300">
        <div className="absolute right-0 top-1">
          <button
            className="text-gray-500 hover:text-gray-600"
            onClick={() => handleRemoveItemFromCart(item.uuid, type)}
          >
            <FontAwesomeIcon icon="fa-xmark" />
          </button>
        </div>
        <div className="flex-shrink-0">
          <img
            src={item.image}
            alt=""
            className="h-20 w-20 rounded-md object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col sm:ml-6">
          <div>
            <div className="flex justify-between">
              <p className="text-gray-700 font-bold text-sm md:text-2xl leading-none">
                {item.name}
              </p>
              <p className="ml-8 font-bold text-sm lg:text-xl text-gray-900 leading-none text-right">
                $
                {item.cost.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}{" "}
                <small> MXN</small>
              </p>
            </div>
          </div>

          <div className="mt-4 flex flex-1 items-end justify-end">
            <div className="ml-4">
              <button
                className="font-medium text-primary-new hover:text-primary-new-50"
                onClick={() =>
                  handleBuyLaterFromCart(
                    item.uuid,
                    type,
                    item.save_for_later ? 0 : 1
                  )
                }
              >
                <div className=" flex items-center justify-center">
                  <p>
                    {item.save_for_later
                      ? "Agregar al carrito"
                      : "Guardar para después"}
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemCart;
