import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavBarItem = ({ item, idx }) => {
  const UrlPath = window.location.pathname;
  const validateUrl = (isActive, label) => {
    if (label !== "Ventas" && label !== "Ordenes") return isActive;

    if (label === "Ventas") {
      return (
        (isActive ||
          UrlPath.match(/productos/) ||
          UrlPath.match(/membresias/)) &&
        label === "Ventas"
      );
    } else if (label === "Ordenes") {
      return (
        (isActive || UrlPath.match(/ordenes/) || UrlPath.match(/orden/)) &&
        label === "Ordenes"
      );
    }
  };

  return (
    <NavLink className="" end to={item.link}>
      {({ isActive }) => (
        <div className="flex flex-row md:flex-col xl:flex-row items-center">
          <div className="w-[18px] md:w-auto mr-2 md:mr-0 lg:mr-2">
            <FontAwesomeIcon
              icon={item.icon}
              className={`text-white ${idx === 6 ? "pl-1" : ""} ${
                validateUrl(isActive, item.label)
                  ? "text-secondary-new"
                  : "text-white"
              }`}
            />
          </div>
          <p
            className={`leading-none text-white text-center text-sm lg:text-[24px] xl:text-2xl mt-0 md:mt-2 lg:mt-0 ${
              validateUrl(isActive, item.label)
                ? "text-secondary-new"
                : "text-white"
            }`}
          >
            {item.label}
          </p>
        </div>
      )}
    </NavLink>
  );
};
export default NavBarItem;
