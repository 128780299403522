import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import useUser from "../../hooks/useUser";

import { ReactComponent as LockIcon } from "../../assets/icons/lock-icon.svg";

const ResetPasswordForm = ({ setActive, email }) => {
  const formSchema = Yup.object().shape({
    code: Yup.string()
      .required("Campo requerido")
      .test("len", "El código debe ser de 6 carácteres", (val) =>
        val ? val.length === 6 : false
      ),
    password: Yup.string()
      .required("Campo requerido")
      .min(8, `Mínimo 8 caracteres`),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Las contraseñas deben coincidir"
    ),
  });

  const { isLoading, hasError, errors, onSucess, passwordReset } = useUser();

  useEffect(() => {
    if (onSucess) {
      setActive("login");
    }
  }, [onSucess, setActive]);

  const handleSubmit = async (values) => {
    await passwordReset({ email, ...values });
  };

  return (
    <div className="flex flex-col justify-center h-96">
      <Formik
        initialValues={{
          code: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={formSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <p className="text-gray-700 font-bold text-xl mt-5">
            Código de verificación
          </p>
          <Field
            name="code"
            className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 px-4 focus:outline-none"
            type="text"
          />
          <ErrorMessage
            name="code"
            component="div"
            className="text-red-400 font-bold font-xs"
          />

          <p className="text-gray-700 font-bold text-xl mt-5">
            Nueva contraseña
          </p>
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-red-200">
              <LockIcon />
            </span>
            <Field
              name="password"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 pl-10 pr-4 focus:outline-none"
              type="password"
            />
          </label>
          <ErrorMessage
            name="password"
            component="div"
            className="text-red-400 font-bold font-xs"
          />

          <p className="text-gray-700 font-bold text-xl mt-5">
            Confirmación contraseña
          </p>
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-red-200">
              <LockIcon />
            </span>
            <Field
              name="password_confirmation"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 pl-10 pr-4 focus:outline-none"
              type="password"
            />
          </label>
          <ErrorMessage
            name="password_confirmation"
            component="div"
            className="text-red-400 font-bold font-xs"
          />

          {hasError && errors.length > 0 && (
            <div className="bg-gray-300 border-l-4 border-l-tertiary p-2 mt-2">
              <ul className="text-gray-700 font-bold text-xs">
                {errors.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-primary-new text-white font-bold text-lg py-2 mt-10 disabled:opacity-75 rounded-full"
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              <span>Reestablecer Contraseña</span>
            )}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
