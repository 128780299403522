import BasicModal from "../../components/Modals/BasicModalEvent";
import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import * as _ from "lodash";
import defaultImage from "../../assets/images/logo-bgp.svg";
import { coursesServices } from "../../services/api/courses";
import { newsService } from "../../services/api/news";
import Context from "../../context/UserContext";
import { notifications } from "../../utils/notifications";
import { StatisticsService } from "../../services/api/statistics";
import { isEmpty } from "lodash";
import { toHHMMSS } from "../../utils";
import LearningCard from "../../components/statics/LearningCard";
import CourseButton from "../../components/courses/CourseButton";

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  const [news, setNews] = useState([]);
  const [rebates, setRebates] = useState([]);
  const [newReleases, setNewReleases] = useState([]);
  const { currentUser } = useContext(Context);
  const navigate = useNavigate();

  const fetchNews = () => {
    newsService
      .getNews()
      .then((response) => {
        setNews(response.data.news);
        setRebates(response.data.rebates);
        setNewReleases(response.data.newReleases);
      })
      .catch((err) => {});
  };

  const openNewModal = (item) => {
    setShowModal(true);
    setModalContent(
      <div
        className="px-6 lg:px-12 text-2xl 2xl:text-3xl text-center"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="container mx-auto">
            <div className="modal-header my-5 lg:text-left flex flex-row text-2xl 2xl:text-left text-center 2xl:text-3xl">
              <FontAwesomeIcon
                icon={["far", "calendar"]}
                size="sm"
                className="mr-3 lg:mt-1"
              />
              <h5 className="modal-title font-bold text-center text-sm lg:text-2xl">
                {item.title}
              </h5>
              <hr className=""></hr>
            </div>
            <div className=" modal-body text-2xl text-gray-new text-center 2xl:text-3xl">
              <div className="flex-wrap 2xl:flex-row text-2xl 2xl:text-3xl ">
                <div className="w-full whitespace-pre-line">
                  <div className=" 2xl:mb-5 capitalize">
                    <p className="text-primary-new text-center font-bold">
                      {item.type}
                    </p>
                  </div>
                </div>
                {item.image ? (
                  <div className="w-full">
                    {item.link ? (
                      /* eslint-disable jsx-a11y/anchor-is-valid */
                      <a
                        href={() => item.link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={item.image}
                          alt={"imagen"}
                          className="w-full md:w-1/2 object-contain mx-auto my-10"
                        />
                      </a>
                    ) : (
                      <img
                        src={item.image}
                        alt={"imagen"}
                        className="w-full md:w-1/2 object-contain mx-auto my-10"
                      />
                    )}
                  </div>
                ) : (
                  <img
                    src={defaultImage}
                    alt={"imagen"}
                    className="w-full md:w-1/2 object-contain mx-auto my-10"
                  />
                )}

                <p className=" xl:mt-5 text-sm lg:text-xl">
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [modalContent, setModalContent] = useState();

  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [statistics, setStatistics] = useState({});

  const fetchUserCourses = () => {
    coursesServices
      .getUserCourses()
      .then((response) => {
        setCoursesInProgress(response.data.coursesInProgress.slice(0, 2));
        // Agregar solo tomar los dos primeros
      })
      .catch((err) => {});
  };

  const fetchWishList = () => {
    coursesServices
      .getUserWishList()
      .then((response) => {
        setWishList(response.data.courses.slice(0, 1));
      })
      .catch((err) => {});
  };

  const fetchFavorites = () => {
    coursesServices
      .getUserFavorites()
      .then((response) => {
        setFavorites(response.data.favorites.slice(0, 1));
      })
      .catch((err) => {});
  };

  const fetchStatistics = () => {
    StatisticsService.getStatistics()
      .then((response) => {
        setStatistics(response.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchUserCourses();
    fetchWishList();
    fetchFavorites();
    fetchStatistics();
    fetchNews();
  }, [currentUser]);

  const updateWishList = async (uuid) => {
    await coursesServices
      .updateCourseWishList({ uuid })
      .then((response) => {
        notifications.success(response.data.message);
        setWishList(wishList.filter((course) => course.uuid !== uuid));
      })
      .catch((err) => {});
  };

  const handleWishList = (uuid) => {
    updateWishList(uuid);
  };

  const removeFromFavorites = async (id) => {
    await coursesServices
      .updateClassFavorites({ id })
      .then((response) => {
        notifications.success(response.data.message);
        setFavorites(favorites.filter((favorite) => favorite.classe.id !== id));
      })
      .catch((err) => {});
  };

  const getStudyTime = () => {
    if (isEmpty(statistics)) return 0;

    let time = 0;

    statistics.timePerDay.forEach((day) => {
      time += parseInt(day.seconds);
    });

    return (time / 3600).toFixed(2);
  };

  const learningArray = [
    {
      key: "medal-1",
      icon: "medal",
      title: "Cursos completados",
      count: statistics.coursesFinished,
    },
    {
      key: "loader-1",
      icon: ["far", "loader"],
      title: "Cursos en progreso",
      count: statistics.coursesInProgress,
    },
    {
      key: "clock-1",
      icon: ["far", "clock"],
      title: "Horas de estudio",
      count: getStudyTime(),
    },
  ];

  return (
    <>
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
      />
      <div className="flex flex-col md:flex-row gap-10 ">
        <div className="w-full lg:w-8/12">
          {/* TOP INFO */}
          <div className="w-full border border-gray-350 mb-10 flex flex-col lg:flex-row md:justify-around p-10 md:p-5 lg:p-10 md:py-10">
            {learningArray.map((item) => (
              <LearningCard key={item.key} item={item} desktop />
            ))}
          </div>

          {/* COURSE IN PROGRESS */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            {coursesInProgress.map((course) => (
              <div className="shadow-md flex flex-col h-auto lg:h-[550px]">
                <div className="relative ">
                  <img
                    src={course.image}
                    alt={course.name}
                    className="w-full h-40 object-cover"
                  />
                </div>

                <div className="p-4 flex flex-col justify-between lg:h-full">
                  <div className="">
                    <h3 className="font-bold text-gray-new text-2xl lg:text-3xl mb-2 text-center lg:text-left">
                      {course.name}
                    </h3>
                    <p className="block lg:hidden text-xl text-gray-650 mb-2 leading-none">
                      {course.description.length >= 100
                        ? course.description.substring(0, 100) + "..."
                        : course.description}
                    </p>
                    <p className="hidden lg:block text-xl text-gray-650 mb-4 leading-none">
                      {course.description}
                    </p>
                  </div>

                  <div className="text-xl mb-5 lg:mb-1">
                    <div className="pt-2 lg:pt-0 text-center lg:text-left text-xl">
                      <p className="text-gray-400">Impartido por</p>
                      <p className="text-gray-new">{course.teacher.name}</p>
                    </div>
                    <div className="my-4 lg:my-8">
                      <p className="text-primary-new my-2 text-sm text-center lg:text-left">
                        {parseInt(course.progress)}% de progreso
                      </p>
                      <ProgressBar
                        bgColor="#394875"
                        baseBgColor="#dadada"
                        isLabelVisible={false}
                        height="10px"
                        borderRadius="12px"
                        animateOnRender
                        completed={course.progress}
                        maxCompleted={100}
                      />
                    </div>

                    <div className="flex justify-center">
                      <CourseButton course={course} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* WISH LIST & FAVORITES */}
          {!isEmpty(wishList.length) ||
            (!isEmpty(favorites) && (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10">
                <div>
                  {wishList.map((course) => (
                    <div
                      key={course.uuid}
                      className="shadow-md flex flex-col h-[600px] lg:h-full"
                    >
                      <div className="relative">
                        <img
                          src={course.image}
                          alt={course.name}
                          className="w-full h-60 object-cover"
                        />
                        <div className="flex items-center justify-center bg-white h-10 w-10 rounded-full absolute top-2 right-2">
                          <button onClick={() => handleWishList(course.uuid)}>
                            <FontAwesomeIcon
                              icon={["fas", "heart"]}
                              className="text-tertiary"
                              size="lg"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="p-4 flex flex-col flex-grow justify-between">
                        <div className="">
                          <h3 className="font-bold text-gray-700 text-3xl mb-2">
                            {course.name}
                          </h3>
                          <p className="text-xl text-gray-500 mb-4">
                            {course.description}
                          </p>
                        </div>

                        <div className="flex justify-center py-3">
                          <CourseButton course={course} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-5 md:mt-0">
                  {!_.isEmpty(favorites) && (
                    <div className="grid grid-cols-1 gap-9 h-full">
                      {favorites.map(
                        (favorite) =>
                          favorite.classe &&
                          favorite.classe.id && (
                            <div
                              key={favorite.classe.id}
                              className="shadow-md flex flex-col h-[600px] lg:h-full"
                            >
                              <div className="relative">
                                <img
                                  src={favorite.course.image}
                                  alt=""
                                  className="w-full h-60 object-cover"
                                />
                                <div className="flex items-center justify-center bg-white h-10 w-10 rounded-full absolute top-2 right-2">
                                  <button
                                    onClick={() =>
                                      removeFromFavorites(favorite.classe.id)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "star"]}
                                      className="text-secondary"
                                      size="lg"
                                    />
                                  </button>
                                </div>
                              </div>

                              <div className="p-4 flex flex-col flex-grow justify-between">
                                <div className="">
                                  <h3 className="font-bold text-gray-700 text-3xl mb-2">
                                    {favorite.course.name}
                                  </h3>
                                  {favorite.section && (
                                    <h4 className="font-bold text-gray-700 text-xl mb-2">
                                      {favorite.section.name}
                                    </h4>
                                  )}
                                  <p className="text-xl text-gray-500 mb-4">
                                    {favorite.classe.name}
                                  </p>
                                </div>

                                <div className="flex flex-col justify-between h-full">
                                  <div>
                                    <div className="text-xl">
                                      <p className="text-gray-400">
                                        <FontAwesomeIcon
                                          icon={["far", "clock"]}
                                          className="mr-2"
                                          size="lg"
                                        />
                                        {toHHMMSS(favorite.classe.time)}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="flex justify-center py-3">
                                    <CourseButton course={favorite.course} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        <div className="w-full lg:w-4/12 border border-gray-350 flex flex-col h-full">
          <div className="text-center">
            <p className=" text-center px-5 lg:text-left py-4 lg:py-8 text-2xl lg:text-3xl x text-gray-700 font-bold  border-b border-gray-350 lg:px-8  lg:self-start lg:flex flex-row">
              Noticias
            </p>
          </div>
          {newReleases.length === 0 &&
          rebates.length === 0 &&
          news.length === 0 ? (
            <div className="text-xl text-center my-4 lg:my-8">
              Sin noticias disponibles...
            </div>
          ) : (
            ""
          )}

          <div className={"px-5 lg:px-10 pt-5 lg:pt-16 space-y-8 mb-20"}>
            {newReleases.length > 0 ? (
              <div className="w-full ">
                <div className="text-xl bg-primary-new-50 text-white w-60 p-2 text-center mb-3 rounded-full">
                  Nuevos lanzamientos
                </div>
                {newReleases.map((item, key) => (
                  <ul
                    key={key}
                    className="ml-5 list-disc marker:text-primary-new text-center lg:text-left leading-none mb-2"
                  >
                    <li className="mb-1 text-left lg:text-xl font-bold">
                      {item.title}
                    </li>
                    <a
                      className="text-left flex text-secondary-new font-bold cursor-pointer"
                      onClick={() => openNewModal(item)}
                    >
                      Mas informacion
                    </a>
                  </ul>
                ))}
              </div>
            ) : (
              ""
            )}
            {rebates.length > 0 ? (
              <div className="w-full">
                <div className="text-xl bg-red-200 text-red-500 w-40 p-2 text-center mb-3 rounded-full">
                  Rebajas
                </div>
                {rebates.map((item, key) => (
                  <ul
                    key={key}
                    className="ml-5 list-disc marker:text-primary-new text-center lg:text-left leading-none mb-2"
                  >
                    <li className="mb-1 text-left lg:text-xl font-bold">
                      {item.title}
                    </li>
                    <a
                      className="text-left flex text-secondary-new font-bold cursor-pointer"
                      onClick={() => openNewModal(item)}
                    >
                      Mas informacion
                    </a>
                  </ul>
                ))}
              </div>
            ) : (
              ""
            )}

            {news.length > 0 ? (
              <div className="w-full">
                <div className="text-xl bg-yellow-200 text-yellow-500 w-40 p-2 text-center mb-3 rounded-full">
                  Anuncios
                </div>
                {news.map((item, key) => (
                  <ul
                    key={key}
                    className="ml-5 list-disc marker:text-primary-new text-center lg:text-left leading-none mb-2"
                  >
                    <li className="mb-1 text-left lg:text-xl font-bold">
                      {item.title}
                    </li>
                    <a
                      className="text-left flex text-secondary-new font-bold cursor-pointer"
                      onClick={() => openNewModal(item)}
                    >
                      Mas informacion
                    </a>
                  </ul>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
