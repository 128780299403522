import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LandingCard.css";
import { NavLink } from "react-router-dom";

const LandingCard = ({ title, icon, backText, navLink = null }) => {
  return (
    <div className="card w-[272px] h-[302px] cursor-pointer">
      <div className="card__content text-center relative p-20 transition-transform duration-1000 text-white font-bold h-full">
        <div className="card__front absolute top-0 bottom-0 right-0 left-0 p-8 pt-16 bg-primary-new-100 flex items-start justify-center rounded-xl">
          <div className="flex flex-col justify-center items-center space-y-3">
            <div className="h-[100px] w-[100px] rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon
                className="text-primary-new"
                icon={icon}
                size="3x"
              />
            </div>
            <p className="font-Montserrat text-[17px]">{title}</p>
          </div>
        </div>
        <div
          className={`card__back absolute top-0 bottom-0 right-0 left-0 p-8 bg-secondary-new flex justify-center ${
            navLink ? "items-start pt-16" : "items-center"
          } rounded-xl`}
        >
          <div className="flex flex-col justify-center items-center space-y-3">
            <p className="font-Montserrat text-[17px] text-primary-new leading-5 mb-5">
              {backText}
            </p>

            {navLink && (
              <NavLink
                to={navLink.url}
                className="bg-primary-new hover:bg-[#0A4C7D] transition-all ease-in text-white text-sm py-4 px-8 rounded-full drop-shadow-xl w-full"
              >
                <span className="text-white font-normal">{navLink.label}</span>
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCard;
