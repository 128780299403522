import * as _ from "lodash";
import moment from "moment";
import { MEMBERSHIPS_DICTIONARY } from "../../../utils/MembershipsDictionary";

const CommissionsDetails = ({
  commissions,
  breakdownSelect,
  setBreakdownSelect,
  breakdownWeekSelect,
  setBreakdownWeekSelect,
  dateRangesCombined,
  yearBar,
  setYearBar,
  arrayYears,
  filter,
  setFilter,
}) => (
  <>
    <div className="flex flex-col lg:flex-row my-10 gap-5">
      <p className="text-3xl font-bold text-gray-700 my-auto">Desglose</p>
      {commissions.length > 0 && dateRangesCombined.length > 0 && (
        <div className="w-full flex flex-col lg:flex-row gap-5 text-lg">
          <select
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value);
            }}
            name="select"
            className="w-full text-3xl md:w-1/2 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
          >
            <option value="all">Todas las ventas</option>
            <option value="memberships">Membresias</option>
            {Object.entries(MEMBERSHIPS_DICTIONARY).map(
              ([key, membership]) =>
                membership.label !== "" &&
                membership.label !== "Cursos" && (
                  <option value={membership.id}>{membership.label}</option>
                )
            )}
            <option value="courses">Cursos</option>
            <option value="products">Productos</option>
          </select>
          <select
            name="breakdown"
            value={breakdownSelect}
            className="w-full md:w-1/2 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
            onChange={(event) => {
              setBreakdownSelect(event.target.value);
            }}
          >
            <option value="currentWeek">SEMANA ACTUAL</option>
            <option value="annual">ANUAL</option>
            <option value="otherWeek">OTRA SEMANA</option>
          </select>
          {breakdownSelect === "otherWeek" && (
            <select
              name="breakdown_week"
              value={breakdownWeekSelect}
              className="w-full bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
              onChange={(event) => {
                setBreakdownWeekSelect(event.target.value);
              }}
            >
              {dateRangesCombined.map((range, idx) => (
                <option key={`otherWeek-${idx + 1}`} value={idx}>
                  {`Sab ${moment(range[0]).format("DD/MM/YYYY")} - Sab ${moment(
                    range[1]
                  ).format("DD/MM/YYYY")}`}
                </option>
              ))}
            </select>
          )}
          {breakdownSelect === "annual" && (
            <select
              className="w-full text-3xl md:w-1/2 bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
              name="changeYearBar"
              value={yearBar}
              onChange={(event) => {
                setYearBar(parseInt(event.target.value));
              }}
            >
              {arrayYears &&
                arrayYears.map((value, idx) => (
                  <option key={`year-${idx + 1}`} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          )}
        </div>
      )}
    </div>
  </>
);

export default CommissionsDetails;
