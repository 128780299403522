import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const MembershipCard = ({ membership, onClick }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 1024) {
      setIsOpen(true);
      return;
    }

    setIsOpen(false);
  }, [windowWidth]);

  return (
    <div
      className={`relative w-[373px] border border-gray-500 rounded-2xl bg-white px-8 pt-6 pb-3 lg:pb-6 lg:pt-8`}
    >
      <div className="flex justify-end">
        <button className="lg:hidden" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon
            className="text-gray-500"
            icon={isOpen ? "chevron-up" : "chevron-down"}
            size="lg"
          />
        </button>
      </div>
      <p className="font-bold text-xl text-secondary-new mb-2 lg:mb-4">
        {membership.name}
      </p>
      <div className="flex flex-col">
        <div className="flex items-center space-x-3">
          <p className="font-bold text-primary-new text-[33px]">
            ${membership.price.toLocaleString() || 0}
          </p>
          <p className="font-bold text-[10px] text-gray-new-100">
            {membership.period}
          </p>
        </div>
        <p className="font-bold text-gray-new text-sm">
          {membership.paymentInfo}
        </p>
      </div>

      <div className="flex justify-center my-3 lg:my-5">
        <button
          className={`font-bold border border-secondary-new transition-all ease-in py-4 px-8 w-[286px] h-20 rounded-full ${
            membership.cta.outline
              ? "bg-white text-secondary-new"
              : "bg-secondary-new text-white"
          }`}
          onClick={onClick}
        >
          <p>Adquirir</p>
          <p>{membership.cta.label}</p>
        </button>
      </div>

      {/* Description */}
      {isOpen && (
        <div className="flex flex-col space-y-5">
          <p className="font-bold text-gray-new text-sm">Lo que obtienes:</p>

          <div className="flex flex-col space-y-2">
            {membership.benefits.map((benefit, index) => (
              <div
                key={`benefit-${index}`}
                className="flex items-start space-x-1"
              >
                <FontAwesomeIcon
                  className={`mt-[2px] ${
                    benefit.disabled ? "text-[#B8B4B4]" : "text-[#5BCC78] "
                  }`}
                  icon={"check"}
                  size="2xs"
                />
                <p
                  className={`text-justify
 font-bold text-[13px] leading-[14px] ${
   benefit.disabled ? "line-through text-[#B8B4B4]" : "text-gray-new"
 }`}
                >
                  {benefit.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

MembershipCard.propTypes = {
  membership: PropTypes.object,
  onClick: PropTypes.func,
};

export default MembershipCard;
