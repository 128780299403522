import { Rating } from "react-simple-star-rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import CourseButton from "./courses/CourseButton";

export default function BestCoursesList({ bestCourses, handleAddCourse }) {
  return (
    <>
      <div className="hidden md:block ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mb-2">
          {bestCourses?.map((bestCourse) => (
            <BestCourse
              key={bestCourse.uuid}
              bestCourse={bestCourse}
              handleAddCourse={(id) => handleAddCourse(id)}
            />
          ))}
        </div>
      </div>
      <div className="block md:hidden">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
          }}
          navigation={true}
          modules={[Navigation, Pagination]}
          className="mySwiper3"
        >
          {bestCourses?.map((bestCourse, index) => (
            <SwiperSlide id={bestCourse.id} key={index}>
              <BestCourse
                key={bestCourse.uuid}
                bestCourse={bestCourse}
                handleAddCourse={(id) => handleAddCourse(id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

function BestCourse({ bestCourse }) {
  return (
    <div className="grid grid-cols-1 w-full xl:w-5/6 gap-8 lg:gap-16 mb-2 mx-auto">
      <div className="shadow-md flex flex-col h-[540px] lg:h-full   ">
        <div className="relative">
          <img
            src={bestCourse.image}
            alt={bestCourse.name}
            className="w-full h-64 object-cover"
          />
          <div className="bg-secondary absolute top-4 left-4 text-xs uppercase text-white font-bold px-3 pt-1 pb-2">
            Mas vendido
          </div>
        </div>
        <div className="p-4 flex flex-col flex-grow justify-between">
          <div>
            <h3 className="font-bold text-gray-700 text-xl lg:text-3xl mb-2 text-center lg:text-left">
              {bestCourse.name}
            </h3>
            <div className="text-center lg:text-left">
              <Rating
                size={25}
                emptyColor="#e2e2e2"
                fillColor="#ffc107"
                transition
                ratingValue={bestCourse.rating}
                initialValue={bestCourse.rating}
                showTooltip={false}
                readonly={true}
              />
            </div>

            <p className="block lg:hidden text-xl w-66 mb-4 text-gray-650">
              {bestCourse.description.length >= 99
                ? bestCourse.description.substring(0, 99) + "..."
                : bestCourse.description}
            </p>
            <p className="hidden lg:block text-xl w-66 mb-4 text-gray-650">
              {bestCourse.description}
            </p>
          </div>
          <div className="flex justify-center pt-4">
            <CourseButton course={bestCourse} />
          </div>
        </div>
      </div>
    </div>
  );
}
