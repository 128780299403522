const ServicesDetails = ({ service, sub }) => {
  return (
    <div className="flex relative py-4 px-2 lg:px-5 border-b border-gray-300">
      <div className="flex-shrink-0">
        <img
          src={service.image ? service.image : null}
          alt=""
          className="h-20 w-20 rounded-md object-cover object-center"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col sm:ml-6">
        <div>
          <div className="flex justify-between">
            <div>
              <p className="text-primary-new-50 font-bold text-sm lg:text-xl text-left italic leading-none">
                {sub}
              </p>
              <p className="text-gray-700 font-bold text-sm lg:text-xl text-left leading-none">
                {service.name}
              </p>
            </div>
            <p className="lg:ml-8 font-bold text-sm lg:text-xl text-gray-900 leading-none text-right">
              $
              {service.cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}{" "}
              <br /> <small>MXN</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesDetails;
