import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "react-skeleton-generator";
import { shopifyService } from "../../services/api/shopify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCard from "../../components/store/ProductCard";
import * as _ from "lodash";
import "../../components/store/CarrouselProducts";

const sortFilters = [
  { id: "new", title: "Fecha: Más reciente" },
  { id: "old", title: "Fecha: Más antiguo" },
  { id: "mostsell", title: "Más vendidos" },
  { id: "alfa", title: "Alfabeticamente A-Z" },
  { id: "alfz", title: "Alfabeticamente Z-A" },
  { id: "cheapest", title: "Precio: Menor a mayor" },
  { id: "expensive", title: "Precio: Mayor a menor" },
];

const Index = () => {
  localStorage.removeItem("scrollPosition");
  const firstSelection = "new";
  const navigate = useNavigate();
  const params = useParams();
  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState(null);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [originalCollectionProducts, setOriginalCollectionProducts] = useState(
    []
  );
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedCategories, setIsLoadedCategories] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState(null);
  const [selectedSortedOption, setSelectedSortedOption] =
    useState(firstSelection);
  const [showFilters, setShowFilters] = useState(false);
  const [searchProducts, setSearchProducts] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [quantity, setQuantity] = useState(0);

  const [title, setTitle] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  const goToProductDetail = (id, store_id) => {
    navigate(`/tienda/${store_id}/producto/${id}`);
  };

  const handleOnSortCategory = (event) => {
    const value = event.target.value;
    setSelectedSortedOption(value);
    if (products) {
      let filteredProducts = [...originalCollectionProducts];

      if (selectedTags.length > 0) {
        filteredProducts = filteredProducts.filter((product) => {
          return selectedTags.some((tag) => product.tags.includes(tag));
        });
      }

      const sortFunctions = {
        cheapest: (a, b) =>
          parseFloat(a.variants.edges[0].node.price.amount) -
          parseFloat(b.variants.edges[0].node.price.amount),
        expensive: (a, b) =>
          parseFloat(b.variants.edges[0].node.price.amount) -
          parseFloat(a.variants.edges[0].node.price.amount),
        new: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        old: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        alfa: (a, b) => a.title.localeCompare(b.title),
        alfz: (a, b) => b.title.localeCompare(a.title),
        mostsell: (a, b) => (a.sold > b.sold ? -1 : a.sold < b.sold ? 1 : 0),
      };

      if (sortFunctions[value]) {
        if (selectedTags.length === 0 && title === "") {
          filteredProducts = originalCollectionProducts.slice(); // Asigna los productos completos
        }
        if (title !== "") {
          let searchWords = title.split(" ");
          filteredProducts = filteredProducts.filter((product) =>
            searchWords.every((word) =>
              product.title.toLowerCase().includes(word.toLowerCase())
            )
          );
        }
        if (value === "mostsell") {
          setQuantity(filteredProducts.length);
          setProducts(filteredProducts);
          return;
        }
        filteredProducts.sort(sortFunctions[value]);
      } else {
        filteredProducts = originalCollectionProducts.slice();
      }
      setQuantity(filteredProducts.length);
      setProducts(filteredProducts);
    }

    setShowSidebar(false);
  };

  const navigateToCategory = (store_id, id) => {
    navigate(`/tienda/${store_id}/categoria/${id}`);
    setShowFilters(false);
    setIsLoaded(false);
  };

  const addToTags = (tag) => {
    let tagsToAdd = [...selectedTags];
    let tagsFound = selectedTags.filter((t) => t === tag).length > 0;

    if (tagsFound) {
      tagsToAdd = tagsToAdd.filter((t) => t !== tag);
    } else {
      tagsToAdd.push(tag);
    }

    setSelectedTags(tagsToAdd);
  };

  const fetchCollections = () => {
    shopifyService
      .getAllCollections()
      .then((response) => {
        let collectionsFetch = response.data.collects;
        setCollections(collectionsFetch);
      })
      .catch((err) => {})
      .finally(() => setIsLoadedCategories(true));
  };

  const fetchCollectionById = async (id) => {
    await shopifyService
      .getCollection(id)
      .then((response) => {
        let data = response.data;
        setCollection(data.collection);
        setProducts(data.products);
        setQuantity(data.products.length);
        setOriginalProducts(data.products);
        setSearchProducts(data.products);
        setTags(data.tags);
        setFirstLoad(true);
        setIsLoaded(true);
        setSelectedSortedOption(firstSelection);
        setOriginalCollectionProducts(data.products);
        setShowFilters(true);
        setShowSidebar(false);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    if (products.length > 0 && firstLoad) {
      let event = { target: { value: firstSelection } };
      handleOnSortCategory(event);
      setFirstLoad(false);
    }
  }, [products, firstLoad, selectedSortedOption]);

  const handleOnSearchChange = (event) => {
    setTitle(event.target.value);
  };

  const cleanFilters = () => {
    setSelectedCategoryOption(null);
    setProducts(originalProducts);
    setSelectedSortedOption("new");
    setSelectedTags([]);
    setTitle("");
    setShowSidebar(false);
  };

  useEffect(() => {
    fetchCollectionById(params.id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCollections();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((selectedSortedOption && tags.length > 0) || title) {
      let event = { target: { value: selectedSortedOption } };
      handleOnSortCategory(event);
    }
  }, [selectedTags, title]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="pb-12 lg:pb-24">
      {/* TOP */}
      <div className="mx-auto w-full">
        <div className="bg-primary-new h-14 lg:h-24 flex justify-center items-center">
          <p className="text-white font-bold text-[13px] lg:text-[22px]">
            Beneficios exclusivos que ofrecemos a nuestros miembros.
          </p>
        </div>
      </div>

      {/* HEADER */}
      <div className="mb-4 lg:mb-8 lg:shadow-md">
        <div className="container mx-auto px-0 md:px-4 flex flex-col md:flex-row justify-between items-center py-5 lg:py-8 space-y-5 md:space-y-0">
          <div className="flex flex-col-reverse md:flex-row justify-between w-full">
            <div className="w-auto mx-4 md:mx-0">
              {collections.length > 0 && (
                <select
                  onChange={(event) => {
                    let result = event.target.value.split("-");
                    navigateToCategory(result[0], result[1]);
                    fetchCollectionById(result[1]);
                    cleanFilters();
                  }}
                  value={params.store_id + "-" + params.id}
                  className="pl-4 pr-10 py-2 border border-gray-500 rounded-xl w-full focus:outline-none"
                >
                  {collections
                    ? collections.map((value, idx) => (
                        <option
                          value={value.store.id + "-" + value.id}
                          key={idx}
                        >
                          {value.title}
                        </option>
                      ))
                    : ""}
                </select>
              )}
            </div>

            <div className="w-full text-xl lg:text-2xl font-bold text-primary-200 text-center py-5 sm:py-0 my-auto">
              {collection && collection.title}
            </div>
          </div>
          {products && isLoaded && (
            <div className="flex justify-end items-center w-full px-4 md:px-0 md:w-auto">
              <div className="relative w-full">
                <input
                  type="text"
                  id="searchInput"
                  className="pl-4 pr-10 py-2 border border-gray-500 rounded-xl w-full focus:outline-none"
                  placeholder="Buscar productos"
                  value={title}
                  onChange={handleOnSearchChange}
                  onKeyDown={(event) => {
                    if (collection) return;
                  }}
                />
                <button
                  type="button"
                  disabled
                  id="searchButton"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-primary-new focus:outline-none"
                  onClick={() => title.length > 0 && !isLoading}
                >
                  <FontAwesomeIcon
                    icon={[
                      "fa",
                      isLoading ? "circle-notch" : "magnifying-glass",
                    ]}
                    size="1x"
                    spin={isLoading}
                  />
                </button>
              </div>
            </div>
          )}

          {/* FILTERS MOBILE BUTTON */}
          <div className="flex justify-center md:hidden">
            <button
              className="flex justify-center text-sm items-center border border-gray-500 rounded-full py-2 px-6 w-full hover:bg-gray-200"
              onClick={() => setShowSidebar(true)}
            >
              <FontAwesomeIcon
                icon={["fa", "filter-list"]}
                className="text-gray-new mr-1"
                size="1x"
              />{" "}
              <span className="font-bold text-gray-new">Filtros</span>
            </button>
          </div>
        </div>
      </div>

      {/* CONTENT */}

      <div className="w-full">
        <div className="container mx-auto w-full pt-2 md:pt-6 px-4">
          <div>
            {showFilters && (
              <div className="flex justify-between">
                <p
                  className={`font-bold text-gray-new text-center text-base md:text-left lg:text-[22px] pb-5`}
                >
                  FILTRAR Y ORDENAR
                </p>

                <p
                  className={`font-bold text-gray-new text-center text-base md:text-left lg:text-[22px] pb-5`}
                >
                  {quantity} PRODUCTOS
                </p>
              </div>
            )}

            <div
              className={`grid ${
                showFilters &&
                "grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5"
              }`}
            >
              {showFilters && (
                <div className="hidden md:block border-t border-[#E6E6E6] py-4">
                  {collection && (
                    <>
                      <div>
                        <p className="font-bold text-gray-new text-center text-base md:text-left lg:text-[22px] pb-5">
                          Ordenar
                        </p>

                        <form>
                          {sortFilters.map((filter) => (
                            <div
                              key={filter.id}
                              className="flex flex-col items-start"
                            >
                              <label className="flex flex-row items-start text-sm font-bold text-gray-new leading-5">
                                <input
                                  type="radio"
                                  name="opcion"
                                  value={filter.id}
                                  className="mr-2 mt-[2px] accent-primary-new"
                                  checked={selectedSortedOption === filter.id}
                                  onChange={handleOnSortCategory}
                                />
                                {filter.title}
                              </label>
                            </div>
                          ))}
                        </form>
                      </div>
                    </>
                  )}
                  {collection && tags.length > 0 && (
                    <>
                      <hr className="border-[#E6E6E6] my-4" />
                      <div>
                        <p className="font-bold text-gray-new text-center text-base md:text-left lg:text-[22px] pb-5">
                          Etiquetas
                        </p>

                        <form>
                          <div className="h-auto max-h-60 overflow-auto">
                            {tags.map((tag, index) => (
                              <div
                                key={index}
                                className="flex flex-col items-start"
                              >
                                <label className="flex flex-row items-start text-sm font-bold text-gray-new leading-5">
                                  <input
                                    type="checkbox"
                                    name="opcion"
                                    value={tag}
                                    className="mr-2 mt-[2px] accent-primary-new"
                                    onChange={(e) => addToTags(e.target.value)}
                                    checked={selectedTags.includes(tag)}
                                  />
                                  {tag}
                                </label>
                              </div>
                            ))}
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  <div className="w-full">
                    <button
                      className="flex justify-center text-sm items-center border border-gray-500 rounded-xl px-2 py-1 w-full mt-5 hover:bg-gray-200"
                      onClick={cleanFilters}
                    >
                      <span className="font-bold text-gray-new">
                        Limpiar filtros
                      </span>
                    </button>
                  </div>
                </div>
              )}

              <>
                <div className="col-span-4 md:col-span-3 xl:col-span-4 2xl:col-span-5 ">
                  <div className="">
                    {isLoaded && products.length > 0 && (
                      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 place-items-center gap-y-8 md:gap-x-5 md:gap-y-10 xl:gap-x-8 xl:gap-y-16">
                        {products.map((product) => (
                          <ProductCard
                            key={product.id}
                            category_id={product.shopify_store_id}
                            product={product}
                            onClick={goToProductDetail}
                          />
                        ))}
                      </div>
                    )}
                    {!isLoaded && (
                      <Skeleton.SkeletonThemeProvider animation="opacity">
                        <div>
                          <div className="">
                            <div className="container mx-auto w-full pt-12 md:pt-24 px-4 md:px-0">
                              <div className="pb-12 md:pb-24">
                                {/* PRODUCT LIST */}
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
                                  {[...Array(20)].map((e, idx) => (
                                    <div key={idx} className="h-72">
                                      <Skeleton
                                        height="100%"
                                        borderRadius="0px"
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Skeleton.SkeletonThemeProvider>
                    )}
                    {collection && products.length === 0 && <NoProductsFound />}
                  </div>
                </div>

                {/* OFFERS */}
              </>
            </div>
          </div>
        </div>
      </div>

      {/* FILTERS MOBILE */}
      <>
        {/* Sidebar */}
        <div
          className={`block md:hidden w-5/6 text-gray-700 h-full fixed top-0 left-0 z-50 shadow-md ease-in-out duration-300 overflow-auto transform ${
            showSidebar ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ backgroundColor: "#FCFBFC" }}
        >
          {showFilters && (
            <div className="mt-5 text-xl p-5">
              <p className="font-bold text-gray-new text-left text-2xl pb-5">
                FILTRAR Y ORDENAR
              </p>

              <div className="border-t border-[#E6E6E6] py-4">
                {collection && (
                  <>
                    <div>
                      <p className="font-bold text-gray-new text-left text-xl pb-5">
                        Ordenar
                      </p>

                      <form>
                        {sortFilters.map((filter) => (
                          <div
                            key={filter.id}
                            className="flex flex-col items-start"
                          >
                            <label className="flex flex-row items-start text-sm font-bold text-gray-new leading-5">
                              <input
                                type="radio"
                                name="opcion"
                                value={filter.id}
                                className="mr-2 mt-[2px] accent-primary-new"
                                checked={selectedSortedOption === filter.id}
                                onChange={handleOnSortCategory}
                              />
                              {filter.title}
                            </label>
                          </div>
                        ))}
                      </form>
                    </div>
                  </>
                )}
                {collection && tags.length > 0 && (
                  <>
                    <hr className="border-[#E6E6E6] my-4" />
                    <div>
                      <p className="font-bold text-gray-new text-left text-xl pb-5">
                        Etiquetas
                      </p>
                      <form>
                        <div className="h-auto max-h-60 overflow-auto">
                          {tags.map((tag, index) => (
                            <div
                              key={index}
                              className="flex flex-col items-start"
                            >
                              <label className="flex flex-row items-start text-sm font-bold text-gray-new leading-5">
                                <input
                                  type="checkbox"
                                  name="opcion"
                                  value={tag}
                                  className="mr-2 mt-[2px] accent-primary-new"
                                  onChange={(e) => addToTags(e.target.value)}
                                  checked={selectedTags.includes(tag)}
                                />
                                {tag}
                              </label>
                            </div>
                          ))}
                        </div>
                      </form>
                    </div>
                  </>
                )}

                {/* CLEAN FILTERS */}
                <div className="w-full">
                  <button
                    className="flex justify-center text-sm items-center border border-gray-500 rounded-xl px-2 py-1 w-full mt-5 hover:bg-gray-200"
                    onClick={cleanFilters}
                  >
                    <span className="font-bold text-gray-new">
                      Limpiar filtros
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Overlay */}
        {showSidebar && (
          <div
            role="button"
            className="fixed inset-0 bg-black bg-opacity-25 z-40 ease-in-out duration-150"
            onClick={() => setShowSidebar(false)}
          />
        )}
      </>
    </div>
  );
};

const productsSkeleton = (section) => {
  return [...Array(5)].map((e, idx) => (
    <Skeleton.SkeletonThemeProvider key={idx} animation="opacity">
      <div key={`${section}-${idx}`} className="h-72">
        <Skeleton height="100%" borderRadius="0px" />
      </div>
    </Skeleton.SkeletonThemeProvider>
  ));
};

const NoProductsFound = () => {
  return (
    <div className="flex flex-col mx-auto container w-full space-y-5">
      <FontAwesomeIcon
        icon={["fa", "face-thinking"]}
        className="text-secondary-new"
        size="4x"
      />
      <div className="flex flex-col space-y-3">
        <p className="font-bold text-primary-new-50 text-center text-[25px] lg:text-[33px]">
          No se encontraron productos
        </p>
        <p className="font-bold text-gray-new text-center text-[13px] lg:text-base leading-5">
          No hay productos que coincidan con tus filtros actuales.{" "}
          <br className="hidden md:block" /> Intenta eliminar algunos de ellos
          para obtener mejores resultados.
        </p>
      </div>
    </div>
  );
};

export default Index;
