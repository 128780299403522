import { memberships } from "./memberships";

export const MEMBERSHIPS_DICTIONARY = {
  [memberships.BASICA_EDUCATIVA]: {
    backgroundColor: "rgba(255, 99, 132, 0.7)",
    label: "Básica Educativa",
    id: 1,
    UUID: "ee7319e3-6260-43fb-a96a-3b80d6ec1a57",
  },
  [memberships.BASICA_DROPSHIPPING]: {
    backgroundColor: "rgba(54, 162, 235, 0.7)",
    label: "Dropshipping",
    id: 2,
    UUID: "cca081ae-73d0-4dbb-bee3-17c164d58c74",
  },
  [memberships.PLUS_MENSUAL]: {
    backgroundColor: "rgba(255, 206, 76, 0.7)",
    label: "Plus Mensual",
    id: 3,
    UUID: "9c6308c4-5892-4f9d-9ce3-45a4d7aa5ce7",
  },
  [memberships.PLUS_TRIMESTRAL]: {
    backgroundColor: "rgba(75, 192, 192, 0.7)",
    label: "Plus Trimestral",
    id: 4,
    UUID: "cc4d0e84-128b-4f4e-a13c-ebfd7dec15d6",
  },
  [memberships.PLUS_ANUAL]: {
    backgroundColor: "rgba(153, 102, 255, 0.7)",
    label: "Plus Anual",
    id: 5,
    UUID: "94ec2191-c716-493c-9182-aeb51335ce99",
  },
  [memberships.PREMIUM]: {
    backgroundColor: "rgba(255, 160, 47, 0.82)",
    label: "Premium",
    id: 6,

    UUID: "3c84d38a-aabc-4bd0-87bc-03e44f857966",
  },
  [memberships.PLUS_SEMESTRAL]: {
    backgroundColor: "rgba(163, 147, 0, 0.8)",
    label: "Plus Semestral",
    id: 7,
    UUID: "41e1a2a9-d144-436c-bfff-7d7678eab078",
  },
  [memberships.ANUAL_DROPSHIPPING]: {
    backgroundColor: "rgba(255, 255, 255)",
    label: "",
    id: 8,
  },
  [memberships.EMPRENDEDOR]: {
    backgroundColor: "rgba(78, 200, 12, 0.7)",
    label: "Emprendedor",
    id: 9,
    UUID: "3c84d38a-aabc-4bd0-87bc-03e44f857966",
  },
  [memberships.INFINITE]: {
    backgroundColor: "rgba(144, 14, 113, 0.7)",
    label: "Infinita",
    id: 10,
    UUID: "8d4494d6-4435-4353-869a-29b763433ab3",
  },

  default: {
    backgroundColor: "rgba(50, 50, 255, 0.7)",
    label: "Cursos",
  },
};
