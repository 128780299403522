import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-0H4WH9K2H4", {
        page_path: location.pathname + location.search,
      });
    } else {
      console.error("Google Analytics (gtag) no está disponible");
    }
  }, [location]);
  return null;
}

export default GoogleAnalytics;
