import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";
import BasicModal from "../../components/Modals/BasicModalEvent";
import { eventServices } from "../../services/api/events";
import Context from "../../context/UserContext";
import { notifications } from "../../utils/notifications";
import CalendarComponent, {
  changeEventFormat,
} from "../../components/Calendar/CalendarComponent";
import ScheduleEventComponent from "../../components/Calendar/ScheduleEventComponent";
moment.locale("es");

export default function Index() {
  const { currentUser } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [eventsCalendar, setEventsCalendar] = useState([]);

  const fetchEvents = () => {
    eventServices
      .getUserEvents()
      .then((response) => {
        setEventsCalendar(response.data.events);
      })
      .catch((err) => {});
  };

  const getEvent = (event) => {
    const handleSubmit = async (id) => {
      let bodyFormData = new FormData();
      bodyFormData.append("id", id);
      await eventServices
        .SetEvent(bodyFormData)
        .then((response) => {
          notifications.success(response.data.message);
          setShowModal(false);
          fetchEvents();
        })
        .catch((err) => {});
    };

    setModalContent(
      <ScheduleEventComponent
        event={event}
        handleSubmit={(id) => handleSubmit(id)}
      ></ScheduleEventComponent>
    );
    setShowModal(true);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchEvents();
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {eventsCalendar ? eventsCalendar.forEach(changeEventFormat) : ""}
      <BasicModal
        visible={showModal}
        setVisible={setShowModal}
        modalContent={modalContent}
      />
      <div className="capitalize" style={{ height: "500pt" }}>
        <div className="text-gray-new normal-case text-center lg:text-left text-2xl mb-5 font-bold">
          Agenda y asiste a las reuniones virtuales gratuitas que incluye tu
          membresía
        </div>
        <CalendarComponent
          eventsCalendar={eventsCalendar}
          getEvent={getEvent}
        ></CalendarComponent>
      </div>
    </div>
  );
}
