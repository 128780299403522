import Rodal from "rodal";
import "rodal/lib/rodal.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NetworkMonitorModal = ({ visible, setVisible }) => {
  return (
    <div>
      <div>
        <Rodal
          visible={visible}
          onClose={() => setVisible(false)}
          closeOnEsc={false}
          showCloseButton={false}
          customStyles={{
            width: "95%",
            maxWidth: 700,
            height: "85%",
            maxHeight: 550,
            padding: "0",
            overflowY: "scroll",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full px-8">
            <FontAwesomeIcon
              icon={["fa", "circle-notch"]}
              size={"6x"}
              className="text-primary-new"
              spin
            />
            <h2 className="text-center font-bold text-gray-700 px-10 text-3xl my-4">
              Recopilando información sobre el estado de su red ...
            </h2>
            <h2 className="text-center font-bold text-gray-700 text-2xl my-4">
              Por favor, no salga o cierre la página.
            </h2>
            <p className="text-center text-gray-700 text-lg my-4 px-10 pb-4 underline">
              "Esta ventana se cerrará automáticamente una vez que se recopile
              información sobre el estado de su red."
            </p>
          </div>
        </Rodal>
      </div>
    </div>
  );
};

export default NetworkMonitorModal;
