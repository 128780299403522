import { useEffect, useState } from "react";
import { blogServices } from "../../services/api/blog";
import { useNavigate, useParams } from "react-router-dom";
import posterBackup from "../../assets/images/imagen-contenido-curso.png";
import TableLoading from "../../components/TableLoading";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFoundComponent from "../../components/blog/NotFoundComponent";

export default function PostById() {
  const params = useParams();
  const navigate = useNavigate();

  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [processedBody, setProccesedBody] = useState("");
  const [author, setAuthor] = useState(null);
  const [metaInformation, setMetaInformation] = useState(null);
  const [authorMetaInformation, setAuthorMetaInformation] = useState(null);
  const replaceWithBr = (text) => {
    return text ? text.replace(/\n/g, "<br />") : "";
  };

  const handleClick = (slug) => {
    navigate(`/blog/autor/${slug}`);
  };

  const addLineBreaksAfterStrongNumber = (html) => {
    return html
      .replace(/(<p>)/g, "<br />$1")
      .replace(/(\d+\.\s*<strong>)/g, "$1");
  };

  const fetchPostById = () => {
    setIsLoading(true);

    blogServices
      .getPostById(params.id)
      .then((response) => {
        setPost(response.data.post);
        setAuthor(response.data.author);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPostById();
  }, []);

  useEffect(() => {
    if (!post) return;
    setProccesedBody(addLineBreaksAfterStrongNumber(post.body));
  }, [post]);

  useEffect(() => {
    if (author) {
      if (author.meta_description === null) return;
      const metadata = JSON.parse(author.meta);
      setAuthorMetaInformation(JSON.parse(author.meta));
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", metadata.meta_description);
      } else {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = metadata.meta_description;
        document.head.appendChild(meta);
      }
    }
  }, [author]);

  return (
    <section className="bg-gray-300 ">
      <div className="min-h-screen">
        {post && (
          <div className="my-10">
            <div className="container mx-auto px-4 md:px-0 md:py-5  bg-white h-full rounded-md shadow-xl w-11/12 lg:w-2/3">
              <div className="p-10">
                <div className="space-y-2 pb-4">
                  <p className="text-primary-400 text-2xl  md:text-3xl font-bold ">
                    {post.title}
                  </p>
                  <p className="text-gray-600 text-sm md:text-2xl ">
                    {moment(post.publish_date).format("D [de] MMMM [del] YYYY")}{" "}
                    {post.tags?.map(({ name, slug }) => (
                      <>
                        <b
                          onClick={() => navigate("/blog/tag/" + slug)}
                          className="bg-gray-600 rounded-md hover:cursor-pointer text-white px-10 md:px-5  hover:bounce transition transform hover:scale-105 "
                        >
                          #{name}
                        </b>{" "}
                      </>
                    ))}
                  </p>
                  <img
                    alt=""
                    src={
                      post.featured_image ? post.featured_image : posterBackup
                    }
                    className="h-40  md:h-80 w-full object-cover"
                  />
                </div>
                <div className="leading-7">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: processedBody,
                    }}
                  />
                </div>
                {metaInformation &&
                  (metaInformation.opengraph_title ||
                    metaInformation.twitter_title) && (
                    <div className="text-xl sm:text-2xl text-primary-300 font-bold my-3 sm:my-5">
                      <p className="py-2 sm:py-4 text-center lg:text-left">
                        Redes
                      </p>
                      <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
                        {metaInformation.opengraph_title && (
                          <a
                            href={metaInformation.opengraph_description}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex justify-center sm:justify-start"
                          >
                            <FontAwesomeIcon
                              icon={["fab", "facebook"]}
                              alt="Facebook"
                              className="h-10 w-10 sm:h-20 sm:w-20"
                            />
                          </a>
                        )}
                        {metaInformation.twitter_title && (
                          <div className="flex flex-col items-center sm:items-start">
                            <a
                              href={metaInformation.twitter_description}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex justify-center sm:justify-start"
                            >
                              <FontAwesomeIcon
                                icon={["fab", "twitter"]}
                                alt="Twitter"
                                className="h-10 w-10 sm:h-20 sm:w-20"
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </div>
              <div className="flex justify-between p-5 border-t border-gray-400">
                <div className="flex space-x-2">
                  <div className="h-20 w-24 md:h-40 md:w-40 bg-gray-500 rounded-full overflow-hidden">
                    <a onClick={() => handleClick(post.author.slug)}>
                      <img
                        alt=""
                        src={post.author.avatar}
                        className="h-full w-full object-cover hover:cursor-pointer"
                      />
                    </a>
                  </div>
                  <div>
                    <p className="my-auto text-xl md:text-3xl">
                      Escrito por{" "}
                      <a
                        className="font-bold hover:cursor-pointer"
                        onClick={() => handleClick(post.author.slug)}
                      >
                        {post.author.name}
                      </a>
                    </p>
                    <p
                      className="text-sm md:text-2xl"
                      dangerouslySetInnerHTML={{
                        __html: replaceWithBr(post.author.bio),
                      }}
                    />
                    <p></p>
                    {authorMetaInformation && (
                      <div className="flex space-x-4 mt-2 text-primary-300">
                        {authorMetaInformation.twitter_description && (
                          <>
                            <a
                              href={authorMetaInformation.twitter_description}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={["fab", "twitter"]}
                                alt="Twitter"
                                className="h-10 w-10"
                              />
                            </a>
                          </>
                        )}

                        {authorMetaInformation.opengraph_description && (
                          <>
                            <a
                              href={authorMetaInformation.opengraph_description}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={["fab", "facebook"]}
                                alt="Facebook"
                                className="h-10 w-10"
                              />
                            </a>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="h-full w-full bg-gray-300 container px-auto mx-auto text-center my-auto pt-80">
            <TableLoading size="10x" />
          </div>
        )}
        {!isLoading && !post && (
          <div className="my-20">
            <NotFoundComponent
              label={"No se encontró ningún artículo."}
              buttonText={"blog"}
              url={"/blog"}
            />
          </div>
        )}
      </div>
    </section>
  );
}
