import { useState, useEffect, useContext } from "react";
import { coursesServices } from "../../services/api/courses";
import Context from "../../context/UserContext";
import CourseCompleted from "../../components/courses/CourseCompleted";
import CarrouselCoursesCompleted from "../../components/courses/CarrouselCoursesCompleted";

const MyCourses = () => {
  const { currentUser } = useContext(Context);
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [coursesToTake, setCoursesToTake] = useState([]);
  const [coursesFinished, setCoursesFinished] = useState([]);

  const fetchUserCourses = () => {
    coursesServices
      .getUserCourses()
      .then((response) => {
        setCoursesInProgress(response.data.coursesInProgress);
        setCoursesToTake(response.data.coursesByCourses);
        setCoursesFinished(response.data.coursesFinished);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchUserCourses();
  }, [currentUser]);

  return (
    <>
      <section className="container mx-auto px-2">
        <h1 className="text-gray-new font-bold text-xl lg:text-3xl mb-8 pb-2 border-b border-gray-350 text-center lg:text-left">
          En curso
        </h1>

        <div className="hidden lg:block ">
          {coursesInProgress.length === 0 && (
            <div>Aún no está cursando ningun curso.</div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-2">
            {coursesInProgress.map((course, key) => (
              <CourseCompleted key={key} course={course} />
            ))}
          </div>
        </div>

        <div className="relative flex items-center block lg:hidden">
          {coursesInProgress.length === 0 && (
            <div>Aun no esta cursando ningun curso.</div>
          )}
          {
            <CarrouselCoursesCompleted
              courses={coursesInProgress}
            ></CarrouselCoursesCompleted>
          }
        </div>
      </section>

      <section className="container mx-auto px-2 my-8">
        <h1 className="text-gray-700 font-bold text-xl lg:text-3xl mb-8 pb-2 border-b border-gray-350 text-center lg:text-left">
          Por Cursar
        </h1>

        <div className="hidden lg:block ">
          {coursesToTake.length === 0 && (
            <div>Aún no tiene cursos por cursar.</div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-2">
            {coursesToTake.map((course, key) => (
              <CourseCompleted key={key} course={course}></CourseCompleted>
            ))}
          </div>
        </div>

        <div className="relative flex items-center block lg:hidden">
          {coursesToTake.length === 0 && (
            <div>Aún no tiene cursos por cursar.</div>
          )}

          {
            <CarrouselCoursesCompleted
              courses={coursesToTake}
            ></CarrouselCoursesCompleted>
          }
        </div>
      </section>

      <section className="container mx-auto px-2 my-8">
        <h1 className="text-gray-700 font-bold text-xl lg:text-3xl mb-8 pb-2 border-b border-gray-350 text-center lg:text-left">
          Cursos completados
        </h1>

        <div className="hidden lg:block ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-2">
            {coursesFinished.length === 0 && (
              <div>Aún no ha completado ningún curso.</div>
            )}
            {coursesFinished.map((course, key) => (
              <CourseCompleted
                key={key}
                course={course}
                completed
              ></CourseCompleted>
            ))}
          </div>
        </div>

        <div className="relative flex items-center block lg:hidden">
          {coursesFinished.length === 0 && (
            <div>Aún no ha completado ningún curso.</div>
          )}

          {
            <CarrouselCoursesCompleted
              courses={coursesFinished}
              completed
            ></CarrouselCoursesCompleted>
          }
        </div>
      </section>
    </>
  );
};

export default MyCourses;
