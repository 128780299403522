import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import TableLoading from "../../../components/TableLoading";
import moment from "moment";

import * as _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { adminUsersService } from "../../../services/api/admin/users";
import Context from "../../../context/AdminUserContext";

import {
  labelsByMonth,
  optionsBarWithCurrency,
} from "../../../utils/chartsOptions";

import {
  paginationComponentOptions,
  columnsProducts,
} from "../../../utils/tableOptions";
registerLocale("es", es);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ProductSales = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const { currentAdminUser } = useContext(Context);
  const [member, setMember] = useState({});
  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [loading, setLoading] = useState(true);

  const data = {
    labels: labelsByMonth,
    datasets: [
      {
        data: labelsByMonth.map((el, idx) => {
          let total = 0;
          let mFilter = products.filter((m) => {
            let date = m.created_at.split(" ")[0];
            let month = parseInt(date.split("-")[1]);
            if (month === idx + 1) return true;
            return false;
          });

          mFilter.forEach(
            (product) => (total += product.cost * product.amount)
          );
          return total;
        }),
        backgroundColor: "#003159",
      },
    ],
  };

  const fetchProductsSalesData = () => {
    adminUsersService
      .getUserProduct(params.id)
      .then((response) => {
        const responseProducts = response.data.products.map(
          ({ id, ...rest }) => rest
        );
        setProducts(responseProducts);
        setTableData(responseProducts);
        setMember(response.data.user);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let filteredData = tableData.filter((product) => {
      if (!endDate && !startDate) {
        return true;
      }
      return moment(product.created_at).isBetween(startDate, endDate);
    });
    setFilteredData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, endDate]);

  const changeDates = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchProductsSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  return (
    <div className="container mx-auto">
      {loading ? (
        <div className=" w-full mx-auto text-center">
          <TableLoading size="3x" />
        </div>
      ) : (
        <div>
          <div className="flex flex-col md:flex-row mb-10 text-center md:text-left">
            <p className="text-4xl font-bold text-gray-700 mr-2">Ventas de</p>
            {!_.isEmpty(member) && (
              <p className="text-4xl font-bold text-primary-200">
                {member.name}
              </p>
            )}
          </div>
          <div>
            <Bar options={optionsBarWithCurrency} data={data} />
          </div>
          <div className="flex mt-16 md:mt-24 lg:mt-32 mb-10">
            <p className="text-3xl font-bold text-gray-700">
              Historial de Ventas
            </p>
          </div>
          <div className="border border-gray-350 p-5">
            <div>
              <div className="w-full lg:w-1/3 ml-auto text-lg mb-5">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(value) => {
                    changeDates(value);
                  }}
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  className="text-gray-700 placeholder:text-gray-700 border border-slate-300 focus:outline-none py-1 px-3 w-full"
                  placeholderText="Buscar por rango de fechas..."
                  locale="es"
                />
              </div>
              <div>
                <DataTable
                  columns={columnsProducts}
                  data={filteredData}
                  noDataComponent="No hay información que mostrar."
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </div>
          </div>

          <div className="mt-10">
            <button
              className="w-full md:w-56 bg-primary-200 text-white text-2xl py-3 px-5 disabled:opacity-75"
              onClick={() => navigate(-1)}
            >
              Regresar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductSales;
