import { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";
import "../../styles/time-picker.css";
import "moment/locale/es";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import useProfile from "../../hooks/useProfile";

import Context from "../../context/UserContext";

import mexicoStates from "../../utils/mexicoStates";

const General = () => {
  const regex = /^(?:\+?\d+\s?)?(?:\(\d+\)|\d+)(?:-?\s?\d+)*$/;
  const formSchema = Yup.object().shape({
    phone: Yup.string()
      .max(10, `Máximo 10 caracteres`)
      .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
  });

  const { currentUser } = useContext(Context);
  const { isLoading, updateUser } = useProfile();

  const [initialValues, setInitialValues] = useState({
    name: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    country_trigger: "",
    gender: "",
    education_level: "",
    birthdate: "",
  });

  useEffect(() => {
    if (currentUser) {
      const birthdate = currentUser.birthdate;
      const formattedDate = birthdate
        ? new Date(birthdate + "T00:00:00")
        : new Date();

      setInitialValues({
        name: currentUser.name || "",
        phone: currentUser.phone || "",
        city: currentUser.city || "",
        state: currentUser.state || "",
        gender: currentUser.gender || "",
        birthdate: formattedDate || "",
        education_level: currentUser.education_level || "",
        country: countryValidation(currentUser.country),
        country_trigger: countryTriggerValidation(currentUser.country),
      });
    }
  }, [currentUser]);

  const countryValidation = (country) => {
    if (!country) return "";
    return country !== "México" ? country : "";
  };

  const countryTriggerValidation = (country) => {
    if (!country) return "MX";
    return country === "México" ? "MX" : "OTHER";
  };

  const handleSubmit = async (values) => {
    let country = values.country === "" ? "México" : values.country;

    delete values.country_trigger;
    let newValues = { ...values, country };
    !newValues.phone && delete newValues.phone;
    !newValues.name && delete newValues.name;
    !newValues.city && delete newValues.city;
    !newValues.state && delete newValues.state;
    !newValues.gender && delete newValues.gender;
    !newValues.birthdate && delete newValues.birthdate;
    !newValues.education_level && delete newValues.education_level;
    await updateUser(newValues);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        if (values.country_trigger === "MX") values.country = "";
        handleSubmit(values);
      }}
    >
      {({ values }) => (
        <Form>
          <div className="mb-5 text-gray-550">
            <p>Nombre completo</p>
            <Field
              name="name"
              className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-400 font-bold"
            />
          </div>

          <div className="mb-5 text-gray-550">
            <p>Correo</p>
            <input
              className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
              value={currentUser ? currentUser.email : ""}
              readOnly
            />
          </div>

          <div className="mb-5 text-gray-550">
            <p>Teléfono</p>
            <Field
              name="phone"
              className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-5 w-full">
            <p className="text-gray-550 w-full mb-1">Fecha de nacimiento</p>
            <Field
              name="birthdate"
              render={({ field, form }) => (
                <div className="relative mb-2 w-full">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <div className="w-full">
                      <DatePicker
                        {...field}
                        value={field.value}
                        onChange={(date) => {
                          form.setFieldValue("birthdate", date);
                        }}
                        format="dd/MM/yyyy"
                        disableFuture
                        className="timepickerUserProfile w-full"
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              )}
            />
            <ErrorMessage
              name="birthdate"
              component="div"
              className="text-red-400 font-bold font-xs"
            />
          </div>

          <div className="w-full flex flex-row space-x-2 mb-5">
            <div className="w-full">
              <p className="text-gray-550  text-xl">Género</p>
              <Field
                name="gender"
                as="select"
                className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
              >
                <option value="">Seleccione</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </Field>
              <ErrorMessage
                name="gender"
                component="div"
                className="text-red-400 font-bold font-xs"
              />
            </div>

            <div className="w-full">
              <p className="text-gray-550 text-md">Nivel de estudios</p>
              <Field
                name="education_level"
                as="select"
                className="w-full bg-white text-gray-550 border border-slate-300 py-3 mt-1 px-4 focus:outline-none"
              >
                <option value="">Seleccione...</option>
                <option value="Primaria">Primaria</option>
                <option value="Secundaria">Secundaria</option>
                <option value="Preparatoria">Preparatoria</option>
                <option value="Universidad">Universidad</option>
              </Field>
              <ErrorMessage
                name="education_level"
                component="div"
                className="text-red-400 font-bold font-xs"
              />
            </div>
          </div>

          <div className="mb-5 text-gray-550">
            <p>País</p>
            <Field
              as="select"
              name="country_trigger"
              className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
            >
              <option value="MX">México</option>
              <option value="OTHER">Otro país</option>
            </Field>
            <ErrorMessage
              name="country_trigger"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          {values.country_trigger === "OTHER" && (
            <div className="mb-5 text-gray-550">
              <Field
                name="country"
                className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
                placeholder="Escriba el nombre de su país"
                type="text"
              />
              <ErrorMessage
                name="country"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>
          )}

          <div className="mb-5 text-gray-550">
            <p>Estado</p>
            {values.country_trigger === "OTHER" ? (
              <Field
                name="state"
                className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
                type="text"
              />
            ) : (
              <Field
                as="select"
                name="state"
                className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
              >
                {Object.keys(mexicoStates).map((key) => (
                  <option key={key} value={mexicoStates[key]}>
                    {mexicoStates[key]}
                  </option>
                ))}
              </Field>
            )}
            <ErrorMessage
              name="state"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-5 text-gray-550">
            <p>Ciudad</p>
            <Field
              name="city"
              className="w-full bg-white text-gray-550 border border-slate-300 py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="city"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="w-full md:w-1/2 lg:w-1/3 bg-primary-new text-white font-bold text-2xl py-3 disabled:opacity-75 rounded-full"
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon="circle-notch" spin />
              ) : (
                <span>Actualizar</span>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default General;
