/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { profileService } from "../../../services/api/profile";

import Context from "../../../context/UserContext";
import mexicoStates from "../../../utils/mexicoStates";
import { notifications } from "../../../utils/notifications";

const MySwal = withReactContent(Swal);

const EditAddress = () => {
  const regex = /^(?:\+?\d+\s?)?(?:\(\d+\)|\d+)(?:-?\s?\d+)*$/;
  const formSchema = Yup.object().shape({
    street_and_number: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    colony: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    city: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    state: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    country_trigger: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    country: Yup.string().when("country_trigger", {
      is: (val) => val === "OTHER",
      then: Yup.string().required("Campo requerido"),
    }),
    postal_code: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    phone: Yup.string()
      .required("Campo requerido")
      .max(10, `Máximo 10 caracteres`)
      .matches(new RegExp(regex), "Ingrese un número de teléfono válido"),
    receives: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    sender_phone: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    sender_name: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    reference: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
  });

  const [initialValues, setInitialValues] = useState({
    street_and_number: "",
    colony: "",
    city: "",
    state: mexicoStates["AGU"],
    country: "",
    country_trigger: "MX",
    postal_code: "",
    phone: "",
    receives: "",
    sender_phone: "",
    sender_name: "",
    reference: "",
  });

  const navigate = useNavigate();
  const params = useParams();
  const { currentUser } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState({});

  const fetchAddress = async () => {
    await profileService
      .getAddress(params.id)
      .then((response) => {
        setAddress(response.data.address);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchAddress();
  }, [currentUser]);

  useEffect(() => {
    setInitialValues({
      street_and_number: address.street_and_number || "",
      colony: address.colony || "",
      city: address.city || "",
      state: address.state || mexicoStates["AGU"],
      country: countryValidation(address.country),
      country_trigger: countryTriggerValidation(address.country),
      postal_code: address.postal_code || "",
      phone: address.phone || "",
      receives: address.receives || "",
      sender_phone: address.sender_phone || "",
      sender_name: address.sender_name || "",
      reference: address.reference || "",
    });
  }, [address]);

  const countryValidation = (country) => {
    if (!country) return "";
    return country !== "México" ? country : "";
  };

  const countryTriggerValidation = (country) => {
    if (!country) return "MX";
    return country === "México" ? "MX" : "OTHER";
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let country = values.country === "" ? "México" : values.country;

    delete values.country_trigger;
    let newValues = { ...values, country };

    await profileService
      .updateAddress(params.id, newValues)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/mi-perfil/direcciones");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    MySwal.fire({
      icon: "error",
      title: "¿Deseas eliminar está dirección?",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#42a819",
      confirmButtonText: "Eliminar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await profileService
          .deleteAddress(params.id)
          .then((response) => {
            notifications.success(response.data.message);
            navigate("/mi-perfil/direcciones");
          })
          .catch((err) => {});
      }
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        if (values.country_trigger === "MX") values.country = "";
        handleSubmit(values);
      }}
    >
      {({ values }) => (
        <Form>
          <p className="text-gray-700 font-bold text-center lg:text-left text-xl lg:text-2xl pb-2 lg:pb-5">
            Editar dirección
          </p>
          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Persona quien recibe</p>
            <Field
              name="receives"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="receives"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Teléfono de quien recibe</p>
            <Field
              name="phone"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Calle y número</p>
            <Field
              name="street_and_number"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="street_and_number"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Referencia</p>
            <Field
              name="reference"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="reference"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Colonia</p>
            <Field
              name="colony"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="colony"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>País</p>
            <Field
              as="select"
              name="country_trigger"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
            >
              <option value="MX">México</option>
              <option value="OTHER">Otro país</option>
            </Field>
            <ErrorMessage
              name="country_trigger"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          {values.country_trigger === "OTHER" && (
            <div className="mb-9 text-xl lg:text-2xl text-gray-550">
              <Field
                name="country"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                placeholder="Escriba el nombre de su país"
                type="text"
              />
              <ErrorMessage
                name="country"
                component="div"
                className="text-red-400 font-bold text-xl"
              />
            </div>
          )}

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Estado</p>
            {values.country_trigger === "OTHER" ? (
              <Field
                name="state"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
                type="text"
              />
            ) : (
              <Field
                as="select"
                name="state"
                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              >
                {Object.keys(mexicoStates).map((key) => (
                  <option key={key} value={mexicoStates[key]}>
                    {mexicoStates[key]}
                  </option>
                ))}
              </Field>
            )}
            <ErrorMessage
              name="state"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Ciudad</p>
            <Field
              name="city"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="city"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Código postal</p>
            <Field
              name="postal_code"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="postal_code"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Remitente</p>
            <Field
              name="sender_name"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="sender_name"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="mb-4 lg:mb-9 text-xl lg:text-2xl text-gray-550">
            <p>Teléfono del remitente</p>
            <Field
              name="sender_phone"
              className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-1 lg:py-3 px-4 focus:outline-none"
              type="text"
            />
            <ErrorMessage
              name="sender_phone"
              component="div"
              className="text-red-400 font-bold text-xl"
            />
          </div>

          <div className="flex flex-col gap-x-5 md:flex-row justify-between">
            <button
              type="button"
              className="my-2 w-full md:w-1/2 lg:w-1/3 order-2 md:order-1 bg-tertiary text-white font-bold text-xl py-2 lg:py-3 disabled:opacity-75 rounded-full"
              disabled={isLoading}
              onClick={() => handleDelete()}
            >
              <span>Eliminar</span>
            </button>
            <button
              type="submit"
              className="my-2 w-full md:w-1/2 lg:w-1/3 order-1 md:order-2 bg-primary-new text-white font-bold text-xl py-2 lg:py-3 disabled:opacity-75 rounded-full"
              disabled={isLoading}
            >
              {isLoading ? (
                <FontAwesomeIcon icon="circle-notch" spin />
              ) : (
                <span>Guardar</span>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAddress;
