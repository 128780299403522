import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Context from "../../../context/AdminUserContext";
import TableLoading from "../../../components/TableLoading";
import TableNoData from "../../../components/TableNoData";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";
import {
  getColumnsForOrders,
  getFormattedDate,
  customStyles,
  paginationComponentOptions,
  getColumnsForSuperAdminOrders,
} from "../../../utils/tableOptions";
import OrderStatusComponent from "../../../components/admin/OrderStatusComponent";

const Index = () => {
  const { currentAdminUser } = useContext(Context);

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("none");

  const fetchOrders = () => {
    ShopifyAPIService.getOrders()
      .then((response) => {
        setOrders(response.data.orders);
        setFilteredOrders(response.data.orders);
        const uniqueVendors = [
          ...new Set(response.data.orders.map((order) => order.vendor)),
        ];
        setVendors(uniqueVendors);
      })
      .catch((err) => {});
  };

  const filteredData = formattedOrders.filter((order) => {
    if (selectedVendor === "none") {
      return order;
    } else {
      console.log(selectedVendor);
      return order.vendorSelect === selectedVendor;
    }
  });

  const formatOrdersData = () => {
    let data = [];

    let dataColorsStatus = {
      AwaitingPayment: {
        class: "text-gray-700",
        text: "En espera de pago",
      },
      Payment: {
        class: "text-secondary",
        text: "Pagado",
      },
      ConfirmPayment: {
        class: "text-green-50",
        text: "Pago confirmado",
      },
      DeniedPayment: {
        class: "text-tertiary",
        text: "Pago rechazado",
      },
      Send: {
        class: "text-gray-700",
        text: "Enviado",
      },
      Finish: {
        class: "text-primary-200",
        text: "Completado", // Finalizado
      },
    };

    filteredOrders.forEach((order, idx) => {
      data.push({
        vendorSelect: order.vendor,
        vendor: (
          <span className="font-bold text-primary-200">{order.vendor}</span>
        ),
        tag: order.isDropshipping ? (
          <span className="bg-green-50 text-white text-xs font-bold me-2 px-2.5 py-0.5 rounded">
            Dropshipping
          </span>
        ) : (
          <span className="bg-primary-200 text-white text-xs font-bold me-2 px-2.5 py-0.5 rounded">
            Be Global Pro
          </span>
        ),
        order: <span className="font-bold text-primary-200">{order.id}</span>,
        date: getFormattedDate(order.created_at),
        client: order.user_name,
        status: (
          <span
            className={`font-bold	 ${
              order.status
                ? dataColorsStatus[order.status].class
                : "text-gray-700"
            }`}
          >
            {order.status ? dataColorsStatus[order.status].text : "---"}
          </span>
        ),
        detailsComponent: (
          <Link
            to={`/admin/ordenes/${order.id}`}
            className="w-full text-primary-200 hover:text-primary-300 text-center text-sm lg:text-base"
          >
            Ver detalles
          </Link>
        ),
        actionComponent: (
          <OrderStatusComponent order={order} fetchOrders={fetchOrders} />
        ),
      });
    });

    setFormattedOrders(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchOrders();
  }, [currentAdminUser]);

  useEffect(() => {
    // if (_.isEmpty(orders)) return;
    formatOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  const handleVendorChange = (event) => {
    const option = event.target.value;
    setSelectedVendor(option);
  };

  return (
    <div className="container mx-auto text-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-4xl font-bold">Ordenes</p>
      </div>
      {currentAdminUser && currentAdminUser.type === "SuperAdmin"}
      <div className="flex flex-wrap md:flex-row mt-5 mb-10 space-x-4">
        <div className="w-auto py-2 flex  flex-wrap md:flex-row space-x-2 justify-items-center text-center space-y-4 lg:space-y-0">
          <div className="my-auto w-full md:w-auto">Filtrar por :</div>
          <select
            onChange={handleVendorChange}
            value={selectedVendor}
            name="select"
            className="w-full md:w-auto bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
          >
            <option value="none" selected>
              Todas las bodegas
            </option>
            {vendors.map((vendor) => (
              <option value={vendor} selected>
                {vendor}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="border border-gray-350 mt-12">
        <DataTable
          columns={
            currentAdminUser.type === "SuperAdmin"
              ? getColumnsForSuperAdminOrders
              : getColumnsForOrders
          }
          data={filteredData}
          noDataComponent={<TableNoData />}
          progressPending={loading}
          progressComponent={<TableLoading />}
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
    </div>
  );
};

export default Index;
