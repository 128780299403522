import Moment from "react-moment";
import logo from "../assets/images/logo-bgp-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer({ theme = "dark" }) {
  return (
    <footer
      className={`${
        theme === "dark" ? "bg-[#1F2A4C]" : "bg-white"
      } px-8 py-12 md:p-16 lg:p-20 xl:p-24 2xl:p-28`}
    >
      <div className="flex flex-col lg:flex-row ">
        <div className="flex flex-col items-center lg:items-start lg:w-1/2">
          <img src={logo} width={205} alt="Be Global Pro" className="mb-8" />

          <div className="flex items-center justify-center">
            <div className="flex flex-col gap-2 items-center lg:items-start">
              <div className="flex gap-2 justify-center lg:justify-start">
                <a
                  href="https://www.facebook.com/beglobalpro"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-10 h-10 bg-[#394875] rounded-full"
                >
                  <FontAwesomeIcon
                    className="w-6 h-6 text-gray-200"
                    icon={["fab", "facebook"]}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/Beglobalpromx?t=xM7S-RIKKw2d9IZ1KoD50Q&s=09"
                  className="flex items-center justify-center w-10 h-10 bg-[#394875] rounded-full"
                >
                  <FontAwesomeIcon
                    className="w-6 h-6 text-gray-200"
                    icon={["fab", "twitter"]}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/beglobalpro/"
                  className="flex items-center justify-center w-10 h-10 bg-[#394875] rounded-full"
                >
                  <FontAwesomeIcon
                    className="w-6 h-6 text-gray-200"
                    icon={["fab", "instagram"]}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.me/beglobalproinfo"
                  className="flex items-center justify-center w-10 h-10 bg-[#394875] rounded-full"
                >
                  <FontAwesomeIcon
                    className="w-6 h-6 text-gray-200"
                    icon={["fab", "telegram"]}
                  />
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=+526622873165&text=%C2%A1Quiero%20saber%20m%C3%A1s%20informaci%C3%B3n%20de%20Be%20Global%20Pro!"
                  className="flex items-center justify-center w-10 h-10 bg-[#394875] rounded-full"
                >
                  <FontAwesomeIcon
                    className="w-6 h-6 text-gray-200"
                    icon={["fab", "whatsapp"]}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tiktok.com/@academiaecommerce?is_from_webapp=1&sender_device=pc"
                  className="flex items-center justify-center w-10 h-10 bg-[#394875] rounded-full"
                >
                  <FontAwesomeIcon
                    className="w-6 h-6 text-gray-200"
                    icon={["fab", "tiktok"]}
                  />
                </a>
              </div>

              <div className="mt-3">
                <p className="text-white text-sm text-center lg:text-left">
                  Síguenos en nuestras redes sociales y aprende <br /> todos los
                  días algo nuevo sobre el comercio electrónico.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-1/2 mx-auto py-8 lg:py-0">
          <div className="flex w-full flex-col items-center space-y-6 lg:flex-row lg:justify-end lg:space-x-8 lg:space-y-0 lg:mt-3 text-white text-sm">
            <a href="/blog">Blog</a>
            <a href="/membresias">Membresias</a>
            <a href="/tienda">Tienda</a>
            <a href="/cursos">Cursos</a>
          </div>
        </div>
      </div>

      <hr className="border-white my-8 hidden lg:block" />

      <div className="flex justify-center lg:justify-end">
        <p className="text-white text-sm text-center lg:text-right px-4 md:px-0">
          Todos los derechos reservados -{" "}
          <Moment format="YYYY">{new Date()}</Moment>{" "}
          <span className="hidden lg:inline"> | </span>
          <br className="lg:hidden" />
          <a href="/politicas" className="underline">
            Politicas de privacidad
          </a>
        </p>
      </div>
    </footer>
  );
}
