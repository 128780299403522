import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const StoreLayout = () => {
  return (
    <div className="relative">
      <NavBar />

      {/* HEADER */}

      <Outlet />

      <Footer />
    </div>
  );
};

export default StoreLayout;
