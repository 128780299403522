import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../assets/images/logo-globe-gbp.png";
import visaIcon from "../assets/icons/visa-icon.svg";
import mastercardIcon from "../assets/icons/mastercard-icon.svg";

const ShoppingCart = () => {
  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
      <div className="">
        <div className="h-20 flex items-center">
          <img src={logo} alt="BeGlobalPro" className="h-9 mr-2" />
          <p className=" text-gray-700">
            <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
            <span className="text-gray-700">Carrito de compras</span>
          </p>
        </div>
      </div>

      <div className="pt-16 text-gray-700">
        <p className="pb-8 text-4xl font-bold text-center md:text-left">
          Método de pago
        </p>
        <div className="w-full border-2 border-gray-50 mt-5">
          <div className="py-8 flex flex-row items-center border-b-2 border-gray-50">
            <div>
              <input
                type="radio"
                id="topping"
                name="topping"
                value="Paneer"
                className="ml-8 w-8 h-8 b-16 border-2"
              />{" "}
            </div>
            <div className="ml-4 md:ml-8">
              <div className="bg-[#EFEFEF] rounded-full w-20 h-20 mx-auto flex items-center justify-center">
                <img src={visaIcon} alt="VISA" />
              </div>
            </div>
            <div className="ml-4 md:ml-8 flex flex-col md:flex-row text-xl md:text-2xl">
              <p>BBVA</p>
              <p className="md:ml-3 text-base md:text-2xl">
                **** **** **** 6859
              </p>
            </div>
          </div>

          <div className=" py-8 items-center flex flex-row border-b-2 border-gray-50">
            <div>
              <input
                type="radio"
                id="topping"
                name="topping"
                value="Paneer"
                className="ml-8 w-8 h-8 b-16 border-2"
              />{" "}
            </div>
            <div className="ml-4 md:ml-8">
              <div className="bg-[#EFEFEF] rounded-full w-20 h-20 mx-auto flex items-center justify-center">
                <img src={mastercardIcon} alt="MASTERCARD" />
              </div>
            </div>{" "}
            <div className="ml-4 md:ml-8 flex flex-col md:flex-row text-xl md:text-2xl">
              <p>Banamex</p>
              <p className="md:ml-3 text-base md:text-2xl">
                **** **** **** 4567
              </p>
            </div>
          </div>

          <div className=" py-8 items-center flex flex-row border-b-2 border-gray-50">
            <div>
              <input
                type="radio"
                id="topping"
                name="topping"
                value="Paneer"
                className="ml-8 w-8 h-8 b-16 border-2"
              />{" "}
            </div>
            <div className="ml-4 md:ml-8">
              <div className="bg-[#EFEFEF] rounded-full w-20 h-20 flex items-center justify-center">
                <FontAwesomeIcon
                  icon={["far", "credit-card"]}
                  size="2x"
                  className="text-primary-new"
                />
              </div>
            </div>
            <div className="ml-4 md:ml-8 text-xl md:text-2xl">
              Nueva tarjeta de débito
            </div>
          </div>

          <div className="py-8 items-center flex flex-row">
            <div>
              <input
                type="radio"
                id="topping"
                name="topping"
                value="Paneer"
                className="ml-8 w-8 h-8 b-16 border-2"
              />{" "}
            </div>
            <div className="ml-4 md:ml-8">
              <div className="bg-[#EFEFEF] rounded-full w-20 h-20 flex items-center justify-center">
                <FontAwesomeIcon
                  icon={["far", "credit-card"]}
                  size="2x"
                  className="text-primary-new"
                />
              </div>
            </div>{" "}
            <div className="ml-4 md:ml-8 text-xl md:text-2xl">
              Nueva tarjeta de crédito
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full mt-10">
          <button
            className="bg-primary-new text-white py-4 px-4 w-full md:w-1/3 lg:w-1/5 text-2xl font-bold rounded-full"
            href="/data"
          >
            Continuar
          </button>
        </div>
      </div>
    </main>
  );
};

export default ShoppingCart;
