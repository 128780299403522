import { useEffect, useState } from "react";
import * as _ from "lodash";
import { Doughnut } from "react-chartjs-2";
import {
  optionsDoughnutSimpleNoAspectRatio,
  optionsDoughnutWithCurrencyNoAspectRatio,
} from "../../../utils/chartsOptions";

const SalesAndCountsCommissions = ({
  comissionsChart,
  dataComissions,
  soldCountChart,
  dataSalesCount,
  comissionsProductsChart,
  soldCountProductsChart,
}) => {
  const [finalDataComissions, setFinalDataComissions] = useState({});
  const [finalDataSalesCount, setFinalDataSalesCount] = useState({});

  useEffect(() => {
    const _dataComissions = _.cloneDeep(dataComissions);
    _dataComissions.datasets[0].data[
      _dataComissions.datasets[0].data.length - 1
    ] = comissionsProductsChart;
    _dataComissions.datasets[0].backgroundColor.push("rgba(255, 50, 0, 0.7)");
    _dataComissions.labels.push("Productos");

    const _dataSalesCount = _.cloneDeep(dataSalesCount);
    _dataSalesCount.datasets[0].data[
      _dataSalesCount.datasets[0].data.length - 1
    ] = soldCountProductsChart;
    _dataSalesCount.datasets[0].backgroundColor.push("rgba(255, 50, 0, 0.7)");
    _dataSalesCount.labels.push("Productos");

    setFinalDataComissions(_dataComissions);
    setFinalDataSalesCount(_dataSalesCount);
  }, [
    comissionsProductsChart,
    soldCountProductsChart,
    dataComissions,
    dataSalesCount,
  ]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-20 text-center mt-10 space-y-5 md:space-y-0">
      {!_.isEmpty(finalDataComissions) && (
        <div
          className={`${
            comissionsChart.every((item) => item === 0) &&
            comissionsProductsChart === 0
              ? "h-auto"
              : "h-96"
          }`}
        >
          <p className="text-3xl font-bold text-gray-700 mb-5">Comisiones</p>
          {comissionsChart.every((item) => item === 0) &&
          comissionsProductsChart === 0 ? (
            <p className="text-primary-200 font-bold">SIN VENTAS</p>
          ) : (
            <Doughnut
              data={finalDataComissions}
              options={optionsDoughnutWithCurrencyNoAspectRatio}
            />
          )}
        </div>
      )}

      {!_.isEmpty(finalDataSalesCount) && (
        <div
          className={`${
            comissionsChart.every((value) => value === 0) &&
            comissionsProductsChart === 0
              ? "h-auto"
              : "h-96"
          }`}
        >
          <p className="text-3xl font-bold text-gray-700 mb-5">Vendidas</p>
          {soldCountChart.every((item) => item === 0) &&
          soldCountProductsChart === 0 ? (
            <p className="text-primary-200 font-bold">SIN VENTAS</p>
          ) : (
            <Doughnut
              data={finalDataSalesCount}
              options={optionsDoughnutSimpleNoAspectRatio}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SalesAndCountsCommissions;
