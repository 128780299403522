/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field, FieldArray } from "formik";
import { ShopifyAPIService } from "../../../services/api/admin/shopify";
import { notifications } from "../../../utils/notifications";
import UploadImgVariantModal from "../../../components/Modals/admin/UploadImgVariantModal";
import UploadImgVariantContent from "../../../components/Modals/admin/UploadImgVariantContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";

import FilesDragAndDrop from "../../../components/FilesDragAndDropNewProducts";

const NewProduct = () => {
  const navigate = useNavigate();
  const [showPublish, setShowPublish] = useState(false);
  const [currentVariant] = useState();
  const [loading, setLoading] = useState(false);
  const url = window.location.origin;
  const [allFiles, setAllFiles] = useState([]);
  const [oldFiles] = useState([]);

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const formSchema = Yup.object().shape({
    title: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    body_html: Yup.string().required("Campo requerido"),
    image: Yup.mixed()
      .nullable()
      .required()
      .test(
        "fileFormat",
        "Formato no soportado",
        (value) =>
          !value || ((value) => value && SUPPORTED_FORMATS.includes(value.type))
      ),
    product_type: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    tags: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    status: Yup.string()
      .required("Campo requerido")
      .max(255, `Máximo 255 caracteres`),
    options: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Campo requerido"),
        })
      )
      .test(
        "uniqueNames",
        "Los nombres de las opciones deben ser únicos",
        (options) => {
          const names = options.map((option) => option.name);
          const uniqueNames = new Set(names);
          return names.length === uniqueNames.size;
        }
      ),
  });

  const initialValues = {
    title: "",
    body_html: "",
    image: null,
    product_type: "",
    tags: "",
    status: "active",
    variants: [
      {
        option1: "",
        price: "",
        compare_at_price: "",
        sku: "",
        inventory_quantity: "",
      },
    ],
    options: [],
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let bodyFormData = new FormData();

    bodyFormData.append("_method", "POST");
    if (values.image.length > 0) {
      values.image.forEach(function (image, i) {
        bodyFormData.append("images[]", values.image[i]);
      });
    }
    bodyFormData.append("title", values.title);
    bodyFormData.append("body_html", values.body_html);
    bodyFormData.append("product_type", values.product_type);
    bodyFormData.append("tags", values.tags);
    bodyFormData.append("status", values.status);

    let arrayOptions = [];
    values.options.forEach((option) => {
      arrayOptions.push({
        name: option.name,
        values: [],
      });
    });

    values.variants.forEach((variant) => {
      variant.inventory_management = "shopify";

      if (
        variant.option1 !== null &&
        variant.option1 !== "" &&
        !arrayOptions[0]?.values.includes(variant.option1)
      ) {
        arrayOptions[0]?.values.push(variant.option1);
      }
      if (
        variant.option2 !== null &&
        variant.option2 !== "" &&
        !arrayOptions[1]?.values.includes(variant.option2)
      ) {
        arrayOptions[1]?.values.push(variant.option2);
      }
      if (variant.option3 !== null && variant.option3 !== "") {
        arrayOptions[2]?.values.push(variant.option3);
      }
    });

    bodyFormData.append("variants", JSON.stringify(values.variants));
    bodyFormData.append("options", JSON.stringify(arrayOptions));

    await ShopifyAPIService.uploadProduct(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        navigate("/admin/productos");
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilesChange = (files, setFieldValue) => {
    if (!_.isEmpty(files)) {
      setAllFiles(files);
      setFieldValue("image", files);
    }
  };

  return (
    <>
      <div className="container mx-auto text-gray-700">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-4xl font-bold">Nuevo Producto</p>
        </div>
        <div className="text-gray-700 lg:text-xl text-sm py-2 my-5">
          Nota:
          <br></br>
          No olvides llenar la información de pago la cual puedes encontrar en
          la sección de{" "}
          <a
            href={`${url}/admin/mi-perfil`}
            rel="noreferrer"
            target="_blank"
            className={"text-primary-200 cursor-pointer font-bold"}
          >
            Mi Perfil.
          </a>
          <br></br> Recuerda agregar al menos un envío en la sección de{" "}
          <a
            href={`${url}/admin/envios`}
            rel="noreferrer"
            target="_blank"
            className={"text-primary-200 cursor-pointer font-bold"}
          >
            Envíos.
          </a>
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="w-full">
            <Formik
              enableReinitialize={false}
              initialValues={initialValues}
              validationSchema={formSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                    <p>Título</p>
                    <Field
                      name="title"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                      type="text"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>

                  <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                    <p>Descripción</p>
                    <Field
                      as="textarea"
                      name="body_html"
                      className="w-full h-24 md:h-32 lg:h-44 border border-slate-300 text-gray-700 placeholder:text-gray-700 py-3 px-4 resize-none focus:outline-none"
                    />
                    <ErrorMessage
                      name="body_html"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>

                  <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                    <p>Media</p>
                    <div>
                      <FilesDragAndDrop
                        text={true}
                        onFileChange={(files) =>
                          onFilesChange(files, setFieldValue)
                        }
                        multiple={true}
                        showFiles={true}
                        oldFiles={oldFiles}
                      />
                    </div>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>

                  <div className="mb-9 text-2xl text-gray-700 w-full lg:w-2/3">
                    <div className="mt-5">
                      <div className="grid grid-cols-1">
                        <div>
                          <p>Tipo de producto</p>
                          <Field
                            name="product_type"
                            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                            type="text"
                          />
                          <ErrorMessage
                            name="product_type"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 mt-5">
                        <div>
                          <p>Etiquetas (Separar por comas)</p>
                          <Field
                            name="tags"
                            className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                            type="text"
                          />
                          <ErrorMessage
                            name="tags"
                            component="div"
                            className="text-red-400 font-bold text-xl"
                          />
                        </div>
                      </div>

                      <div className="my-5 text-3xl font-bold flex flex-col text-left mt-10">
                        <div className="">Opciones</div>

                        {errors.options && values?.options && (
                          <div className="text-red-400 font-bold text-lg lg:text-xl my-auto">
                            {Array.isArray(errors.options)
                              ? errors.options.map((err, index) => (
                                  <div key={index}>
                                    {err.name === "Campo requerido"
                                      ? "Opciones requeridas"
                                      : err.name}
                                  </div>
                                ))
                              : errors.options === "Campo requerido"
                              ? "Opciones requeridas"
                              : errors.options}
                          </div>
                        )}
                      </div>

                      {
                        <FieldArray name="options">
                          {({ insert, remove, push }) => (
                            <div>
                              {values?.options.length > 0 &&
                                values.options.map((option, index) => (
                                  <div
                                    className="flex flex-row items-center"
                                    key={index}
                                  >
                                    <div className="text-left mt-3">
                                      <p>Nombre</p>
                                      <div className="flex justify-center items-center space-x-4">
                                        <Field
                                          name={`options[${index}].name`}
                                          className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                          type="text"
                                        />
                                        <div className="flex justify-center items-center h-full">
                                          {values.options.length > 1 && (
                                            <button
                                              type="button"
                                              className=""
                                              onClick={() => {
                                                remove(index);
                                                values.variants.forEach(
                                                  (variant, variantIndex) => {
                                                    switch (index) {
                                                      case 0:
                                                        values.variants[
                                                          variantIndex
                                                        ].option1 = "";
                                                        break;
                                                      case 1:
                                                        values.variants[
                                                          variantIndex
                                                        ].option2 = "";
                                                        break;
                                                      case 2:
                                                        values.variants[
                                                          variantIndex
                                                        ].option3 = "";
                                                        break;
                                                      default:
                                                        break;
                                                    }
                                                  }
                                                );
                                              }}
                                            >
                                              X
                                            </button>
                                          )}
                                        </div>
                                      </div>

                                      {errors.options && (
                                        <ErrorMessage
                                          name={`options[${index}].name`}
                                          component="div"
                                          className="text-red-400 font-bold text-xl"
                                        />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              {values?.options.length < 3 && (
                                <button
                                  type="button"
                                  className="w-full md:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75 mt-5 md:mt-10"
                                  onClick={() => push({})}
                                >
                                  Añadir opción
                                </button>
                              )}
                            </div>
                          )}
                        </FieldArray>
                      }

                      <p className="mt-10 mb-5 text-3xl font-bold">Variantes</p>
                      {errors.variants && values?.variants && (
                        <div className="text-red-400 font-bold text-lg lg:text-xl my-auto">
                          {Array.isArray(errors.variants) ? (
                            errors.variants.map((err, index) => (
                              <div key={index}>
                                {err.option1 && (
                                  <div>La opción 1 es requerida</div>
                                )}
                                {err.option2 && (
                                  <div>La opción 2 es requerida</div>
                                )}
                                {err.option3 && (
                                  <div>La opción 3 es requerida</div>
                                )}
                                {err.uniqueVariantOptions && (
                                  <div>
                                    Las variantes no pueden tener opciones
                                    duplicadas
                                  </div>
                                )}
                                {err.maxVariants && (
                                  <div>
                                    Solo se pueden agregar hasta 3 variantes
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div>{errors.variants}</div>
                          )}
                        </div>
                      )}

                      <FieldArray name="variants">
                        {({ insert, remove, push }) => (
                          <div>
                            {values.variants.length > 0 &&
                              values.variants.map((variant, index) => (
                                <div
                                  className="grid grid-cols-1 md:grid-cols-[1fr_1fr_1fr_auto] place-items-center mt-10 gap-5 text-center"
                                  key={index}
                                >
                                  {values.options.length > 0 &&
                                    values.options.map(
                                      (option, indexOption) => (
                                        <>
                                          {option.name ? (
                                            <div>
                                              <p>{option.name}</p>
                                              <Field
                                                name={`variants.${index}.option${
                                                  indexOption + 1
                                                }`}
                                                className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                                type="text"
                                              />
                                              <ErrorMessage
                                                name={`variants.${index}.option${
                                                  indexOption + 1
                                                }`}
                                                component="div"
                                                className="text-red-400 font-bold text-xl"
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )
                                    )}
                                  <div>
                                    <p className="text-2xl md:text-xl md:mt-1 lg:text-2xl lg:mt-0">
                                      Precio
                                    </p>
                                    <Field
                                      name={`variants.${index}.price`}
                                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                      type="number"
                                    />
                                    <ErrorMessage
                                      name={`variants.${index}.price`}
                                      component="div"
                                      className="text-red-400 font-bold text-xl"
                                    />
                                  </div>
                                  <div>
                                    <p className="text-2xl md:text-xl md:mt-1 lg:text-2xl lg:mt-0">
                                      Precio (No Miembros)
                                    </p>
                                    <Field
                                      name={`variants.${index}.compare_at_price`}
                                      className="w-full lg:w-auto bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-2 focus:outline-none"
                                      type="number"
                                    />
                                    <ErrorMessage
                                      name={`variants.${index}.compare_at_price`}
                                      component="div"
                                      className="text-red-400 font-bold text-xl"
                                    />
                                  </div>
                                  <div>
                                    <p className="text-2xl md:text-xl md:mt-1 lg:text-2xl lg:mt-0">
                                      Cantidad
                                    </p>
                                    <Field
                                      name={`variants.${index}.inventory_quantity`}
                                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                                      type="text"
                                    />
                                    <ErrorMessage
                                      name={`variants.${index}.inventory_quantity`}
                                      component="div"
                                      className="text-red-400 font-bold text-xl"
                                    />
                                  </div>
                                  <div className="flex justify-center items-center md:pt-8">
                                    {values.variants.length > 1 && (
                                      <button
                                        type="button"
                                        className=""
                                        onClick={() => remove(index)}
                                      >
                                        X
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                            <button
                              type="button"
                              className="w-full md:w-1/3 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75 mt-5 md:mt-10"
                              onClick={() =>
                                push({ option1: "", price: "", sku: "" })
                              }
                            >
                              Añadir variante
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>

                  <div className="text-2xl mt-10 w-full md:w-1/2 lg:w-3/12">
                    <p className="pb-2">Estatus</p>
                    <Field
                      as="select"
                      name="status"
                      className="w-full bg-white text-gray-700 placeholder:font-italitc border border-slate-300 py-3 px-4 focus:outline-none"
                    >
                      <option value="active">Activo</option>
                      <option value="draft">Borrador</option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="text-red-400 font-bold text-xl"
                    />
                  </div>

                  <div className="flex flex-row justify-end w-full mt-10 lg:mt-20">
                    <button
                      type="submit"
                      className="w-full md:w-1/3 lg:w-1/4 bg-primary-200 text-white font-bold text-2xl py-3 disabled:opacity-75 order-1 md:order-2"
                      disabled={loading}
                    >
                      {loading ? (
                        <FontAwesomeIcon icon="circle-notch" spin />
                      ) : (
                        <span>Guardar</span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <UploadImgVariantModal
        visible={showPublish}
        setVisible={setShowPublish}
        modalContent={
          <UploadImgVariantContent
            files={allFiles}
            currentVariant={currentVariant}
            setVisible={setShowPublish}
          />
        }
      />
    </>
  );
};

export default NewProduct;
