import React from "react";
import { useEffect, useContext, useState } from "react";
import Context from "../../../context/AdminUserContext";
import moment from "moment";
import LearningCard from "../../../components/statics/LearningCard";
import { adminCoursesServices } from "../../../services/api/admin/courses";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoursesInProgress from "../../../components/statics/CoursesInProgress";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

registerLocale("es", es);
const Statistics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [coursesFinished, setCoursesFinished] = useState([]);
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [courseInProgress, setCourseInProgress] = useState(0);
  const [coursesHours, setCoursesHours] = useState([]);
  const [coursesDays, setCoursesDays] = useState([]);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState("currentWeek");
  const currentYear = moment().year();
  const [labelMonth, setLabelMonth] = useState(
    moment().format("MMMM").charAt(0).toUpperCase() +
      moment().format("MMMM").slice(1).toLowerCase()
  );
  const [monthsPerYear, setMonthsPerYear] = useState(
    [...Array(new Date().getMonth() + 1)].map(
      (_, i) =>
        new Date(new Date().getFullYear(), i)
          .toLocaleString("default", { month: "long" })
          .charAt(0)
          .toUpperCase() +
        new Date(new Date().getFullYear(), i)
          .toLocaleString("default", { month: "long" })
          .slice(1)
    )
  );

  const [currentOption, setCurrentOption] = useState("0");
  const [user, setUser] = useState();
  const { currentAdminUser } = useContext(Context);
  const params = useParams();

  const options = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 20,
          },
        },
      },
      title: {},
      fontStyle: "bold",
    },
  };

  const dataDays = {
    labels,
    datasets: [
      {
        label: "Horas de estudio",
        data: data,
        borderColor: "#394875",
        backgroundColor: "#394875",
        pointRadius: 8,
      },
    ],
  };

  const learningArray = [
    {
      icon: "medal",
      title: "Cursos completados",
      count: coursesFinished,
    },
    {
      icon: ["far", "loader"],
      title: "Cursos en progreso",
      count: coursesInProgress,
    },
    {
      icon: ["far", "clock"],
      title: "Horas de estudio",
      count: coursesHours,
    },
  ];

  const setTimePerCourse = (array, option) => {
    if (option) setCurrentOption(option);
    let datesArray = [];
    array.forEach((element) => {
      if (option === element.uuid || option === "0") {
        setCourseInProgress(element.progress);
        element.timePerDay.forEach((date) => {
          datesArray.push(date);
        });
      }
    });
    changeDate(currentDate);
    return datesArray;
  };

  const fetchStatistics = () => {
    adminCoursesServices
      .getStatics(params.id)
      .then((response) => {
        setCourses(response.data.courses);
        setUser(response.data.user);
        setCoursesDays(setTimePerCourse(response.data.courses, "0"));
        setCoursesFinished(response.data.coursesFinished);
        setCoursesInProgress(response.data.coursesInProgress);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const fetchCoursesHours = () => {
    let totalHours = 0;
    if (coursesDays) {
      coursesDays.forEach((element) => {
        totalHours = totalHours + parseFloat(element.seconds);
      });
    }
    setCoursesHours(
      parseFloat((totalHours / 60 / 60).toFixed(1)).toLocaleString("en-US")
    );
  };
  const changeCourse = (value) => {
    setCoursesDays(setTimePerCourse(courses, value));
  };

  const changeDate = (option) => {
    setCurrentDate(option);
    setCurrentOption(option);
    const currentYear = moment().year();

    switch (option) {
      case "currentWeek":
        const startDay = moment().startOf("week");
        const endDay = moment().endOf("week");

        let weekDays = moment.weekdays();
        let objWeek = Object.fromEntries(weekDays.map((day) => [day, 0]));

        // Filtramos solo los datos del año actual y de la semana actual
        let everyWeek = coursesDays.filter((day) => {
          let dateMoment = moment(day.date);
          return (
            dateMoment.year() === currentYear &&
            dateMoment.isBetween(startDay, endDay, null, "[]")
          );
        });

        // Sumamos las horas por día de la semana
        everyWeek.forEach((element) => {
          let dayName = moment(element.date).format("dddd");
          objWeek[dayName] += parseFloat(element.seconds) / 3600;
        });

        setLabels(Object.keys(objWeek));
        setData(Object.values(objWeek));
        break;

      case "annual":
        let objYear = {};

        moment.monthsShort().forEach((month) => (objYear[month] = 0));

        // Filtramos solo los datos del año actual
        let everyYear = coursesDays.filter(
          (day) => moment(day.date).year() === currentYear
        );

        // Sumamos las horas por mes
        everyYear.forEach((element) => {
          let monthName = moment(element.date).format("MMM");
          objYear[monthName] += parseFloat(element.seconds) / 3600;
        });

        setLabels(Object.keys(objYear));
        setData(Object.values(objYear));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!currentAdminUser) return;
    fetchStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdminUser]);

  useEffect(() => {
    if (!currentAdminUser) return;
    if (coursesDays.length > 0 || currentOption) {
      changeDate(currentDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays, currentOption]);
  const LearningLineChart = ({ options, data }) => {
    return <Line options={options} data={data} />;
  };
  useEffect(() => {
    if (!currentAdminUser) return;
    fetchCoursesHours();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);

  useEffect(() => {
    if (!currentAdminUser) return;
    if (currentOption === "currentMonth") {
      const monthIndex =
        labelMonth === "Enero"
          ? 0
          : new Date(`${labelMonth} 1, 2022`).getMonth();

      const start = moment().month(monthIndex).startOf("month");
      const end = moment().month(monthIndex).endOf("month");

      const startOfMonth = start.clone().startOf("month");
      const endOfMonth = end.clone().endOf("month");

      console.log(startOfMonth);

      let objMonth = {};

      for (
        let current = startOfMonth.clone();
        current.isBefore(endOfMonth) || current.isSame(endOfMonth, "day");
        current.add(1, "day")
      ) {
        objMonth[current.format("D")] = 0;
      }

      const everyDay = coursesDays.filter((day) => {
        const dateMoment = moment(day.date);
        return (
          dateMoment.year() === currentYear && dateMoment.month() === monthIndex // Aquí usamos `monthIndex`, no el mes actual
        );
      });

      // Sumamos las horas por día del mes
      everyDay.forEach((element) => {
        const dayNumber = moment(element.date).format("D");
        objMonth[dayNumber] += parseFloat(element.seconds) / 3600;
      });

      // Establecemos los datos en los estados correspondientes
      setLabels(Object.keys(objMonth));
      setData(Object.values(objMonth));
    }
  }, [labelMonth, currentOption, coursesDays]);
  return (
    <>
      <div>
        <p className="text-center md:text-left text-2xl md:text-3xl text-gray-700 font-bold my-auto pb-5">
          Aprendizaje de <br className="md:hidden" />
          <span className="text-primary-200">{user && user.name}</span>
        </p>
        <div className="pb-5 flex flex-col md:flex-row md:items-center mx-auto lg:mx-0 md:space-x-4">
          <div className="w-full md:w-auto my-auto font-bold text-center md:text-left">
            Resumen de:
          </div>
          <select
            name="breakdown"
            onChange={(value) => changeCourse(value.target.value)}
            className="w-full md:w-auto mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none"
          >
            <option value="0">Todos los cursos</option>

            {courses &&
              courses.map((course) => (
                <option value={course.uuid}>{course.name}</option>
              ))}
          </select>
        </div>

        <div className="flex flex-col md:flex-row gap-10 ">
          <div className={`${currentOption === "0" && "lg:w-8/12"} w-full `}>
            {currentOption === "0" ? (
              <div className="lg:px-auto w-full border border-gray-350 px-8 py-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
                {learningArray.map((item, key) => (
                  <LearningCard key={key} item={item}></LearningCard>
                ))}
              </div>
            ) : (
              <div className="px-12 w-full border border-gray-350   flex flex-row flex-wrap py-4 ">
                <div className="mx-auto lg:mx-0">
                  <div className="bg-primary-200 w-12 h-12 rounded-full m-auto mr-4 ">
                    <FontAwesomeIcon
                      className="text-white text-center mx-auto pt-3 flex justify-content"
                      icon={["far", "clock"]}
                      lib="far"
                    />
                  </div>
                </div>

                <div className="w-full lg:w-auto my-auto text-primary-700 font-bold flex lg:space-x-4 flex-wrap text-center lg:text-left mx-auto lg:mx-0">
                  <div className="text-center lg:text-left  mx-auto lg:mx-0 flex">
                    Progreso{" "}
                    {Number.isInteger(courseInProgress)
                      ? courseInProgress
                      : !isNaN(parseFloat(courseInProgress))
                      ? parseFloat(courseInProgress).toFixed(2)
                      : "0"}
                    % &nbsp; <b className="hidden lg:block"> - </b>
                  </div>
                  <div>
                    {parseFloat(coursesHours).toFixed(1)} Horas de estudio
                  </div>
                </div>
              </div>
            )}

            <div className={`w-full my-10 md:my-20`}>
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-10">
                <div className="w-full lg:w-1/2">
                  <p className="w-full lg:w-auto text-center md:text-left text-2xl lg:text-3xl text-gray-700 font-bold mb-5 md:mb-0">
                    Estadísticas
                  </p>
                </div>

                <div className="w-full lg:w-1/2 flex justify-end space-x-2">
                  <select
                    name="breakdown"
                    onChange={(e) => changeDate(e.target.value)}
                    value={currentOption}
                    className="mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none w-full md:w-auto "
                  >
                    <option value="currentWeek">Semanal</option>
                    <option value="currentMonth">Mensual</option>
                    <option value="annual">Anual</option>
                  </select>
                  {currentOption === "currentMonth" && (
                    <select
                      name="select"
                      onChange={(e) => {
                        setLabelMonth(e.target.value);
                      }}
                      value={labelMonth}
                      className="mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none w-full md:w-auto "
                    >
                      {monthsPerYear.map((month) => (
                        <option value={month}>{month}</option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div>
                <LearningLineChart
                  key={currentOption}
                  options={options}
                  data={dataDays}
                />
              </div>
            </div>
          </div>
          <div
            className={`${
              currentOption !== "0" && "hidden"
            } w-full lg:w-4/12  border border-gray-350 block`}
          >
            <CoursesInProgress
              isLoading={isLoading}
              courses={courses}
              label="Progreso"
            ></CoursesInProgress>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
