import appStorage from "./appStorage";
import { EMPRENDEDOR_VENDORS_WHITE_LIST, memberships } from "./memberships";

//===
// Public Helpers
//===
export const tokenLocalStorageKey = appStorage.createNamespaceKey("token");
export const adminTokenLocalStorageKey =
  appStorage.createNamespaceKey("admin_token");

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const toHHMMSS = (secs) => {
  const sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (hours === "00") return minutes + ":" + seconds;

  return hours + ":" + minutes + ":" + seconds;
};

export const validationPrice = (user, vendor) => {
  let allowedMembershipsId = [
    memberships.BASICA_DROPSHIPPING,
    memberships.PLUS_MENSUAL,
    memberships.PLUS_TRIMESTRAL,
    memberships.PLUS_ANUAL,
    memberships.PREMIUM,
    memberships.PLUS_SEMESTRAL,
    memberships.EMPRENDEDOR,
    memberships.INFINITE,
  ];
  if (!user) return false;

  if (
    !EMPRENDEDOR_VENDORS_WHITE_LIST.includes(vendor) &&
    user.membership_id === memberships.EMPRENDEDOR
  ) {
    return false;
  }

  if (
    !user.membership_id ||
    !user.membership_expired_at ||
    !allowedMembershipsId.includes(user.membership_id)
  )
    return false;

  const expiredAt = new Date(user.membership_expired_at);
  const currentDate = new Date();

  if (expiredAt < currentDate) return false;

  return true;
};
