import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LearningCard = ({ item, desktop }) => {
  return (
    <div className=" flex flex-row my-2">
      <div className="flex items-center">
        <div className="bg-primary-new-50 w-12 h-12 rounded-full mr-4">
          <FontAwesomeIcon
            className="text-white text-center mx-auto pt-3 flex justify-content"
            icon={item.icon}
            lib="far"
            size={`${desktop && "xl"}`}
          />
        </div>
      </div>

      <div className="">
        <div className="text-gray-new font-bold text-xl 2xl:text-2xl">
          {item.title}
        </div>
        <div className="text-xl 2xl:text-4xl font-bold">
          {" "}
          {Number.isInteger(item.count)
            ? item.count
            : !isNaN(parseFloat(item.count))
            ? parseFloat(item.count).toFixed(1)
            : "0"}
        </div>
      </div>
    </div>
  );
};

export default LearningCard;
