import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import topImage from "../assets/images/redesign/landing/top.jpeg";
import bottomImage from "../assets/images/redesign/landing/bottom.jpg";
import section2BoxSvg from "../assets/images/redesign/landing/box.svg";
import section2WorldSvg from "../assets/images/redesign/landing/world.svg";
import section2HandsSvg from "../assets/images/redesign/landing/hands.svg";
import videoPlaceholder from "../assets/video/placeholder.mp4";
import videoWebinar from "../assets/video/landing-webinar.mp4";
import testimonial1 from "../assets/video/testimonials/testimonial1.mp4";
import testimonial2 from "../assets/video/testimonials/testimonial2.mp4";
import testimonial3 from "../assets/video/testimonials/testimonial3.mp4";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthModal from "../components/AuthModal";
import NButton from "../components/buttons/NButton";
import LandingCard from "../components/cards/LandingCard";
import { shopifyService } from "../services/api/shopify";
import { coursesServices } from "../services/api/courses";
import CourseButton from "../components/courses/CourseButton";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import TableLoading from "../components/TableLoading";

export default function Index() {
  const { currentUser } = useContext(UserContext);

  const params = useParams();
  const [theMostSold, setTheMostSold] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const navigate = useNavigate();

  let propBool = false;
  if (params.login === "true") {
    propBool = true;
  }
  const [showLogin, setShowLogin] = useState(propBool);
  const coursesSwiperRef = useRef(null);
  const WhyBGPSwiperRef = useRef(null);

  const fetchProducts = () => {
    shopifyService
      .getAllProducts()
      .then((response) => {
        let products = response.data.products;
        products = products.filter((product) => product.shopify_store_id === 2);
        products = products.slice(0, 25);
        products.sort((a, b) => b.sold - a.sold);
        setTheMostSold(products);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCourses = () => {
    setIsLoadingCourses(true);
    coursesServices
      .getCourses()
      .then((response) => {
        setCourses(response.data.courses);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingCourses(false);
      });
  };

  const componentsSection2 = [
    {
      title: "CERO INVENTARIOS",
      subtitle:
        "Nosotros gestionamos el <br /> inventario, tú solo te enfocas <br />en vender.",
      icon: section2BoxSvg,
    },
    {
      title: "ACCESO A PROVEEDORES <br />INTERNACIONALES",
      subtitle:
        "Conéctate a más de 20 proveedores <br /> globales y ofrece productos de <br /> marcas reconocidas.",
      icon: section2WorldSvg,
    },
    {
      title: "ACOMPAÑAMIENTO <br /> CONTINUO",
      subtitle:
        "Recibe soporte y asesoría <br /> personalizada para hacer <br /> crecer tu negocio.",
      icon: section2HandsSvg,
    },
  ];

  const componentsSection3 = new Array(10).fill(1);

  const componentsSection4 = [
    {
      title: "No necesitas experiencia:",
      subtitle: "Aprende desde cero con nuestras guías paso a paso.",
    },
    {
      title: "Áreas cubiertas:",
      subtitle: "Shopify, Amazon, Mercado Libre, Facebook Ads, y más.",
    },
    {
      title: "Certificación avalada",
      subtitle: "Recibe un diploma al finalizar cada curso.",
    },
    {
      title: "Mentorías en vivo",
      subtitle: "Acceso a capacitaciones en Zoom con nuestros expertos.",
    },
  ];

  const componentsSection6 = [
    {
      title: "Sin inventario necesario",
      icon: ["far", "display"],
      backText:
        "Vende sin comprar stock. Paga solo cuando vendas, sin riesgos ni almacenes.",
      navLink: null,
    },
    {
      title: "Más de 20 proveedores confiables",
      icon: ["far", "store"],
      backText:
        "Accede a una red exclusiva de proveedores con productos listos para vender sin inversión en inventario.",
      navLink: {
        label: "Ver tienda",
        url: "/tienda",
      },
    },
    {
      title: "Garantía y envíos express",
      icon: ["far", "truck-fast"],
      backText:
        "Productos con garantía y envíos rápidos para asegurar la mejor experiencia a tus clientes.",
      navLink: null,
    },
    {
      title: "Servicio de logística",
      icon: ["far", "dolly"],
      backText:
        "Nos encargamos del empaque y envío, asegurando entregas eficientes en todo México.",
      navLink: null,
    },
    {
      title: "Plataforma educativa completa",
      icon: ["far", "user-graduate"],
      backText:
        "Aprende ecommerce con más de 50 cursos y conviértete en un experto en ventas en línea.",
      navLink: null,
    },
    {
      title: "Certificados y mentoría",
      icon: ["far", "file-certificate"],
      backText:
        "Obtén certificados y mentoría personalizada para impulsar tu negocio digital con expertos.",
      navLink: null,
    },
  ];

  const componentsSection7 = [
    {
      name: "Fátima Sánchez Ruiz",
      text: "“El aprender a emprender, a lanzarte a tener esa iniciativa de poder emprender aquí y en todos lados.”",
      video: testimonial1,
    },
    {
      name: "Mariana López",
      text: "“Vieron temas que son puntos clave para empezar y poder arrancar en este mundo del emprendimiento.”",
      video: testimonial2,
    },
    {
      name: "Luis Hernández",
      text: "“Los precios de Be Global Pro son muy buenos y los márgenes de  ganancia también lo son. Ha sido muy buena la experiencia y muy buen servicio el que han dado.”",
      video: testimonial3,
    },
  ];

  useEffect(() => {
    fetchProducts();
    fetchCourses();
  }, [currentUser]);

  return (
    <section className="mx-0">
      <AuthModal
        isModalOpen={showLogin}
        setIsModalOpen={setShowLogin}
        type="login"
      />

      {/* SECTION 1 */}
      <div
        className="relative w-full h-[600px] md:h-[460px] xl:h-[680px] 2xl:h-[900px] sm:bg-cover bg-[center_-20px] ] bg-[size:300%] sm:bg-top bg-white flex flex-col justify-end md:justify-center pb-16 md:pb-0"
        style={{ backgroundImage: `url(${topImage})` }}
      >
        <div className="absolute inset-0 bg-white  bg-opacity-80  sm:bg-opacity-70   md:bg-opacity-0"></div>{" "}
        <div className="relative z-10 text-white text-center md:text-left px-12 xl:px-24 space-y-5">
          <p className="text-primary-new font-medium leading-8 2xl:leading-[78px] text-[25px] md:text-[28px] xl:text-4xl 2xl:text-[65px]">
            ¡Empieza tu tienda <br /> de{" "}
            <span className="font-bold text-secondary-new">dropshipping</span>{" "}
            hoy!
          </p>
          <p className="max-w-md font-Inter text-gray-new text-[13px] md:text-sm xl:text-lg mx-auto md:mx-0">
            Crea tu propio negocio sin inventarios ni riesgos. Con Be Global
            Pro, puedes vender productos de marcas internacionales en México.
          </p>
          {!currentUser && (
            <NButton
              label="Únete ahora"
              onClick={() =>
                navigate({ pathname: "", search: "?register=true" })
              }
            />
          )}
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="bg-white py-[60px] lg:py-[80px] xl:py-[120px] pl-16">
        <Swiper
          slidesPerView={1}
          spaceBetween={-60}
          pagination={{
            clickable: false,
          }}
          loop={true}
          breakpoints={{
            580: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
              noSwiping: true,
              allowSlidePrev: false,
              allowSlideNext: false,
              allowTouchMove: false,
            },
          }}
          className="mySwiper1 w-auto mx-auto"
        >
          {componentsSection2.map(({ title, subtitle, icon }, index) => (
            <SwiperSlide key={index}>
              <div className="flex md:justify-center h-[100px] space-x-3 lg:space-x-5 w-full mx-auto ">
                <img alt="" className="h-auto w-[35px] !important" src={icon} />
                <div className="flex flex-col justify-center w-[235px]">
                  <p
                    className="font-bold text-primary-new text-sm lg:text-[17px]"
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                  <p
                    className="text-gray-new text-[11px] lg:text-[13px] leading-3 lg:leading-4"
                    dangerouslySetInnerHTML={{
                      __html: subtitle,
                    }}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* SECTION 3 */}
      <div className="bg-gray-new-50 py-20 lg:py-24">
        <div className="px-8 lg:px-16">
          <p className="font-bold text-base xl:text-xl text-center text-secondary-new">
            Accede a los productos más vendidos <br className="lg:hidden" /> y
            rentables de nuestro catálogo
          </p>

          <p className="font-bold text-2xl xl:text-[44px] text-center text-primary-new-100 mt-4">
            Productos Top en <br className="lg:hidden" /> Be Global Pro
          </p>
        </div>

        <div className="pl-8 md:pl-12 xl:pl-16 my-4 lg:my-16">
          <Swiper
            slidesPerView={2}
            spaceBetween={-60}
            pagination={{
              clickable: false,
            }}
            loop={true}
            breakpoints={{
              570: {
                slidesPerView: 3,
              },
              720: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1536: {
                slidesPerView: 5,
                spaceBetween: 0,
              },
            }}
            className="mySwiper1"
          >
            {theMostSold.map((product, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col items-start lg:items-center space-y-4 p-10 md:p-4">
                  <div className="w-[140px] h-[140px] xl:w-[270px] xl:h-[270px]  flex justify-center items-center">
                    <img
                      key={index}
                      src={product.image.src}
                      alt=""
                      onClick={() =>
                        navigate(
                          `/tienda/${product.shopify_store_id}/producto/${product.id}`
                        )
                      }
                      className="cursor-pointer h-full w-full object-cover rounded-[30px]"
                    />
                  </div>

                  <div className="text-center">
                    <p className="text-primary-new font-bold font-Montserrat text-[13px] xl:text-[17px]">
                      {product.title.slice(0, 14)}...
                    </p>
                    <p className="text-primary-new font-medium font-Montserrat text-[11px] xl:text-sm">
                      <span className="text-[#FD4C4C] line-through">
                        {product.variants[0].compare_at_price} MXN
                      </span>{" "}
                      {product.variants[0].price} MXN
                    </p>
                    <p className="text-primary-new font-medium font-Montserrat text-[9px] xl:text-sm">
                      {product.body_html.replace(/<[^>]+>/g, "").slice(0, 40)}
                      ...
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="text-center">
          <NButton
            label="Ver todos los productos ganadores"
            className="!w-[319px]"
            onClick={() => navigate("/tienda")}
          />
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="bg-white pt-20 lg:py-24">
        <div className="px-8 lg:px-16">
          <p className="font-bold text-2xl xl:text-[44px] xl:leading-[52px] text-center text-primary-new-100 mt-4">
            Más de 50 cursos para llevar tu <br /> negocio al siguiente nivel
          </p>
        </div>

        <div className="pl-8 md:pl-12 xl:pl-16 my-16">
          <Swiper
            slidesPerView={1}
            spaceBetween={-80}
            pagination={{
              clickable: false,
            }}
            loop={true}
            breakpoints={{
              700: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            className="mySwiper1 w-auto mx-auto"
          >
            {componentsSection4.map(({ title, subtitle }, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col justify-center w-full">
                  <p className="font-black text-secondary-new text-[13px] lg:text-[17px]">
                    {title}
                  </p>
                  <p className="font-bold text-gray-new text-[11px] lg:text-[13px] w-full">
                    {subtitle}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="px-4 md:px-8 xl:px-16 mb-20 space-y-16 xl:space-y-24">
          <div className="flex space-x-6 xl:space-x-12">
            <button onClick={() => coursesSwiperRef.current?.slidePrev()}>
              <FontAwesomeIcon
                className="text-primary-new"
                icon={["far", "circle-arrow-left"]}
                lib="far"
                size="3x"
              />
            </button>

            <Swiper
              onSwiper={(swiper) => (coursesSwiperRef.current = swiper)}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1770: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {courses.map((course, index) => (
                <SwiperSlide key={index}>
                  <div className="h-[380px] 2xl:h-[467px] w-full 2xl:w-[354px] shadow-md border rounded-xl my-4 flex flex-col">
                    <div className="h-[50%]">
                      <img
                        key={index}
                        src={course.image}
                        alt=""
                        className="cursor-pointer h-full w-full object-cover rounded-t-md"
                      />
                    </div>
                    <div className="flex flex-col justify-between items-center px-2 2xl:px-8 py-4 xl:py-8 text-center h-[50%] overflow-hidden">
                      <div className="flex-1 overflow-auto">
                        <p className="font-Montserrat font-bold text-primary-new text-xs xl:text-[17px] mb-1">
                          <b className="hidden sm:block">
                            {course.name.length > 24
                              ? course.name.slice(0, 24) + "..."
                              : course.name}
                          </b>
                          <b></b>
                          <b></b>
                        </p>
                        <p className="font-Montserrat font-medium text-primary-new text-[11px] xl:text-sm leading-none xl:leading-1">
                          {course.description.length > 55
                            ? course.description.slice(0, 55) + "..."
                            : course.description}
                        </p>
                      </div>

                      {/* El botón siempre al fondo */}
                      <div className="mt-auto">
                        {isLoadingCourses ? (
                          <TableLoading />
                        ) : (
                          <CourseButton course={course} />
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <button onClick={() => coursesSwiperRef.current?.slideNext()}>
              <FontAwesomeIcon
                className="text-primary-new"
                icon={["far", "circle-arrow-right"]}
                lib="far"
                size="3x"
              />
            </button>
          </div>

          <div className="text-center">
            <NButton
              label="Explorar cursos"
              className="w-[197px]"
              onClick={() => navigate("/cursos")}
            />
          </div>
        </div>
      </div>

      {/* SECTION 5 */}
      <div className="bg-gray-new-50 py-20 lg:py-24">
        <div className="flex justify-center space-x-5 lg:space-x-10">
          <FontAwesomeIcon
            className="text-primary-new/40"
            icon={["fab", "facebook"]}
            size="2x"
          />
          <FontAwesomeIcon
            className="text-primary-new/40"
            icon={["fab", "instagram"]}
            size="2x"
          />
          <FontAwesomeIcon
            className="text-primary-new/40"
            icon={["fab", "amazon"]}
            size="2x"
          />
          <FontAwesomeIcon
            className="text-primary-new/40"
            icon={["fab", "shopify"]}
            size="2x"
          />
          <FontAwesomeIcon
            className="text-primary-new/40"
            icon={["fab", "wordpress"]}
            size="2x"
          />
          <FontAwesomeIcon
            className="text-primary-new/40"
            icon={["fab", "whatsapp"]}
            size="2x"
          />
        </div>

        <div className="px-8 lg:px-16 pt-5 pb-8 xl:pb-12 xl:pt-5">
          <p className="font-bold text-base xl:text-xl text-center text-secondary-new">
            Transformamos emprendedores en dueños de negocios online exitosos,
            <br className="hidden lg:block" />
            sin riesgos y con el respaldo de más de 50 cursos.
          </p>

          <p className="font-bold text-2xl xl:text-[44px] text-center text-primary-new-100 mt-6 mb-0 lg:my-6 leading-[30px] lg:leading-[56px]">
            Tu aliado ideal para
            <br className="lg:hidden" /> vender{" "}
            <br className="hidden lg:block" />
            por internet
          </p>
        </div>

        <div className="flex justify-center px-12">
          <video
            width="320"
            height="240"
            controls
            className="aspect-3/2 w-full max-w-3xl"
          >
            <source src={videoWebinar} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="flex justify-center mt-8">
          <NButton
            type="a"
            href="https://beglobalpro.com.mx/"
            target="_blank"
            label="Accede al webinar hoy"
            secondary
          />
        </div>
      </div>

      {/* SECTION 6 */}
      <div className="bg-white pt-20 lg:py-24">
        <div className="px-8 lg:px-16 pb-8 xl:pb-12">
          <p className="font-bold text-base xl:text-xl text-center text-secondary-new">
            Conoce los beneficios exclusivos <br className="lg:hidden" /> que
            ofrecemos a nuestros miembros.
          </p>

          <p className="font-bold text-2xl xl:text-[44px] text-center text-primary-new-100 mt-4">
            ¿Por qué Be Global Pro?
          </p>
          <p className="lg:hidden text-sm sm:text-xl text-center text-primary-new-100 mt-2">
            ¡Haz click en cada tarjeta y descubre el porqué!
          </p>
        </div>

        <div className="px-4 md:px-8 xl:px-16 mb-20 space-y-16 xl:space-y-24">
          <div className="flex space-x-6 xl:space-x-12">
            <button onClick={() => WhyBGPSwiperRef.current?.slidePrev()}>
              <FontAwesomeIcon
                className="text-primary-new"
                icon={["far", "circle-arrow-left"]}
                lib="far"
                size="3x"
              />
            </button>

            <Swiper
              onSwiper={(swiper) => (WhyBGPSwiperRef.current = swiper)}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1536: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {componentsSection6.map(
                ({ title, icon, backText, navLink }, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex justify-center py-5 lg:py-10">
                      <LandingCard
                        title={title}
                        icon={icon}
                        backText={backText}
                        navLink={navLink}
                      />
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>

            <button onClick={() => WhyBGPSwiperRef.current?.slideNext()}>
              <FontAwesomeIcon
                className="text-primary-new"
                icon={["far", "circle-arrow-right"]}
                lib="far"
                size="3x"
              />
            </button>
          </div>
        </div>
      </div>

      {/* SECTION 7 */}
      <div className="bg-gray-new-50 py-20 lg:py-24">
        <div className="px-8 lg:px-16 pb-8 xl:pb-12">
          <p className="font-bold text-base xl:text-xl text-center text-secondary-new">
            Descubre cómo nuestros miembros han cambiado sus vidas con Be Global
            Pro
          </p>

          <p className="font-bold text-2xl xl:text-[44px] text-center text-primary-new-100 mt-4">
            Historias de éxito
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 px-8 xl:px-16 place-items-center">
          {componentsSection7.map(({ name, text, video }, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center w-[330px] space-y-6"
            >
              <video width="330" height="190" controls className="aspect-3/2">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="text-center text-primary-new">
                <p className="font-bold font-Montserrat text-[17px] mb-2">
                  {name}
                </p>
                <p className="font-medium font-Montserrat leading-4 text-[14px]">
                  {text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* SECTION 8 */}
      <div
        className="relative w-full h-[600px] md:h-[460px] xl:h-[680px] 2xl:h-[900px] bg-cover bg-top bg-white flex flex-col justify-center"
        style={{ backgroundImage: `url(${bottomImage})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>{" "}
        <div className="relative z-10 text-white text-center px-12 xl:px-24">
          <p className="max-w-lg font-bold text-white text-xl  xl:text-2xl mx-auto mb-4">
            Todo lo que necesitas está aquí. <br />
            <span className="text-secondary-new text-lg xl:text-xl">
              ¡Únete a Be Global Pro y empieza <br className="lg:hidden" /> a
              vender sin riesgos!
            </span>
          </p>
          <p className="text-white font-bold leading-8 2xl:leading-[78px] text-3xl xl:text-4xl">
            ¡Empieza hoy <br className="lg:hidden" /> tu negocio online!
          </p>

          <div className="mt-8">
            {!currentUser && (
              <NButton
                label="Únete ahora"
                className="text-[20px]"
                secondary
                onClick={() =>
                  navigate({ pathname: "", search: "?register=true" })
                }
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
