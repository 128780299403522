import { useRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "../styles/transitions/modal.css";

const Modal = ({ children, isModalOpen, setIsModalOpen, onRequestClose }) => {
  const modalRef = useRef();

  const close = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CSSTransition
        nodeRef={modalRef}
        in={isModalOpen}
        classNames="modal"
        timeout={300}
        onExited={onRequestClose}
        unmountOnExit
      >
        <>
          {ReactDOM.createPortal(
            <div ref={modalRef}>
              <div className="modalContent justify-center items-center flex overflow-x-hidden overflow-hidden md:overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*CONTENT*/}
                  <div className="border-0 rounded-none md:rounded-lg overflow-hidden shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*HEADER*/}
                    <div className="absolute right-0 z-10">
                      <button
                        className="w-auto h-auto px-2 border-0 leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => close()}
                      >
                        <span className=" text-black text-3xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*BODY*/}
                    {children}
                  </div>
                </div>
              </div>
              <div className="opacity-30 fixed inset-0 z-50 bg-black" />
            </div>,
            document.getElementById("modal")
          )}
        </>
      </CSSTransition>
    </>
  );
};

export default Modal;
