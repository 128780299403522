import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import Diploma from "../../components/Diploma";

import { profileService } from "../../services/api/profile";

import Context from "../../context/UserContext";

const Diplomas = () => {
  const { currentUser } = useContext(Context);

  const [diplomas, setDiplomas] = useState([]);

  const fetchDiplomas = () => {
    profileService
      .getDiplomas()
      .then((response) => {
        setDiplomas(response.data.diplomas);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchDiplomas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      {!_.isEmpty(diplomas) ? (
        diplomas.map((diploma, index) => (
          <Diploma
            key={`Diploma-${diploma.id}`}
            index={index}
            diploma={diploma}
            last={index === diplomas.length - 1}
          />
        ))
      ) : (
        <div className="">
          <p className="text-center lg:text-left text-gray-700 font-bold text-xl pb-5">
            Aún no tienes diploma
          </p>
          <p className="text-center lg:text-left text-gray-400 text-xl pb-12">
            Empieza fácilmente, selecciona aquí un curso, apruébalo y obtén tu
            primer certificado
          </p>
          <div className="mx-auto text-center lg:text-left">
            <Link
              to="/cursos"
              className="bg-primary-new text-white text-center font-bold text-xl py-3 px-10 disabled:opacity-75 rounded-full"
            >
              Ir a cursos
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Diplomas;
