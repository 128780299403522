import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import AuthModal from "./AuthModal";
import logo from "../assets/images/logo-bgp.png";
import useUser from "../hooks/useUser";
import UserContext from "../context/UserContext";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NavBar() {
  const { currentUser } = useContext(UserContext);
  const [showLogin, setShowLogin] = useState(false);
  const [authModalType, setAuthModalType] = useState("login");
  const [openMenu, setOpenMenu] = useState(false);
  const keyValues = window.location.search;
  const urlParams = new URLSearchParams(keyValues);
  const { logout } = useUser();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  const onLogout = async () => {
    await logout();
    setOpenMenu(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    urlParams.get("login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (urlParams.get("login") === "true") {
      setShowLogin(true);
    }
    if (urlParams.get("register") === "true") {
      handleAuthModal("register");
    }

    if (location.pathname === "/") {
      setIsLanding(true);
    } else {
      setIsLanding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleScroll = () => {
    if (window.scrollY > 25) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!showLogin) return setAuthModalType("login");
  }, [showLogin]);

  const handleAuthModal = (type) => {
    setAuthModalType(type);
    setShowLogin(true);
  };

  return (
    <>
      <nav
        className={`w-full h-[112px] flex items-center px-4 md:px-12 xl:px-24 fixed z-40 ${
          isScrolled ? "bg-white shadow-md" : "bg-transparent"
        } ${
          !isLanding ? "bg-white" : ""
        } transition-all ease-in-out duration-300`}
      >
        <div className="container mx-auto flex justify-between">
          {/* Logo BeGlobalPro */}
          <Link
            className="flex items-center"
            to="/"
            onClick={() => setOpenMenu(false)}
          >
            <img
              src={logo}
              alt="logo de BeGlobal Pro"
              className="w-40 lg:w-44 px-0"
            />
          </Link>
          {/* Menú Móvil */}
          <div
            className="flex justify-center items-center lg:hidden cursor-pointer text-white bg-[#062F55] h-10 w-10 rounded-xl"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>

          {/* Menu Pc */}
          <div className="hidden lg:flex items-center space-x-10 xl:space-x-20 2xl:space-x-32">
            <div className="flex space-x-6 items-center">
              <NavLink
                to="/blog"
                className="text-gray-new-200 tex-2xl hover:text-primary-new transition-all ease-in-out duration-300"
                onClick={() => setOpenMenu(false)}
              >
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Blog
                  </span>
                )}
              </NavLink>
              <NavLink
                to="/membresias"
                className="text-gray-new-200 tex-2xl"
                onClick={() => setOpenMenu(false)}
              >
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Membresías
                  </span>
                )}
              </NavLink>
              <NavLink
                to="/tienda"
                className="text-gray-new-200 tex-2xl"
                onClick={() => setOpenMenu(false)}
              >
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Tienda
                  </span>
                )}
              </NavLink>
              <NavLink
                to="/calendario"
                className="text-gray-new-200 tex-2xl"
                onClick={() => setOpenMenu(false)}
              >
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Calendario
                  </span>
                )}
              </NavLink>
              <NavLink
                to="/cursos"
                className="text-gray-new-200 tex-2xl"
                onClick={() => setOpenMenu(false)}
              >
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Cursos
                  </span>
                )}
              </NavLink>
            </div>

            <div className="flex items-center space-x-4">
              {currentUser ? (
                <Dropdown name={currentUser.name} />
              ) : (
                <div className="flex space-x-5">
                  <button
                    className={`rounded-full px-8 py-4 bg-primary-new hover:bg-primary-new-50 text-white transition-all ease-in-out duration-300 ${
                      isLanding ? "order-1" : "order-2"
                    }`}
                    onClick={() =>
                      currentUser ? false : handleAuthModal("register")
                    }
                  >
                    Registrarme
                  </button>
                  <button
                    className={`rounded-full px-8 py-4 bg-white hover:bg-gray-100 transition-all ease-in-out duration-300 ${
                      isLanding ? "order-2" : "order-1"
                    }`}
                    onClick={() =>
                      currentUser ? false : handleAuthModal("login")
                    }
                  >
                    Iniciar sesión
                  </button>
                </div>
              )}
              {currentUser && (
                <NavLink to="/carrito-de-compras" className="ml-3">
                  <span>
                    <FontAwesomeIcon
                      icon={["far", "cart-shopping"]}
                      size="lg"
                    />
                  </span>
                </NavLink>
              )}
            </div>
          </div>
        </div>

        {/* Menú Móvil */}
        {openMenu && (
          <div className="absolute top-20 right-0 lg:hidden w-full flex flex-col items-left text-left bg-white shadow-md py-4 z-0 text-xl rounded-2xl space-y-3">
            <NavLink
              to="/blog"
              className="text-gray-new-200 hover:text-primary-new transition-all ease-in-out duration-300"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className="space-x-4 flex flex-row">
                  <span className="text-center w-1/6 pl-3">
                    <FontAwesomeIcon
                      icon={["fa", "blog"]}
                      size="lg"
                      color={isActive ? "#062f55" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Blog
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/membresias"
              className="text-gray-new-200 hover:text-primary-new transition-all ease-in-out duration-300"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "users"]}
                      size="lg"
                      color={isActive ? "#062f55" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Membresías
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/tienda"
              className="text-gray-new-200 hover:text-primary-new transition-all ease-in-out duration-300"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "store"]}
                      size="lg"
                      color={isActive ? "#062f55" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Tienda
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/calendario"
              className="text-gray-new-200 hover:text-primary-new transition-all ease-in-out duration-300"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "calendar"]}
                      size="lg"
                      color={isActive ? "#062f55" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Calendario
                  </span>
                </div>
              )}
            </NavLink>
            <NavLink
              to="/cursos"
              className="text-gray-new-200 hover:text-primary-new transition-all ease-in-out duration-300"
              onClick={() => setOpenMenu(false)}
            >
              {({ isActive }) => (
                <div className=" space-x-4 flex flex-row">
                  <span className="text-center w-1/6">
                    <FontAwesomeIcon
                      icon={["far", "book"]}
                      size="lg"
                      color={isActive ? "#062f55" : undefined}
                    />
                  </span>
                  <span
                    className={
                      isActive ? "font-black text-primary-new" : undefined
                    }
                  >
                    Cursos
                  </span>
                </div>
              )}
            </NavLink>

            {currentUser && (
              <NavLink
                to="/carrito-de-compras"
                className="text-gray-new-200 hover:text-primary-new transition-all ease-in-out duration-300"
                onClick={() => setOpenMenu(false)}
              >
                {({ isActive }) => (
                  <div className=" space-x-4 flex flex-row">
                    <span className="text-center w-1/6">
                      <FontAwesomeIcon
                        icon={["far", "cart-shopping"]}
                        size="lg"
                        color={isActive ? "#062f55" : undefined}
                      />
                    </span>
                    <span
                      className={
                        isActive ? "font-black text-primary-new" : undefined
                      }
                    >
                      Carrito de compras
                    </span>
                  </div>
                )}
              </NavLink>
            )}
            {currentUser ? (
              <div className="text-gray-900 flex flex-col ">
                <Link
                  to="/mi-perfil"
                  className="mb-2 px-6  font-bold"
                  onClick={() => setOpenMenu(false)}
                >
                  <div className=" space-x-4 flex flex-row">
                    <span className="text-center w-1/6">
                      <FontAwesomeIcon icon={["far", "user"]} size="lg" />
                    </span>
                    <span>Mi Perfil</span>
                  </div>
                </Link>
                <Link
                  to="/mi-escritorio"
                  className="mb-2 px-6  font-bold"
                  onClick={() => setOpenMenu(false)}
                >
                  <div className=" space-x-4 flex flex-row">
                    <span className="text-center w-1/6">
                      <FontAwesomeIcon
                        icon={["far", "laptop-code"]}
                        size="lg"
                      />
                    </span>
                    <span>Mi Escritorio</span>
                  </div>
                </Link>
                <hr className="border-gray-300 my-2 pb-2" />
                <div
                  type="button"
                  className="mb-2 px-6 font-bold"
                  onClick={() => onLogout()}
                >
                  <div className=" space-x-4 flex flex-row">
                    <span className="text-center w-1/6">
                      <FontAwesomeIcon
                        icon={["far", "right-from-bracket"]}
                        size="lg"
                      />
                    </span>
                    <span>Cerrar Sesión</span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                type="button"
                className="font-bold text-primary-new"
                onClick={() => {
                  if (!currentUser) {
                    setShowLogin(true);
                    setOpenMenu(false);
                  }
                }}
              >
                <div className="space-x-4 flex flex-row">
                  <span className="text-center w-1/6 ">
                    <FontAwesomeIcon
                      icon={["far", "right-to-bracket"]}
                      size="lg"
                    />
                  </span>
                  <span>Iniciar sesión</span>
                </div>
              </div>
            )}
          </div>
        )}
      </nav>
      <AuthModal
        isModalOpen={showLogin}
        setIsModalOpen={setShowLogin}
        type={authModalType}
      />
      {!isLanding && <div className="pt-[112px] relative"></div>}
    </>
  );
}
