import { useEffect, useState } from "react";
import PostComponent from "../../components/blog/PostComponent";
import { blogServices } from "../../services/api/blog";
import TableLoading from "../../components/TableLoading";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFoundComponent from "../../components/blog/NotFoundComponent";

export default function PostByAuthor() {
  const params = useParams();

  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [author, setAuthor] = useState(null);

  const [authorMetaInformation, setAuthorMetaInformation] = useState(null);
  const replaceWithBr = (text) => {
    return text ? text.replace(/\n/g, "<br />") : "";
  };
  const formatUrl = (url) => {
    return url.startsWith("http") ? url : `https://${url}`;
  };
  const fetchPostsByTag = () => {
    setIsLoading(true);

    blogServices
      .getPostByAuthor(params.slug)
      .then((response) => {
        setPosts(response.data.posts);
        setAuthor(response.data.author);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPostsByTag();
  }, []);

  useEffect(() => {
    if (author) {
      if (author.meta_description === null) return;
      const metadata = JSON.parse(author.meta);
      setAuthorMetaInformation(JSON.parse(author.meta));
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", metadata.meta_description);
      } else {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = metadata.meta_description;
        document.head.appendChild(meta);
      }
    }
  }, [author]);

  return (
    <section className="bg-gray-300 h-auto">
      <div className="min-h-screen">
        {author && (
          <div className="space-y-5 bg-white py-4 shadow-xl flex flex-col items-center justify-center container px-auto mx-auto  text-4xl w-full h-full">
            <div className="flex flex-col md:flex-row justify-start items-start p-4 border-gray-400 space-y-4 md:space-y-0 md:space-x-4">
              <div className="md:flex items-center space-x-4 ">
                <div className="mx-auto px-auto">
                  <p className="my-auto text-xl md:text-3xl text-center md:text-left">
                    <p className="font-bold hover:cursor-pointer">
                      {author.name}
                    </p>
                  </p>
                  <div className="md:hidden mx-auto h-20 w-20 md:h-40 md:w-40 bg-gray-500 rounded-md overflow-hidden mx-auto">
                    <img
                      alt={author.name}
                      src={author.avatar}
                      className="h-full w-full object-cover hover:cursor-pointer"
                    />
                  </div>
                  <p
                    className="text-sm md:text-2xl"
                    dangerouslySetInnerHTML={{
                      __html: replaceWithBr(author.bio),
                    }}
                  />
                  {authorMetaInformation &&
                    (authorMetaInformation.twitter_description ||
                      authorMetaInformation.opengraph_description) && (
                      <p className="text-sm md:text-xl font-bold text-primary text-center md:text-left text-primary-200">
                        Redes sociales:
                      </p>
                    )}
                  {authorMetaInformation && (
                    <div className="flex flex-col md:flex-row md:space-x-4 mt-2 text-primary-300">
                      {authorMetaInformation.twitter_description && (
                        <a
                          href={formatUrl(
                            authorMetaInformation.twitter_description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "twitter"]}
                            alt="Twitter"
                            className="h-10 w-10"
                          />
                        </a>
                      )}

                      {authorMetaInformation.opengraph_description && (
                        <a
                          href={formatUrl(
                            authorMetaInformation.opengraph_description
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "facebook"]}
                            alt="facebook"
                            className="h-10 w-10"
                          />
                        </a>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex justify-start">
                  <div className="bg-gray-400 hidden md:block h-20 w-20 md:h-40 md:w-40 rounded-md overflow-hidden flex items-center justify-center">
                    <img
                      alt={author.name}
                      src={author.avatar}
                      className="h-full w-full object-cover hover:cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
            {author && posts.length > 0 && (
              <div className="container px-4 mx-auto text-center text-sm lg:text-2xl text-primary-300 font-bold">
                Articulos relacionados a este autor:
              </div>
            )}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 container mx-auto px-4  xl:px-6 py-10 space-y-4 xl:space-y-0 ">
          {posts?.map(
            ({
              id,
              title,
              body,
              publish_date,
              featured_image,
              author,
              slug,
            }) => (
              <PostComponent
                key={id}
                title={title}
                body={body}
                publish_date={publish_date}
                featured_image={featured_image}
                author={author}
                slug={slug}
                hideAuthor
              />
            )
          )}
        </div>

        {isLoading && (
          <div className="container px-auto mx-auto text-center">
            <TableLoading size="10x" />
          </div>
        )}
        {posts.length === 0 && !isLoading && !author && (
          <NotFoundComponent
            label={"No se encontró ningún autor"}
            buttonText={"blog"}
            url={"/blog"}
          />
        )}
        {posts.length === 0 && !isLoading && author && (
          <NotFoundComponent
            label={"Este autor aún no cuenta con ninguna publicación."}
            buttonText={"blog"}
            url={"/blog"}
          />
        )}
      </div>
    </section>
  );
}
