import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableTopSales = ({ data, membershipSales }) => {
  return (
    <div className="relative my-10 rounded-xl mx-4">
      <div className="border border-gray-350 ">
        <div className="bg-gradient-to-t from-primary-300 to-primary-400 w-full h-20 py-4 text-white font-bold px-8 flex space-x-5 mx-auto justify-center">
          <div className="-rotate-90 text-2xl">TOP</div>
          <div className="text-4xl flex justify-center items-center">10</div>
          <div className="text-2xl flex justify-center items-center text-center">{`VENTAS DE ${
            membershipSales ? "MEMBRESIAS" : "PRODUCTOS"
          }`}</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
          {Array.from({
            length: Math.ceil(data.length / 5),
          }).map((_, colIndex) => (
            <div key={colIndex} className="flex flex-col">
              {data.slice(colIndex * 5, colIndex * 5 + 5).map((user, index) => (
                <div
                  key={index}
                  className="flex border-b pb-2 border-gray-500 border-opacity-50 mx-2 pt-2 h-20 lg:h-16 xl:h-[70px] 2xl:h-16"
                >
                  <div className="w-1/12  my-auto">
                    <div className="rounded-full  w-6 h-6 bg-primary-400 text-white text-center text-base my-auto font-bold">
                      {colIndex * 5 + index + 1}
                    </div>
                  </div>

                  <div className="flex justify-between  w-11/12">
                    <div className="flex w-full ">
                      <div className="my-auto w-3/12 ">
                        <div className="w-8 h-8 mx-2 relative ml-3">
                          {/* Icono de la corona */}
                          {colIndex * 5 + index < 3 && user.image && (
                            <>
                              <FontAwesomeIcon
                                icon={["fas", "crown"]}
                                alt="Crown"
                                className="h-5 w-5  bottom-6 left-[5px] absolute text-secondary rounded-full"
                              />
                              <div className="-rotate-45 absolute top-4 left-4 flex justify-center items-center w-5 h-5  bg-primary-500 rounded-full aunq">
                                <span className="text-[8px] font-bold text-white my-auto">
                                  TOP
                                </span>
                              </div>
                            </>
                          )}
                          {user.image ? (
                            <img
                              src={user.image}
                              alt=""
                              className="rounded-full w-full h-full object-cover"
                              style={{ backgroundColor: "#D8D8D8" }}
                            />
                          ) : (
                            <div className="animate-pulse rounded-full w-full h-full object-cover bg-gray-400" />
                          )}
                        </div>
                      </div>

                      {user.name ? (
                        <p className="text-black text-sm my-auto pl-2 w-9/12 ">
                          {user.name}
                          {/* {user.name && user.name.length > 30
                            ? user.name.slice(0, 30) + "..."
                            : user.name} */}
                        </p>
                      ) : (
                        <p className="text-black text-sm my-auto  truncate animate-pulse pl-2  w-9/12 text-left ">
                          Lugar disponible
                        </p>
                      )}
                    </div>
                    {user.total ? (
                      <div className="my-auto text-sm  font-bold  w-1/2 text-right">
                        ${user.total.toFixed()} <small>MXN</small>
                      </div>
                    ) : (
                      <div className="my-auto text-sm  font-bold  w-1/2 text-right"></div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableTopSales;
