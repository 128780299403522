import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Address = ({ address, last }) => {
  return (
    <div
      className={`flex flex-col lg:flex-row justify-between items-start border-b lg:items-center border-gray-350 py-8 ${
        last && "border-b-0 pb-0"
      }`}
    >
      <div className="flex items-center">
        <FontAwesomeIcon
          className="text-secondary-new"
          icon="location-dot"
          lib="fa"
          size="2xl"
        />
        <div className="pl-3">
          <p className="text-gray-700 font-bold text-2xl  leading-none">
            {address.street_and_number}
          </p>
          <p className="text-gray-550 text-xl  leading-none">
            Col. {address.colony} {address.postal_code} - {address.city},{" "}
            {address.state}
          </p>
          <p className="text-gray-550 text-xl leading-none font-bold">
            {address.country}
          </p>
          <p className="text-gray-600 text-xl leading-none font-bold">
            {address.phone}
          </p>
        </div>
      </div>
      <Link
        to={`/mi-perfil/direcciones/${address.id}/editar`}
        className="text-primary-new hover:text-primary-new-50 text-xl pt-5 lg:pt-0 ml-auto lg:m-0 w-28 text-right"
      >
        Editar
      </Link>
    </div>
  );
};

export default Address;
