import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import logo from "../assets/images/logo-globe-gbp.png";
import checkIcon from "../assets/icons/check-icon.svg";

const ConfirmPay = () => {
  return (
    <main className="container mx-auto flex-1 px-4 md:px-0 mb-16">
      <div className="">
        <div className="h-20 flex items-center">
          <img src={logo} alt="BeGlobalPro" className="h-9 mr-2" />
          <p className=" text-gray-700">
            <FontAwesomeIcon icon="fa-chevron-right" className="mr-1" />{" "}
            <span className="text-gray-400">Carrito de compras</span>
          </p>
        </div>
      </div>

      <div className="">
        <div className="bg-green-50 border-green-50 border-4 rounded-full w-24 h-24 mx-auto flex items-center justify-center mb-10">
          <img src={checkIcon} alt="" />
        </div>

        <p className="pb-8 text-5xl text-center  text-green-50 font-bold">
          Orden confirmada
        </p>
        <p className="pb-8 text-3xl text-center">
          La orden se ha confirmado exitosamente
        </p>
        <div className=" text-gray-700 text-2xl text-center">
          <Link
            to="/"
            className="bg-primary-new py-3 px-12 text-white font-bold mt-5 rounded-full"
          >
            Continuar
          </Link>
        </div>
      </div>
    </main>
  );
};

export default ConfirmPay;
