const commonBenefits = [
  "Acceso a todos los proveedores de la Tienda de Be Global Pro",
  "Más de 1000 productos diferentes para envío inmediato.",
  "Garantia por defecto de fabrica en productos.",
  "Envíos express mismo dia dentro de nuestros horarios hábiles",
  "Servicio de logística para envíos de ventas en Mercado libre, Amazon, Shopify, redes sociales, etc.",
  "Empaque del envío (cajas, cintas, burbuja, etc)",
  "Acceso a toda la plataforma educativa sin costo adicional.",
  "Certificados avalados por Be Global Pro al finalizar cada curso.",
  "Capacitaciones en vivo por zoom todas las semanas para mentorías con nuestros educadores.",
  "Acompañamiento por whatsapp con nuestros expertos.",
  "Acceso para poder hacer dropshipping con el proveedor de productos americanos originales.",
];

const additionalBenefits = [
  "Acceso a grupos de whatsapp para hacer compras mayoreo en tiendas de USA con una comisión muy baja de solo 10%",
  "Acceso a compras personalizadas 1 a 1 en USA con una comisión del 10%",
  "Dirección en USA para que hagas compras en sitios web por un costo mensual de solo $1,000 mxn",
  "Logistica para importar productos desde USA y CHINA, nosotros nos encargamos de llevarlo a México",
  "Proximidad con los CEOs",
  "Accesos a la convención del año de BE GLOBAL PRO",
];

export const plusMembershipsMapping = [
  {
    id: 3,
    name: "Mensual",
    price: 4500,
    period: "/ mes",
    paymentInfo: "Mensualidad de $4500 MXN",
    cta: {
      label: "Franquicia PLUS",
      outline: true,
    },
    benefits: [
      ...commonBenefits.map((label) => ({ label, disabled: false })),
      ...additionalBenefits.map((label) => ({ label, disabled: true })),
    ],
  },
  {
    id: 4,
    name: "Trimestral",
    price: 3000,
    period: "/ mes",
    paymentInfo: "Pago inicial $8,500 MXN te cubre 3 meses",
    cta: {
      label: "Franquicia PLUS",
      outline: false,
    },
    benefits: [
      ...commonBenefits.map((label) => ({ label, disabled: false })),
      ...additionalBenefits.map((label) => ({ label, disabled: true })),
    ],
  },
  {
    id: 7,
    name: "Semestral",
    price: 2500,
    period: "/ mes",
    paymentInfo: "Pago inicial $14,000 MXN te cubre 6 meses",
    cta: {
      label: "Franquicia PLUS",
      outline: true,
    },
    benefits: [
      ...commonBenefits.map((label) => ({ label, disabled: false })),
      ...additionalBenefits.map((label) => ({ label, disabled: true })),
    ],
  },
  {
    id: 5,
    name: "Anual",
    price: 2000,
    period: "/ mes",
    paymentInfo: "Pago inicial $20,000 MXN te cubre 12 meses",
    cta: {
      label: "Franquicia PLUS",
      outline: false,
    },
    benefits: [
      ...commonBenefits.map((label) => ({ label, disabled: false })),
      ...additionalBenefits.map((label) => ({ label, disabled: false })),
    ],
  },
];

export const infiniteMembershipsMapping = {
  id: 10,
  name: "Infinita",
  price: 40000,
  period: "",
  paymentInfo: "* Pago único",
  cta: {
    label: "Franquicia Infinita",
    outline: false,
  },
  benefits: [
    ...commonBenefits.map((label) => ({ label, disabled: false })),
    ...additionalBenefits.map((label) => ({ label, disabled: false })),
  ],
};
