import React from "react";
import { useEffect, useContext, useState } from "react";
import Context from "../../context/UserContext";
import moment from "moment";
import LearningCard from "../../components/statics/LearningCard";
import { StatisticsService } from "../../services/api/statistics";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import CoursesInProgress from "../../components/statics/CoursesInProgress";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

registerLocale("es", es);
const Statistics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [coursesFinished, setCoursesFinished] = useState([]);
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [coursesHours, setCoursesHours] = useState([]);
  const [coursesDays, setCoursesDays] = useState([]);
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [labelMonth, setLabelMonth] = useState(
    moment().format("MMMM").charAt(0).toUpperCase() +
      moment().format("MMMM").slice(1).toLowerCase()
  );

  const currentYear = moment().year();
  const [monthsPerYear, setMonthsPerYear] = useState(
    [...Array(new Date().getMonth() + 1)].map(
      (_, i) =>
        new Date(new Date().getFullYear(), i)
          .toLocaleString("default", { month: "long" })
          .charAt(0)
          .toUpperCase() +
        new Date(new Date().getFullYear(), i)
          .toLocaleString("default", { month: "long" })
          .slice(1)
    )
  );

  const [currentOption, setCurrentOption] = useState("currentWeek");

  const options = {
    scales: {
      x: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 20,
          },
        },
      },
      title: {},
      fontStyle: "bold",
    },
  };

  const dataDays = {
    labels,
    datasets: [
      {
        label: "Horas de estudio",
        data: data,
        borderColor: "#394875",
        backgroundColor: "#394875",
        pointRadius: 8,
      },
    ],
  };

  const learningArray = [
    {
      icon: "medal",
      title: "Cursos completados",
      count: coursesFinished,
    },
    {
      icon: ["far", "loader"],
      title: "Cursos en progreso",
      count: coursesInProgress,
    },
    {
      icon: ["far", "clock"],
      title: "Horas de estudio",
      count: coursesHours,
    },
  ];
  const fetchStatistics = () => {
    StatisticsService.getStatistics()
      .then((response) => {
        setCourses(response.data.courses);
        setCoursesFinished(response.data.coursesFinished);
        setCoursesInProgress(response.data.coursesInProgress);
        setCoursesDays(response.data.timePerDay);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const fetchCoursesHours = () => {
    let totalHours = 0;
    if (coursesDays) {
      coursesDays.forEach((element) => {
        totalHours = totalHours + parseFloat(element.seconds);
      });
    }
    setCoursesHours(
      parseFloat((totalHours / 60 / 60).toFixed(1)).toLocaleString("en-US")
    );
  };

  const changeDate = (option) => {
    setCurrentOption(option);
    switch (option) {
      case "currentWeek":
        const startDay = moment().startOf("week");
        const endDay = moment().endOf("week");

        let weekDays = moment.weekdays();
        let objWeek = Object.fromEntries(weekDays.map((day) => [day, 0]));

        // Filtramos solo los datos del año actual y de la semana actual
        let everyWeek = coursesDays.filter((day) => {
          let dateMoment = moment(day.date);
          return (
            dateMoment.year() === currentYear &&
            dateMoment.isBetween(startDay, endDay, null, "[]")
          );
        });

        // Sumamos las horas por día de la semana
        everyWeek.forEach((element) => {
          let dayName = moment(element.date).format("dddd");
          objWeek[dayName] += parseFloat(element.seconds) / 3600;
        });

        setLabels(Object.keys(objWeek));
        setData(Object.values(objWeek));
        break;

      case "annual":
        let objYear = {};

        moment.monthsShort().forEach((month) => (objYear[month] = 0));

        // Filtramos solo los datos del año actual
        let everyYear = coursesDays.filter(
          (day) => moment(day.date).year() === currentYear
        );

        // Sumamos las horas por mes
        everyYear.forEach((element) => {
          let monthName = moment(element.date).format("MMM");
          objYear[monthName] += parseFloat(element.seconds) / 3600;
        });

        setLabels(Object.keys(objYear));
        setData(Object.values(objYear));
        break;

      default:
        break;
    }
  };

  const { currentUser } = useContext(Context);
  useEffect(() => {
    if (!currentUser) return;
    fetchStatistics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    if (coursesDays.length > 0) {
      changeDate("currentWeek");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);

  useEffect(() => {
    if (!currentUser) return;
    fetchCoursesHours();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesDays]);

  useEffect(() => {
    if (!currentUser) return;
    if (currentOption === "currentMonth") {
      const monthIndex =
        labelMonth === "Enero"
          ? 0
          : new Date(`${labelMonth} 1, 2022`).getMonth();

      const start = moment().month(monthIndex).startOf("month");
      const end = moment().month(monthIndex).endOf("month");

      const startOfMonth = start.clone().startOf("month");
      const endOfMonth = end.clone().endOf("month");
      let objMonth = {};

      // Llenamos el objeto con días del mes y les asignamos un valor de 0
      for (
        let current = startOfMonth.clone();
        current.isBefore(endOfMonth) || current.isSame(endOfMonth, "day");
        current.add(1, "day")
      ) {
        objMonth[current.format("D")] = 0;
      }

      const everyDay = coursesDays.filter((day) => {
        const dateMoment = moment(day.date);
        return (
          dateMoment.year() === currentYear && dateMoment.month() === monthIndex // Aquí usamos `monthIndex`, no el mes actual
        );
      });

      // Sumamos las horas por día del mes
      everyDay.forEach((element) => {
        const dayNumber = moment(element.date).format("D");
        objMonth[dayNumber] += parseFloat(element.seconds) / 3600;
      });

      // Establecemos los datos en los estados correspondientes
      setLabels(Object.keys(objMonth));
      setData(Object.values(objMonth));
    }
  }, [labelMonth, currentOption]);

  return (
    <>
      <div>
        <p className="text-center lg:text-left text-xl lg:text-3xl text-gray-700 font-bold pb-5">
          Mi aprendizaje
        </p>
        <div className="flex flex-col md:flex-row gap-10 ">
          <div className="w-full lg:w-8/12 ">
            <div className="lg:px-auto w-full border border-gray-350 px-8 py-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              {learningArray.map((item, key) => (
                <LearningCard key={key} item={item} desktop></LearningCard>
              ))}
            </div>
            <div className="w-full mt-20">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-10">
                <div className="w-full lg:w-1/2">
                  <p className="w-full lg:w-auto text-center md:text-left text-2xl lg:text-3xl text-gray-700 font-bold mb-5 md:mb-0">
                    Tus estadisticas
                  </p>
                </div>

                <div className="w-full lg:w-1/2 flex justify-end space-x-2">
                  <select
                    name="breakdown"
                    onChange={(e) => changeDate(e.target.value)}
                    value={currentOption}
                    className="mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none w-full md:w-auto "
                  >
                    <option value="currentWeek">Semanal</option>
                    <option value="currentMonth">Mensual</option>
                    <option value="annual">Anual</option>
                  </select>
                  {currentOption === "currentMonth" && (
                    <select
                      name="select"
                      onChange={(e) => {
                        setLabelMonth(e.target.value);
                      }}
                      value={labelMonth}
                      className="mx-auto lg:mx-1 text-xl lg:text-xl font-bold  bg-white text-gray-700 border border-slate-300 py-3 px-4 focus:outline-none w-full md:w-auto "
                    >
                      {monthsPerYear.map((month) => (
                        <option value={month}>{month}</option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <Line options={options} data={dataDays} />
            </div>
          </div>
          <div className="w-full lg:w-4/12   border border-gray-350  ">
            <CoursesInProgress
              isLoading={isLoading}
              courses={courses}
              label={"Mi progreso"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
