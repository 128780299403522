import * as _ from "lodash";

const UserFund = ({ member }) => {
  return (
    <>
      <div className="flex flex-col text-center md:text-left">
        <p className="text-4xl font-bold text-gray-700 mr-2">
          Ventas: Membresías, productos y cursos
        </p>
        <p className="text-4xl font-bold text-primary-200">
          Miembro: {member.name}{" "}
        </p>
      </div>
      <p className="text-3xl font-bold text-gray-700 mb-10 text-center md:text-left mt-5 md:mt-0">
        Balance: ${member.fund ? member.fund.toLocaleString("en-US") : 0.0}{" "}
        <small>MXN</small>
      </p>
    </>
  );
};

export default UserFund;
