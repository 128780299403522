import { useState, React, useContext } from "react";
import { cartServices } from "../../services/api/cart";
import UserContext from "../../context/UserContext";
import { notifications } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";
import { MEMBERSHIPS_DICTIONARY } from "../../utils/MembershipsDictionary";
import MembershipCard from "../../components/cards/MembershipCard";
import { plusMembershipsMapping } from "./modules/membershipsMapping";

const PlusMembership = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddMembership = async (option) => {
    setIsLoading(true);
    if (currentUser) {
      await cartServices
        .addMembershipToCart({
          uuid: MEMBERSHIPS_DICTIONARY[option].UUID,
          type: "Membresia",
        })
        .then((response) => {
          notifications.success(response.data.message);
          navigate("/carrito-de-compras");
        })
        .catch((err) => {
          notifications.error(err.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    notifications.warn(
      <button
        onClick={() => navigate({ pathname: "", search: "?login=true" })}
        className="text-primary-200 text-left"
      >
        Inicie sesión{" "}
        <span className="text-gray-600">para adquirir una membresía</span>
      </button>
    );

    setIsLoading(false);
  };

  return (
    <div className="bg-white">
      <div className="grid place-items-center grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-y-10">
        {plusMembershipsMapping.map((membership, index) => (
          <div
            key={membership.id}
            className={`${index === 3 ? "xl:col-start-2" : ""}`}
          >
            <MembershipCard
              membership={membership}
              onClick={() => handleAddMembership(membership.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlusMembership;
