import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostComponent from "../../components/blog/PostComponent";
import { blogServices } from "../../services/api/blog";
import TableLoading from "../../components/TableLoading";
import postNotFoundImage from "../../assets/images/blog/not_found_search_posts.png";
import { useNavigate } from "react-router-dom";
import NotFoundComponent from "../../components/blog/NotFoundComponent";

export default function AllPosts() {
  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [searchedPosts, setSearchedPosts] = useState();
  const [showSearched, setShowSearched] = useState(false);

  const [isAtBottom, setIsAtBottom] = useState(false);
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const margin = 400;
    if (scrollTop + windowHeight >= documentHeight - margin) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  const fetchPosts = () => {
    setIsLoading(true);
    let params = {
      page: currentPage,
    };
    blogServices
      .getPosts(params)
      .then((response) => {
        if (Number(currentPage) !== Number(response.data.current_page)) {
          setCurrentPage(response.data.current_page);
        }
        setTotalPage(response.data.total_page);
        setPosts((prevPosts) => prevPosts.concat(response.data.posts));
        if (currentPage === 0) {
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchPosts = () => {
    setShowSearched(true);
    setSearchedPosts([]);

    setIsLoading(true);
    blogServices
      .searchPosts(search)
      .then((response) => {
        setSearchedPosts(response.data.posts);
        window.scrollTo(0, 0);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (currentPage !== 1) {
      fetchPosts();
    }
  }, [currentPage]);

  useEffect(() => {
    if (search === "" && showSearched) {
      setShowSearched(false);
      setSearchedPosts([]);
    }
  }, [searchPosts]);

  useEffect(() => {
    if (
      isAtBottom &&
      Number(currentPage) !== Number(totalPage) &&
      !showSearched
    ) {
      setCurrentPage(currentPage + 1);
    }
  }, [isAtBottom]);

  return (
    <section className="bg-gray-300 h-auto">
      <div className="min-h-screen">
        {posts.length > 3 && (
          <div className="flex  w-full py-5 top-0">
            <div className="container mx-auto px-4 md:px-0 pt-2 ">
              <div className="flex justify-end lg:space-x-8">
                <label className="relative block w-full lg:w-4/12">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FontAwesomeIcon
                      icon={["fa", "magnifying-glass"]}
                      className="text-[#A9A9A9]"
                      size="sm"
                    />
                  </span>
                  <input
                    className="w-full bg-white text-gray-700 placeholder:font-italic border border-slate-300 py-3 pl-12 pr-4 focus:outline-none"
                    type="text"
                    value={search}
                    placeholder="Buscar artículos..."
                    onChange={(event) => setSearch(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && search.length > 0) {
                        searchPosts();
                      }
                    }}
                  />
                </label>
                <button
                  onClick={() =>
                    search.length > 0 && !isLoading && searchPosts()
                  }
                  className={`py-auto ${
                    search.length > 0
                      ? "bg-primary-200 cursor-pointer"
                      : "bg-gray-600 cursor-not-allowed"
                  }  p-3 lg:px-10 text-white font-bold`}
                >
                  {isLoading && showSearched ? (
                    <FontAwesomeIcon icon="circle-notch" spin />
                  ) : (
                    <span title="Escribe el nombre del artículo que quieras encontrar y da click en  el botón 'Buscar'">
                      Buscar
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 container mx-auto px-4  xl:px-6 py-10 space-y-4 xl:space-y-0 ">
          {!showSearched &&
            posts?.map(
              ({
                id,
                title,
                body,
                publish_date,
                featured_image,
                author,
                slug,
              }) => (
                <PostComponent
                  key={id}
                  title={title}
                  body={body}
                  publish_date={publish_date}
                  featured_image={featured_image}
                  author={author}
                  slug={slug}
                />
              )
            )}
          {showSearched &&
            searchedPosts?.map(
              ({
                id,
                title,
                body,
                publish_date,
                featured_image,
                author,
                slug,
              }) => (
                <PostComponent
                  key={id}
                  title={title}
                  body={body}
                  publish_date={publish_date}
                  featured_image={featured_image}
                  author={author}
                  slug={slug}
                />
              )
            )}
        </div>
        {showSearched && searchedPosts.length === 0 && !isLoading && (
          <div className="space-y-10 bg-white py-24 shadow-xl flex flex-col items-center justify-center container px-auto mx-auto text-center text-4xl w-full h-full">
            <div>
              <img
                alt=""
                src={postNotFoundImage}
                className="h-20 w-20 mx-auto"
              />
            </div>
            <div className="mt-4 text-2xl md:text-4xl text-primary-300">
              No se encontraron resultados para tu búsqueda...
            </div>
            <button
              type="button"
              className="bg-primary-200 hover:bg-primary-300 text-white text-xl   mx-auto lg:m-0 p-3 px-6 font-bold  hover:bounce transition transform hover:scale-105 flex-col"
              onClick={() => setSearch("")}
            >
              Regresar
            </button>
          </div>
        )}
        {isLoading && (
          <div className="container px-auto mx-auto text-center">
            <TableLoading size="10x" />
          </div>
        )}
        {posts.length === 0 && !isLoading && (
          <NotFoundComponent
            label={"Aún no se ha añadido ningún artículo."}
            buttonText={"Home"}
            url={"/"}
          />
        )}
      </div>
    </section>
  );
}
