import React, { useContext, useEffect, useState } from "react";
import { cartServices } from "../services/api/cart";
import { notifications } from "../utils/notifications";
import Product from "../components/cart/Product";
import Item from "../components/cart/Item";
import { useNavigate } from "react-router-dom";
import Context from "../context/UserContext";
import BounceLoader from "react-spinners/BounceLoader";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShoppingCart = () => {
  const navigate = useNavigate();

  const { currentUser } = useContext(Context);

  const [courses, setCourses] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);

  const [coursesLater, setCoursesLater] = useState([]);
  const [membershipsLater, setMembershipsLater] = useState([]);
  const [servicesLater, setServicesLater] = useState([]);
  const [productsLater, setProductsLater] = useState([]);

  const [cartTotal, setCartTotal] = useState(0);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [isSavedForNowEmpty, setIsSavedForNowEmpty] = useState(true);
  const [isSavedForLaterEmpty, setIsSavedForLaterEmpty] = useState(true);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    color: "blue",
  };

  const handleOrder = () => {
    navigate("/carrito-de-compras/pagar");
  };

  const fetchCart = () => {
    cartServices
      .getCart()
      .then((response) => {
        setCourses(response.data.buyNow.courses);
        setServices(response.data.buyNow.services);
        setMemberships(response.data.buyNow.memberships);
        setProducts(response.data.buyNow.products);

        setCoursesLater(response.data.buyForLater.courses);
        setServicesLater(response.data.buyForLater.services);
        setMembershipsLater(response.data.buyForLater.memberships);
        setProductsLater(response.data.buyForLater.products);
        setMessage("Tu carrito de Be Global Pro está vacío.");
        setLoading(false);
      })
      .catch((err) => {});
  };

  const getCartTotal = () => {
    let total = 0;

    courses.forEach((course) => {
      total += course.cost;
    });

    services.forEach((service) => {
      total += service.cost;
    });

    memberships.forEach((membership) => {
      total += membership.cost;
    });

    products.forEach((product) => {
      total += product.amount * product.price;
    });

    setCartTotal(total);
  };

  useEffect(() => {
    if (!currentUser) {
      setLoading(false);
      setMessage("Inicia sesión para poder ver tu carrito");
      return;
    } else {
      fetchCart();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    getCartTotal();
    checkSavedForEmpty();
    checkSavedForEmpty(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, services, products, memberships]);

  useEffect(() => {
    //si el cart total da igual a 0 entonces que cargue el mensaje, sino ,que no de nada
    if (!cartTotal) return;
  }, [cartTotal]);

  const verifyProductsAvailability = (products) => {
    const getVariant = (variant_id, product) => {
      let variant;
      Object.keys(product.variants).forEach((key) => {
        if (variant_id === product.variants[key].id) {
          variant = product.variants[key];
        }
      });
      return variant;
    };
    let areAvailable = true;
    products.forEach((product) => {
      if (
        product.amount >
          getVariant(product.variant_id, product).inventory_quantity ||
        getVariant(product.variant_id, product).inventory_quantity === 0
      ) {
        areAvailable = false;
      }
    });
    return areAvailable;
  };

  const handleUpdateProductFromCart = async (
    uuid,
    quantity,
    variant_id,
    shopify_store_id
  ) => {
    if (quantity === 0)
      return notifications.error(
        "La cantidad a actualizar del producto no puede ser 0"
      );
    let bodyFormData = new FormData();
    bodyFormData.append("product_id", uuid);
    bodyFormData.append("variant_id", variant_id);
    bodyFormData.append("shopify_store_id", shopify_store_id);

    bodyFormData.append("amount", quantity);

    bodyFormData.append("_method", "PUT");
    await cartServices
      .buyForLaterProductFromCart(bodyFormData)
      .then((response) => {
        notifications.success(response.data.message);
        fetchCart();
        getCartTotal();
      })
      .catch((err) => {});
  };

  const handleRemoveItemFromCart = async (
    uuid,
    option,
    variant_id,
    shopify_store_id
  ) => {
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "DELETE");
    if (option === "Producto") {
      bodyFormData.append("product_id", uuid);
      bodyFormData.append("variant_id", variant_id);
      bodyFormData.append("shopify_store_id", shopify_store_id);
    } else {
      bodyFormData.append("uuid", uuid);
    }
    bodyFormData.append("type", option);
    option === "Producto"
      ? await cartServices
          .removeProductFromCart(bodyFormData)
          .then((response) => {
            notifications.success(response.data.message);
            fetchCart();
            getCartTotal();
          })
          .catch((err) => {})
      : await cartServices
          .removeCourseFromCart(bodyFormData)
          .then((response) => {
            notifications.success(response.data.message);
            fetchCart();
            getCartTotal();
          })
          .catch((err) => {});
  };

  const handleBuyLaterFromCart = async (
    uuid,
    option,
    value,
    variant_id,
    shopify_store_id
  ) => {
    let bodyFormData = new FormData();
    bodyFormData.append("uuid", uuid);
    if (option === "Producto") {
      bodyFormData.append("product_id", uuid);
      bodyFormData.append("variant_id", variant_id);
      bodyFormData.append("shopify_store_id", shopify_store_id);
    } else {
      bodyFormData.append("uuid", uuid);
    }
    bodyFormData.append("type", option);
    bodyFormData.append("save_for_later", value);
    bodyFormData.append("_method", "PUT");
    option === "Producto"
      ? await cartServices
          .buyForLaterProductFromCart(bodyFormData)
          .then((response) => {
            notifications.success(response.data.message);
            fetchCart();
            getCartTotal();
          })
          .catch((err) => {})
      : await cartServices
          .buyForLaterItemFromCart(bodyFormData)
          .then((response) => {
            notifications.success(response.data.message);
            fetchCart();
            getCartTotal();
          })
          .catch((err) => {});
  };

  const checkSavedForEmpty = (type = 1) => {
    let arrayNow = [courses, memberships, services, products];
    let arrayLater = [
      coursesLater,
      membershipsLater,
      servicesLater,
      productsLater,
    ];

    let array = type === 1 ? arrayNow : arrayLater;

    let flag = array.every((element) => _.isEmpty(element) === true);

    type === 1 ? setIsSavedForNowEmpty(flag) : setIsSavedForLaterEmpty(flag);
  };

  const cartContent = () => {
    if (isSavedForNowEmpty && isSavedForLaterEmpty) {
      return (
        <div className="text-center font-bold text-3xl text-gray-700 py-20 px-20">
          {message}
        </div>
      );
    }

    return (
      <main>
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:px-0">
          <h1 className="text-center text-xl lg:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Carrito de Compras
          </h1>

          {/* CART ITEMS */}
          {products.length < 1 &&
          courses.length < 1 &&
          services.length < 1 &&
          memberships.length < 1 ? (
            <div className="px-4  lg:px-1 mx-auto text-xl lg:text-3xl text-center my-2 lg:my-5">
              <p>No tienes ningún elemento en tu carrito.</p>
            </div>
          ) : (
            <div className="mt-6 lg:mt-12">
              <section>
                {products.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Productos
                    </p>
                    <div>
                      {products.map((product) => (
                        <Product
                          key={product.variant_id}
                          handleRemoveItemFromCart={(
                            id,
                            option,
                            variant_id,
                            shopify_store_id
                          ) =>
                            handleRemoveItemFromCart(
                              id,
                              option,
                              variant_id,
                              shopify_store_id
                            )
                          }
                          handleBuyLaterFromCart={(
                            id,
                            option,
                            value,
                            variant_id,
                            shopify_store_id
                          ) =>
                            handleBuyLaterFromCart(
                              id,
                              option,
                              value,
                              variant_id,
                              shopify_store_id
                            )
                          }
                          handleUpdateProductFromCart={(
                            id,
                            option,
                            variant_id,
                            shopify_store_id
                          ) =>
                            handleUpdateProductFromCart(
                              id,
                              option,
                              variant_id,
                              shopify_store_id
                            )
                          }
                          product={product}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <section className="mt-10">
                {courses.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Cursos
                    </p>
                    <div>
                      {courses.map((course) => (
                        <Item
                          key={course.uuid}
                          type="Curso"
                          handleRemoveItemFromCart={(id, option) =>
                            handleRemoveItemFromCart(id, option)
                          }
                          handleBuyLaterFromCart={(id, option, value) =>
                            handleBuyLaterFromCart(id, option, value)
                          }
                          item={course}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <section className="mt-10">
                {services.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Servicios
                    </p>
                    <div>
                      {services.map((service) => (
                        <Item
                          key={service.uuid}
                          type="Servicio"
                          handleRemoveItemFromCart={(id, option) =>
                            handleRemoveItemFromCart(id, option)
                          }
                          handleBuyLaterFromCart={(id, option, value) =>
                            handleBuyLaterFromCart(id, option, value)
                          }
                          item={service}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <section className="mt-10">
                {memberships.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Membresias
                    </p>
                    <div>
                      {memberships.map((membership) => (
                        <Item
                          key={membership.uuid}
                          type="Membresia"
                          handleRemoveItemFromCart={(id, option) =>
                            handleRemoveItemFromCart(id, option)
                          }
                          handleBuyLaterFromCart={(id, option, value) =>
                            handleBuyLaterFromCart(id, option, value)
                          }
                          item={membership}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              {/* ORDER SUMMARY */}
              <section className="items-end mt-10">
                <div className="items-end">
                  <div className="flex justify-end text-xl  lg:text-2xl text-gray-900">
                    <p>TOTAL</p>
                    <p className="ml-8 font-bold leading-none text-right">
                      $
                      {cartTotal.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}{" "}
                      <small> MXN</small>
                    </p>
                  </div>
                  <div className="flex justify-end mt-10">
                    {verifyProductsAvailability(products) ? (
                      <button
                        type="button"
                        className="w-full md:w-1/3 rounded-full bg-primary-new py-3 px-4 text-base font-bold text-white shadow-sm focus:outline-none transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150"
                        onClick={() => {
                          handleOrder();
                        }}
                      >
                        PAGAR
                      </button>
                    ) : (
                      <div
                        className="w-full text-center md:w-1/3 rounded-md bg-gray-500 py-3 px-4 text-base font-bold  shadow-sm focus:outline-none transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150"
                        title="Verifica que los productos de tu carrito estén disponibles para comprar"
                      >
                        <FontAwesomeIcon
                          icon={["fa", "ban"]}
                          className="text-gray-700 text-3xl"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>

        {!isSavedForLaterEmpty && (
          <div className="mx-auto max-w-2xl pb-16 px-4 sm:pb-24 sm:px-6 lg:px-0">
            <h1 className="text-center text-xl lg:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Guardado para después
            </h1>

            <div className="mt-6 lg:mt-12">
              <section>
                {productsLater.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Productos
                    </p>
                    <div>
                      {productsLater.map((product) => (
                        <Product
                          key={product.variant_id}
                          handleRemoveItemFromCart={(
                            id,
                            option,
                            variant_id,
                            shopify_store_id
                          ) =>
                            handleRemoveItemFromCart(
                              id,
                              option,
                              variant_id,
                              shopify_store_id
                            )
                          }
                          handleBuyLaterFromCart={(
                            id,
                            option,
                            value,
                            variant_id,
                            shopify_store_id
                          ) =>
                            handleBuyLaterFromCart(
                              id,
                              option,
                              value,
                              variant_id,
                              shopify_store_id
                            )
                          }
                          handleUpdateProductFromCart={(
                            id,
                            option,
                            variant_id,
                            shopify_store_id
                          ) =>
                            handleUpdateProductFromCart(
                              id,
                              option,
                              variant_id,
                              shopify_store_id
                            )
                          }
                          product={product}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <section className="mt-10">
                {coursesLater.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Cursos
                    </p>
                    <div>
                      {coursesLater.map((course) => (
                        <Item
                          key={course.uuid}
                          type="Curso"
                          handleRemoveItemFromCart={(id, option) =>
                            handleRemoveItemFromCart(id, option)
                          }
                          handleBuyLaterFromCart={(id, option, value) =>
                            handleBuyLaterFromCart(id, option, value)
                          }
                          item={course}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <section className="mt-10">
                {servicesLater.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Servicios
                    </p>
                    <div>
                      {servicesLater.map((service) => (
                        <Item
                          key={service.uuid}
                          type="Servicio"
                          handleRemoveItemFromCart={(id, option) =>
                            handleRemoveItemFromCart(id, option)
                          }
                          handleBuyLaterFromCart={(id, option, value) =>
                            handleBuyLaterFromCart(id, option, value)
                          }
                          item={service}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>

              <section className="mt-10">
                {membershipsLater.length > 0 && (
                  <div>
                    <p className="font-bold font-gray-800 text-xl lg:text-3xl">
                      Membresías
                    </p>
                    <div>
                      {membershipsLater.map((membership) => (
                        <Item
                          key={membership.uuid}
                          type="Membresia"
                          handleRemoveItemFromCart={(id, option) =>
                            handleRemoveItemFromCart(id, option)
                          }
                          handleBuyLaterFromCart={(id, option, value) =>
                            handleBuyLaterFromCart(id, option, value)
                          }
                          item={membership}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        )}
      </main>
    );
  };

  return (
    <>
      {loading ? (
        <div className="sweet-loading my-40">
          <div className="container mx-auto flex-1 px-4 md:px-0 mb-8 lg:mb-16 text-xl lg:text-3xl text-center font-bold text-primary-new">
            {" "}
            Cargando carrito...
          </div>
          <BounceLoader
            color={"#394875"}
            loading={loading}
            cssOverride={override}
            size={100}
            aria-label="BeatLoader"
            data-testid="BeatLoader"
          />
        </div>
      ) : (
        cartContent()
      )}
    </>
  );
};

export default ShoppingCart;
