import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useState } from "react";

const MembershipsLayout = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      navigate("/membresias/infinita");
    } else {
      navigate("/membresias/plus");
    }
  };

  return (
    <div className="flex flex-col h-screen justify-between">
      <div>
        <NavBar />

        <main>
          <div className="w-full h-full relative md:px-6 lg:px-10">
            <div className="w-full">
              <div className="py-4 md:py-8 lg:py-24">
                <div className="container mx-auto text-center">
                  <h1 className="text-primary-new font-bold text-2xl md:text-3xl lg:text-4xl xl:text-[44px] mb-6 text-center">
                    Conoce <br className="md:hidden" /> nuestras membresías
                  </h1>

                  {/* SWITCH */}
                  <div className="flex justify-center items-center w-full mt-10 space-x-5">
                    <p className="text-primary-new font-bold text-sm lg:text-lg">
                      Franquicia Plus
                    </p>
                    <label
                      htmlFor="switch"
                      className="inline-flex relative items-center cursor-pointer"
                      aria-label="Toggle between Franquicia Plus and Franquicia Infinita"
                    >
                      <input
                        type="checkbox"
                        id="switch"
                        className="sr-only peer"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                      <div className="w-10 h-6 bg-[#1F2A4C] rounded-full peer-checked:after:translate-x-full peer-checked:after:bg-white after:content-[''] after:absolute after:left-1 after:top-1 after:bg-white after:border after:border-gray-300 after:rounded-full after:h-4 after:w-4 after:transition-all"></div>
                    </label>

                    <p className="text-primary-new font-bold text-sm lg:text-lg">
                      Franquicia Infinita
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white w-full container mx-auto px-5 md:px-0 pb-12 lg:pb-24">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default MembershipsLayout;
