import * as _ from "lodash";
import { MEMBERSHIPS_DICTIONARY } from "../../../utils/MembershipsDictionary";

const MembershipsGridsSales = ({
  salesTotalChart,
  salesTotalProductsChart,
  filter,
}) => {
  const filterShow = (label, id) => {
    if (filter === "all" || parseInt(filter) === parseInt(id)) return label;
    if (filter === "courses") return "Cursos";
    if (filter === "memberships" && label !== "Cursos") return label;
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-7y gap-5">
      {Object.entries(MEMBERSHIPS_DICTIONARY).map(
        ([key, membership]) =>
          membership.label !== "" &&
          filterShow(membership.label, membership.id) === membership.label && (
            <div key={key}>
              <div className="text-center font-bold text-2xl text-[#9F9F9F] border border-gray-300 rounded-lg shadow-md p-4">
                <p className="text-xl md:text-2xl leading-none text-gray-700 h-10">
                  {membership.label} <br /> <br />
                </p>
                <div className="mt-2">
                  <p className="text-xl md:text-3xl text-secondary-new">
                    $
                    {membership.label === "Productos"
                      ? salesTotalProductsChart
                      : (
                          salesTotalChart[
                            membership.label === "Cursos"
                              ? Object.keys(MEMBERSHIPS_DICTIONARY).length - 1
                              : key - 1
                          ] || 0
                        ).toLocaleString("en-US")}
                  </p>
                  <p className="text-xl text-gray-new">MXN</p>
                </div>
              </div>
            </div>
          )
      )}
      {/* PRODUCT CARD */}
      {(filter === "all" || filter === "products") && (
        <div className="text-center font-bold text-2xl text-[#9F9F9F] border border-gray-300 rounded-lg shadow-md p-4">
          <p className="text-2xl leading-none text-gray-700">
            Productos <br /> <br />
          </p>
          <div className="mt-2">
            <p className="text-3xl text-primary-200">
              ${salesTotalProductsChart.toLocaleString("en-US")}
            </p>
            <p className="text-xl">MXN</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipsGridsSales;
