import { useState, useEffect } from "react";
import * as _ from "lodash";

import QuestionComponent from "../../../components/admin/QuestionComponent";
import { adminCoursesServices } from "../../../services/api/admin/courses";

const Questions = () => {
  const [menuContent, setMenuContent] = useState("pending");
  const [pendings, setPendings] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [pendingsFilter, setPendingsFilter] = useState([]);
  const [answeredFilter, setAnsweredFilter] = useState([]);
  const [coursesFilter, setCoursesFilter] = useState([]);

  const getPendingQuestions = () => {
    adminCoursesServices
      .getPendingQuestions()
      .then((response) => {
        setPendings(response.data.questions);
        setPendingsFilter(response.data.questions);
      })
      .catch((err) => {});
  };

  const getAnsweredQuestions = () => {
    adminCoursesServices
      .getAnsweredQuestions()
      .then((response) => {
        setAnswered(response.data.questions);
        setAnsweredFilter(response.data.questions);
        setCoursesFilter(response.data.questions.course);
      })
      .catch((err) => {});
  };

  const handleChangeCourseSelect = (course) => {
    if (course === "Sin filtrar") {
      setAnsweredFilter(answered);
      setPendingsFilter(pendings);
    } else {
      const answeredSearch = answered.filter(
        (answer) => answer.course.name === course
      );
      setAnsweredFilter(answeredSearch);
      const pendingSearch = pendings.filter(
        (pending) => pending.course.name === course
      );
      setPendingsFilter(pendingSearch);
    }
  };

  useEffect(() => {
    getPendingQuestions();
    getAnsweredQuestions();
  }, [pendings.length]);

  useEffect(() => {
    const courses = new Set();

    answered.forEach((answered) => {
      courses.add(answered.course.name);
    });

    pendings.forEach((pending) => {
      courses.add(pending.course.name);
    });

    setCoursesFilter([...courses]);
  }, [answered, pendings]);

  return (
    <div className="container mx-auto text-gray-700">
      <p className="text-center lg:text-left text-2xl lg:text-4xl font-bold">
        Todas las preguntas
      </p>
      <div className="flex flex-wrap lg:flex-row ">
        <p className="w-full my-auto font-bold text-2xl text-center lg:text-left lg:w-1/12">
          Filtrar por
        </p>
        <select
          className="py-3 pl-2 my-3 w-full mx-2 lg:w-10/12"
          onChange={(e) => handleChangeCourseSelect(e.target.value)}
        >
          <option>Sin filtrar</option>
          {coursesFilter?.map((course, index) => (
            <option key={index}>{course}</option>
          ))}
        </select>
      </div>

      {/* MENU */}
      <div className="flex justify-center md:justify-start items-center gap-8 px-8 py-3 mt-6 border-b border-b-gray-350">
        <button
          className="text-xl lg:text-2xl"
          onClick={() => setMenuContent("pending")}
        >
          <p
            className={`${
              menuContent === "pending"
                ? "text-primary-200 font-bold"
                : "text-gray-700"
            }`}
          >
            Pendientes
          </p>
        </button>

        <button
          className="text-xl lg:text-2xl"
          onClick={() => setMenuContent("answered")}
        >
          <p
            className={`${
              menuContent === "answered"
                ? "text-primary-200 font-bold"
                : "text-gray-700"
            }`}
          >
            Respondidas
          </p>
        </button>
      </div>

      <div className="border border-gray-350 mt-12">
        {/* CARD */}
        {menuContent === "pending" ? (
          <Pending questions={pendingsFilter} setPendings={setPendings} />
        ) : (
          <Answered questions={answeredFilter} />
        )}
      </div>
    </div>
  );
};

export default Questions;

const Pending = ({ questions, setPendings }) => {
  return (
    <>
      {!_.isEmpty(questions) ? (
        questions.map((question) => (
          <QuestionComponent
            key={question.id}
            question={question}
            pendings={questions}
            setPendings={setPendings}
          />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold">
            No hay preguntas pendientes.
          </p>
        </div>
      )}
    </>
  );
};

const Answered = ({ questions }) => {
  return (
    <>
      {!_.isEmpty(questions) ? (
        questions.map((question) => (
          <QuestionComponent key={question.id} question={question} answered />
        ))
      ) : (
        <div className="bg-gray-300 border-l-4 border-l-primary-200 p-4 m-8">
          <p className="text-center lg:text-left text-lg lg:text-xl text-gray-700 font-bold">
            No hay preguntas respondidas.
          </p>
        </div>
      )}
    </>
  );
};
