import { cartServices } from "../../services/api/cart";
import { MEMBERSHIPS_DICTIONARY } from "../../utils/MembershipsDictionary";
import { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { notifications } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";
import { infiniteMembershipsMapping } from "./modules/membershipsMapping";
import MembershipCard from "../../components/cards/MembershipCard";

const InfiniteMembership = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddMembership = async (option) => {
    setIsLoading(true);
    if (currentUser) {
      await cartServices
        .addMembershipToCart({
          uuid: MEMBERSHIPS_DICTIONARY[option].UUID,
          type: "Membresia",
        })
        .then((response) => {
          notifications.success(response.data.message);
          navigate("/carrito-de-compras");
        })
        .catch((err) => {
          notifications.error(err.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    notifications.warn(
      <button
        onClick={() => navigate({ pathname: "", search: "?login=true" })}
        className="text-primary-200 text-left"
      >
        Inicie sesión{" "}
        <span className="text-gray-600">para adquirir una membresía</span>
      </button>
    );

    setIsLoading(false);
  };

  return (
    <div className="bg-white">
      <div className="grid place-items-center grid-cols-1">
        <MembershipCard
          membership={infiniteMembershipsMapping}
          onClick={() => handleAddMembership(infiniteMembershipsMapping.id)}
        />
      </div>
    </div>
  );
};

export default InfiniteMembership;
