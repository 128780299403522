import { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Context from "../../context/UserContext";
import CourseButton from "./CourseButton";

const CourseCard = ({ course, handleWishList }) => {
  const { currentUser } = useContext(Context);
  const [inWishList, setInWishList] = useState(false);

  useEffect(() => {
    setInWishList(course.inWishList);
  }, [course]);

  return (
    <div className="shadow-md flex flex-col h-300 lg:h-full">
      <div className="relative ">
        <img
          src={course.image}
          alt={course.name}
          className="w-full h-40 object-cover"
        />
        {currentUser && (
          <div className="flex items-center justify-center bg-white h-10 w-10 rounded-full absolute top-2 right-2">
            <button
              onClick={() => {
                handleWishList(course.uuid);
                setInWishList(!inWishList);
              }}
            >
              <FontAwesomeIcon
                icon={inWishList ? ["fas", "heart"] : ["far", "heart"]}
                className="text-tertiary"
                size="lg"
              />
            </button>
          </div>
        )}
      </div>

      <div className="p-4 flex flex-col flex-grow justify-between pb-5 md:pb-12">
        <div className="">
          <h3 className="font-bold text-gray-700 text-sm md:text-xl lg:text-3xl mb-2 text-center lg:text-left">
            {course.name}
          </h3>
          <p className="block lg:hidden  text-gray-650 mb-2">
            {course.description.length >= 100
              ? course.description.substring(0, 100) + "..."
              : course.description}
          </p>
          <p className="hidden lg:block  text-gray-650 mb-4">
            {course.description}
          </p>
        </div>
        <div className="text-sm md:text-xl lg:mb-1">
          <div className="grid grid-cols-2 md:grid-cols-1">
            <div className="flex items-center justify-start mb-4 text-gray-650">
              <FontAwesomeIcon icon={["far", "book-blank"]} size="lg" />
              <p className="ml-2">{course.level}</p>
            </div>

            <div className="flex items-center mb-4 justify-end md:justify-start text-gray-650">
              <FontAwesomeIcon icon={["far", "chart-line"]} size="lg" />
              <p className="ml-2">{course.classes} conferencias</p>
            </div>
          </div>

          {/* Contenedor centrado para el botón */}
          <div className="flex justify-center pt-4">
            <CourseButton course={course} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
