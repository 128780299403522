import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import useUser from "../../hooks/useUser";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock-icon.svg";

const LoginForm = ({ setActive, setIsModalOpen, setEmail }) => {
  const [state, setState] = useState();
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Campo requerido")
      .email("Correo electronico invalido")
      .max(255, `Máximo 255 caracteres`),
    password: Yup.string()
      .required("Campo requerido")
      .min(8, `Mínimo 8 caracteres`),
  });

  const { isLoading, hasError, errors, login, isLogged } = useUser();
  const { resendVerificationCode } = useUser();

  useEffect(() => {
    if (isLogged) {
      setIsModalOpen(false);
    }
  }, [isLogged, setIsModalOpen]);

  const handleSubmit = ({ email, password }) => {
    login({ email, password });
  };

  const onResetPassword = () => {
    setActive("sendCode");
  };
  const onVerificationCode = ({ email }) => {
    setEmail(email);
    setActive("verify");
    resendVerificationCode({ email });
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={formSchema}
        onSubmit={(values) => {
          switch (state) {
            case "login":
              break;
            case "verification":
              onVerificationCode(values);
              break;
            default:
              handleSubmit(values);
              break;
          }
        }}
      >
        <Form>
          <p className="text-gray-700 font-bold text-2xl pt-10 pb-5">
            Iniciar Sesión
          </p>
          <p className="text-gray-700 font-bold text-xl">Correo Electronico</p>
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <UserIcon />
            </span>
            <Field
              name="email"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 pl-10 pr-4 focus:outline-none"
              placeholder="Usuario"
              type="email"
            />
          </label>
          <ErrorMessage
            name="email"
            component="div"
            className="text-red-400 font-bold font-xs"
          />

          <p className="text-gray-700 font-bold text-xl mt-5">Contraseña</p>
          <label className="relative block">
            <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-red-200">
              <LockIcon />
            </span>
            <Field
              name="password"
              className="w-full bg-white text-gray-550 placeholder:font-italitc border border-slate-300 py-2 pl-10 pr-4 focus:outline-none"
              placeholder="Contraseña"
              type="password"
            />
          </label>
          <ErrorMessage
            name="password"
            component="div"
            className="text-red-400 font-bold font-xs"
          />

          {hasError && errors.length > 0 && (
            <div className="mt-2 bg-gray-300 border-l-4 border-l-tertiary p-2">
              <ul className="text-gray-700 font-bold text-xs">
                {errors.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {Object.keys(errors).map((index, i) =>
            errors[index] === "La cuenta no está verificada" ? (
              <div>
                <p className="pt-2 text-primary-200">
                  <button
                    type="submit"
                    className=" text-primary-200font-normal font-bold"
                    onClick={
                      () => {
                        setState("verification");
                      } //onVerificationCode()
                    }
                  >
                    Verificar cuenta
                  </button>
                </p>
              </div>
            ) : (
              ""
            )
          )}

          <p className="pt-2 pb-5">
            <button
              type="button"
              className=" text-gray-700 font-normal text-base"
              onClick={() => onResetPassword()}
            >
              ¿Olvidaste tu contraseña?
            </button>
          </p>

          <button
            type="submit"
            className="w-full bg-primary-new text-white font-bold text-lg py-2 disabled:opacity-75 rounded-full"
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              <span>Entrar</span>
            )}
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default LoginForm;
