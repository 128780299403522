import { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Context from "../../context/UserContext";
import { ordersService } from "../../services/api/orders";
import TableLoading from "../../components/TableLoading";

import TableNoData from "../../components/TableNoData";
import {
  getColumnsForOrdersDesktop,
  getFormattedDate,
  customStyles,
  paginationComponentOptions,
  getColumnsForOrdersDesktopMobile,
  customStylesMobile,
  getColumnsForOrdersTablet,
} from "../../utils/tableOptions";
import { Link } from "react-router-dom";

const Orders = () => {
  const { currentUser } = useContext(Context);

  const [orders, setOrders] = useState();
  const [formattedOrders, setFormattedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = () => {
    setIsLoading(true);
    ordersService
      .getAllOrders()
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const formatOrdersData = () => {
    let data = [];

    let dataColorsStatus = {
      Checkout: {
        class: "text-gray-700",
        text: "Sin confirmar",
      },
      AwaitingPayment: {
        class: "text-gray-700",
        text: "Pendiente de pago",
      },
      Payment: {
        class: "text-secondary",
        text: "Pagado",
      },
      ConfirmPayment: {
        class: "text-green-50",
        text: "Pago confirmado",
      },
      DeniedPayment: {
        class: "text-tertiary",
        text: "Pago rechazado",
      },
      Send: {
        class: "text-gray-700",
        text: "Enviado",
      },
      Finish: {
        class: "text-primary-new-50",
        text: "Completado", // Finalizado
      },
      DeclinedPayment: {
        class: "text-tertiary",
        text: "Pago declinado",
      },
    };

    orders.forEach((order, idx) => {
      data.push({
        order: (
          <span className="font-bold text-primary-new-50 text-sm lg:text-base">
            {order.id}
          </span>
        ),
        date: (
          <p className="text-sm lg:text-base">
            {getFormattedDate(order.created_at)}
          </p>
        ),
        total: (
          <>
            <p className="font-bold text-sm lg:text-base md:hidden">
              $
              {order.total.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}{" "}
            </p>
            <p className="hidden md:block font-bold text-sm lg:text-base">
              $
              {order.total.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}{" "}
              <small>MXN</small>
            </p>
          </>
        ),
        status: (
          <span
            className={`font-bold text-sm lg:text-base ${
              order.status && dataColorsStatus[order.status]
                ? dataColorsStatus[order.status].class
                : "text-gray-700"
            }`}
          >
            {order.status && dataColorsStatus[order.status]
              ? dataColorsStatus[order.status].text
              : "---"}
          </span>
        ),
        actionComponent:
          order.status !== "Checkout" ? (
            <>
              <Link
                to={`/mi-escritorio/orden/${order.id}`}
                className="block xs:hidden w-full text-primary-new hover:text-primary-new-50 text-center text-sm lg:text-base"
              >
                Ver
              </Link>
              <Link
                to={`/mi-escritorio/orden/${order.id}`}
                className="hidden xs:block w-full text-primary-new hover:text-primary-new-50 text-center text-sm lg:text-base"
              >
                Ver detalles
              </Link>
            </>
          ) : (
            <FontAwesomeIcon
              icon={["fa", "ban"]}
              size="1x"
              className="text-gray-700"
            />
          ),
      });
    });

    setFormattedOrders(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!currentUser) return;
    fetchOrders();
  }, [currentUser]);

  useEffect(() => {
    if (_.isEmpty(orders)) return;
    formatOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  return (
    <div id="desktop-orders-table">
      <div className="bg-white px-2 rounded-2xl w-full">
        <h1 className="font-bold text-2xl lg:text-3xl mb-6 text-center md:text-left font-nunito text-[#555555]">
          Mis Ordenes
        </h1>
        <div className="hidden lg:block border border-[#BCBCBC] rounded-2xl mt-12">
          <DataTable
            columns={getColumnsForOrdersDesktop}
            data={formattedOrders}
            noDataComponent={<TableNoData />}
            progressPending={isLoading}
            progressComponent={<TableLoading size="5x" />}
            customStyles={customStyles}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>

        <div className="hidden xs:block lg:hidden border border-[#BCBCBC] rounded-2xl mt-12">
          <DataTable
            columns={getColumnsForOrdersTablet} // Coloca la versión tablet aquí
            data={formattedOrders}
            noDataComponent={<TableNoData />}
            progressPending={isLoading}
            progressComponent={<TableLoading size="5x" />}
            customStyles={customStyles}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>

        <div className="xs:hidden border border-[#BCBCBC] rounded-2xl mt-8 xs:mt-12">
          <DataTable
            columns={getColumnsForOrdersDesktopMobile}
            data={formattedOrders}
            noDataComponent={<TableNoData />}
            progressPending={isLoading}
            progressComponent={<TableLoading size="5x" />}
            customStyles={{
              headCells: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                },
              },
              rows: {
                style: {
                  fontSize: "12px",
                  minHeight: "40px",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
